import { SagaReturnType, call, put, select, takeLatest } from 'redux-saga/effects'

import message from 'constants/message'
import { PessoaFisica } from 'modules/pessoa-fisica/types'
import { createPessoaFisica, updatePessoaFisica } from 'services/pessoa-fisica.services'
import { alert } from 'store/alert/duck'
import history from 'store/history'

import { pessoaFisicaSelectors } from './selector'
import { pessoaFisicaActions } from './slice'

type CreatePessoaFisicaResult = SagaReturnType<typeof createPessoaFisica>
type UpdatePessoaFisicaResult = SagaReturnType<typeof updatePessoaFisica>

function* handleCreatePessoaFisica() {
  try {
    yield put(pessoaFisicaActions.setIsSubmiting(true))
    const origin: string = yield select(pessoaFisicaSelectors.origin)

    const payload: PessoaFisica = yield select(pessoaFisicaSelectors.values)
    const result: CreatePessoaFisicaResult = yield call(createPessoaFisica, payload)

    if (!result.success) {
      throw new Error(result.errorMessage)
    }

    yield put(alert.success(message.MSG0001))
    yield put(pessoaFisicaActions.reset())

    if (origin) {
      history.push(origin, { selectedId: result.data.idPessoaFisica })
    } else {
      history.push('/')
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(alert.error(error.message))
    }
  } finally {
    yield put(pessoaFisicaActions.setIsSubmiting(false))
  }
}

function* handleUpdatePessoaFisica() {
  try {
    yield put(pessoaFisicaActions.setIsSubmiting(true))
    const origin: string = yield select(pessoaFisicaSelectors.origin)

    const payload: PessoaFisica = yield select(pessoaFisicaSelectors.values)
    const result: UpdatePessoaFisicaResult = yield call(updatePessoaFisica, payload)

    if (!result.success) {
      throw new Error(result.errorMessage)
    }

    yield put(alert.success('Cadastro atualizado com sucesso'))
    yield put(pessoaFisicaActions.reset())

    if (origin) {
      history.push(origin, { selectedId: payload.idPessoaFisica })
    } else {
      history.push('/')
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(alert.error(error.message))
    }
  } finally {
    yield put(pessoaFisicaActions.setIsSubmiting(false))
  }
}

export default function* pessoaFisicaSaga() {
  yield takeLatest(pessoaFisicaActions.create.type, handleCreatePessoaFisica)
  yield takeLatest(pessoaFisicaActions.update.type, handleUpdatePessoaFisica)
}
