/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ResetFormOptions = 'ALL' | 'PESSOA_FISICA' | 'PESSOA_JURIDICA' | 'ANP' | 'PROCESSO_PROCEDIMENTO'
export type ResetFormPayload = PayloadAction<ResetFormOptions>

const initialState = {
  confirm: false,
  cancel: false,
  navigationKey: 0,
  searchUrl: '',
  confirmationMsg: '',
}

export const navigationSlice = createSlice({
  initialState,
  name: 'navigation',
  reducers: {
    setCancel(state) {
      state.cancel = true
    },
    setConfirm(state) {
      state.confirm = true
    },
    setNavigationKey(state, action) {
      state.navigationKey = action.payload
    },
    setSearchUrl(state, action) {
      state.searchUrl = action.payload
    },
    setConfirmationMsg(state, action) {
      state.confirmationMsg = action.payload
    },
    resetNavigationState(state) {
      state.confirm = initialState.confirm
      state.cancel = initialState.cancel
      state.navigationKey = initialState.navigationKey
      state.confirmationMsg = initialState.confirmationMsg
    },
    resetFormState: (state, payload: ResetFormPayload) => state,
  },
})

export const navigationActions = navigationSlice.actions
export default navigationSlice.reducer
