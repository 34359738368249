import React from 'react'

import useWindowDimensions from 'hooks/useWindowDimensions'
import logo from 'styles/assets/logo.png'
import breakpoints from 'styles/breakpoints'

import { Grid } from '@material-ui/core'

// import packageJson from '../../../package.json';
import {
  Container, Infos, LayoutWrapper, Version
} from './styles'

const Login: React.FC = ({ children }) => {
  const { width } = useWindowDimensions()

  return (
    <LayoutWrapper>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Container>
            <img src={logo} alt="IDEA logo" />
            {children}
            <div>
              <Infos>{`Em caso de dúvidas, acesse:`}</Infos>
              <Infos>{`• Intranet > Sistemas > Canal do IDEA`}</Infos>
              <Infos>{`• Telefone: (71) 3103-0175`}</Infos>
              {/* <Version>v{packageJson.version} dev</Version> */}
              <Version></Version>
            </div>
          </Container>
        </Grid>
        {width >= breakpoints.medium && <Grid item md={8}></Grid>}
      </Grid>
    </LayoutWrapper>
  )
}

export default Login
