import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const state = (state: AppState) => state.processoProcedimento

const values = createSelector(state, processoProcedimento => processoProcedimento.values)
const origin = createSelector(state, processoProcedimento => processoProcedimento.origin)
const currentStep = createSelector(state, processoProcedimento => processoProcedimento.currentStep)
const completedStep = createSelector(state, processoProcedimento => processoProcedimento.completedStep)
const selectedEnvolvidoId = createSelector(state, processoProcedimento => processoProcedimento.selectedEnvolvidoId)
const listEnvolvidos = createSelector(state, processoProcedimento => processoProcedimento.values.envolvidos)
const listInstrumentos = createSelector(state, processoProcedimento => processoProcedimento.values.instrumentos)

export const processoProcedimentoSelectors = {
  selectedEnvolvidoId,
  listEnvolvidos,
  listInstrumentos,
  completedStep,
  currentStep,
  values,
  origin
}
