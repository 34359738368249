import { AtividadeNaoProcedimental } from 'modules/atividade-nao-procedimental/types'

import { endpoints } from './api/endpoints/atividade-nao-procedimental.endpoints'
import { httpDelete, httpGet, httpPost, httpPut } from './api/http-methods'
import { AnpAnexoApiViewModel, AnpApiViewModel } from './api/models/atividade-nao-procedimental.models'
import { requestHandler } from './api/request-handler'
import { mapANPToApi } from './mappers/atividade-nao-procedimental.mappers'

type DeleteAnexoParams = {
  cdUsuario: number
  nmAnexo: string
}

type CreateDocumentoParams = {
  nmDocumento: string
  dsDocumento: string
  sobrescrever: boolean
}

export const getAtividadeNaoProcedimentalById = requestHandler<AnpApiViewModel, string>(atividadeNaoProcedimentalId =>
  httpGet(endpoints.getById(atividadeNaoProcedimentalId))
)

export const createAtividadeNaoProcedimental = requestHandler<AnpApiViewModel, AtividadeNaoProcedimental>(payload =>
  httpPost(endpoints.create, mapANPToApi(payload))
)

export const updateAtividadeNaoProcedimental = requestHandler<AnpApiViewModel, AtividadeNaoProcedimental>(payload =>
  httpPut(endpoints.update(String(payload.idAtividadeNaoProcedimental)), mapANPToApi(payload))
)

export const deleteAtividadeNaoProcedimental = requestHandler<AnpApiViewModel, string>(atividadeNaoProcedimentalId =>
  httpDelete(endpoints.delete(atividadeNaoProcedimentalId))
)

export const createDocumento = requestHandler<AnpAnexoApiViewModel, CreateDocumentoParams>(payload =>
  httpPost(endpoints.createDocumento, payload)
)

export const deleteAnexoTemporario = requestHandler<void, DeleteAnexoParams>(payload =>
  httpDelete(endpoints.deleteAnexoTemporario(payload.cdUsuario, payload.nmAnexo))
)

export const downloadAnexo = requestHandler<Blob, string>(anexoId =>
  httpGet(endpoints.downloadAnexo(anexoId), {}, { responseType: 'blob' })
)
