import { SagaReturnType, call, put, takeLatest } from 'redux-saga/effects'

import { PayloadAction } from '@reduxjs/toolkit'
import {
  getConfiguracoes,
  updateConfiguracao,
  deleteConfiguracao,
  createConfiguracao,
  getConfiguracaoById,
  deleteConfiguracaoValor,
} from 'services/configuracao.services'
import { alertActions } from 'store/alert/duck'

import { Setting, settingActions } from './duck'

type GetConfiguracoesResult = SagaReturnType<typeof getConfiguracoes>
type GetConfiguracaoByIdResult = SagaReturnType<typeof getConfiguracaoById>

function* load() {
  try {
    const result: GetConfiguracoesResult = yield call(getConfiguracoes, undefined)

    if (!result.success) {
      throw new Error(result.errorMessage)
    }
    yield put(settingActions.set(result.data))
  } catch (error) {
    yield put(
      alertActions.open({
        isOpen: true,
        message: 'Não foi possível carregar as configurações do sistema.',
        type: 'warning',
        value: '',
      })
    )
  }
}

function* save({ payload: setting }: PayloadAction<Setting>) {
  try {
    yield call(createConfiguracao, setting)
    yield put(settingActions.load())
    yield put(
      alertActions.open({
        isOpen: true,
        message: 'Cadastro concluído com sucesso.',
        type: 'success',
        value: '',
      })
    )
  } catch (error) {
    yield put(
      alertActions.open({
        isOpen: true,
        message: 'Não foi possível salvar as configurações do sistema.',
        type: 'warning',
        value: '',
      })
    )
  }
}

function* update({ payload: setting }: PayloadAction<Setting>) {
  try {
    const result: GetConfiguracaoByIdResult = yield call(getConfiguracaoById, Number(setting.idConfiguracao))

    if (!result.success) {
      throw new Error(result.errorMessage)
    }

    const settingList = result.data

    if (settingList.valores.length > 0) {
      for (let index = 0; index <= settingList.valores.length; index++) {
        const valoresList = setting.valores.find(
          item => item.idConfiguracaoValor === settingList.valores[index]?.idConfiguracaoValor
        )
        if (!valoresList) {
          if (Number(settingList.valores[index]?.idConfiguracaoValor) >= 1) {
            yield call(deleteConfiguracaoValor, Number(settingList.valores[index]?.idConfiguracaoValor))
          }
        }
      }
    }

    yield call(updateConfiguracao, setting)
    yield put(settingActions.load())
    yield put(
      alertActions.open({
        isOpen: true,
        message: 'Dados atualizados com sucesso.',
        type: 'success',
        value: '',
      })
    )
  } catch (error) {
    yield put(
      alertActions.open({
        isOpen: true,
        message: 'Não foi possível atualizar as configurações do sistema.',
        type: 'warning',
        value: '',
      })
    )
  }
}

function* remove({ payload: settingId }: PayloadAction<number>) {
  try {
    yield call(deleteConfiguracao, settingId)
    yield put(settingActions.load())
    yield put(
      alertActions.open({
        isOpen: true,
        message: 'Dados excluídos com sucesso!',
        type: 'success',
        value: '',
      })
    )
  } catch (error) {
    yield put(
      alertActions.open({
        isOpen: true,
        message: 'Não foi possível atualizar as configurações do sistema.',
        type: 'warning',
        value: '',
      })
    )
  }
}

export default function* settingSaga(): Generator {
  yield takeLatest(settingActions.load.type, load)
  yield takeLatest(settingActions.update.type, update)
  yield takeLatest(settingActions.save.type, save)
  yield takeLatest(settingActions.remove.type, remove)
}
