import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Color } from '@material-ui/lab/Alert'

export type AlertType = {
  type: Color
  message: string
  isOpen?: boolean
  value: string
}

const initialState: AlertType = { type: 'success', message: '', isOpen: false, value: '' }

type Alert = {
  message: string
  value?: string 
} | string

const getValues = (type: 'info' | 'error' | 'success' | 'warning', payload: Alert) => ({
  type,
  message: typeof payload === 'string' ? payload : payload.message,
  isOpen: true,
  value: typeof payload === 'object' && payload.value ? payload.value : '',
})

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    open: (state: AlertType, { payload }: PayloadAction<AlertType>) => ({
      ...state,
      ...payload,
      isOpen: true,
      value: payload.value,
    }),
    close: (state: AlertType) => ({ ...state, isOpen: false }),
    reset: () => initialState,
    info: (_, { payload }: PayloadAction<Alert>) => getValues('info', payload),
    error: (_, { payload }: PayloadAction<Alert>) => getValues('error', payload),
    success: (_, { payload }: PayloadAction<Alert>) => getValues('success', payload),
    warning: (_, { payload }: PayloadAction<Alert>) => getValues('warning', payload),
  },
})

export const alertActions = alertSlice.actions

export const alert = alertActions

export default alertSlice.reducer
