import React from 'react'

import colors from 'styles/colors'

import { IconProps } from './types'

const MenuIcon = ({ size = 24, color = colors.white }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 12H21" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 6H21" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 18H21" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default MenuIcon
