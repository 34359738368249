import { Option } from 'components/form/inputs'
import { format, isValid } from 'date-fns'

export function autoClickDownload(url: string, filename: string) {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}

export function generateRandomId() {
  return window.crypto.getRandomValues(new Uint16Array(1))[0]
}

export function printLabel(option?: string | Option, fallback = '-') {
  if (!option) {
    return fallback
  }

  if (typeof option === 'string') {
    return option
  }

  if (option.label) {
    return option.label
  }

  return fallback
}

export function getLabelByFieldName(value?: unknown, fieldName = 'label', fallback = '-') {
  if (!value) {
    return fallback
  }

  if (typeof value === 'string') {
    return value
  }

  if (typeof value === 'object' && value !== null) {
    const typedValue = value as Record<string, string>
    return fieldName in typedValue ? typedValue[fieldName] : fallback
  }

  return fallback
}

export function printDate(date?: Date | null, mask = 'dd/MM/yyyy', fallback = '-') {
  if (!date || !isValid(date)) {
    return fallback
  }

  return format(date, mask)
}

export function printDateHour(date?: Date | null, mask = 'dd/MM/yyyy - hh:mm', fallback = '-') {
  if (!date || !isValid(date)) {
    return fallback
  }

  return format(date, mask)
}

export function getOptionValue(option?: Option | string) {
  if (option && typeof option !== 'string') {
    return option.value || null
  }
  return null
}

export function createQueryString(params = {}) {
  const queryObject = params as Record<string, string>

  const queryString = Object.keys(queryObject)
    .filter(key => Boolean(queryObject[key]))
    .map(key => `${key}=${encodeURIComponent(queryObject[key])}`)
    .join('&')

  return queryString ? `?${queryString}` : ''
}

export function revertQueryString(queryString = '') {
  const queryObject = {} as Record<string, string>
  const querySplitted = queryString.replace('?', '').split('&')

  querySplitted.forEach(item => {
    const [key, value] = item.split('=')
    queryObject[key] = decodeURIComponent(value)
  })

  return queryObject
}

export function searchParamsByValidParams(params: object, validParams: string[]) {
  const filters: Record<string, string> = {}

  Object.entries(params).forEach(([key, value]) => {
    if (validParams.includes(key) && value) {
      filters[key] = value
    }
  })

  return filters
}
