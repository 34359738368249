import { SagaReturnType, call, put, takeLatest } from 'redux-saga/effects'

import { PayloadAction } from '@reduxjs/toolkit'
import { getPermissionsByGroups } from 'services/login.services'
import { errorActions } from 'store/errors/duck'

import { permissionActions } from './duck'

type GetPermissionsResult = SagaReturnType<typeof getPermissionsByGroups>

function* load({ payload }: PayloadAction<number[]>) {
  try {
    const result: GetPermissionsResult = yield call(getPermissionsByGroups, payload)

    if (!result.success) {
      throw new Error(result.errorMessage)
    }
    yield put(permissionActions.set(result.data))
  } catch (error) {
    yield put(
      errorActions.add({
        key: permissionActions.load.type,
        errors: ['Não foi possível carregar as configurações do sistema.'],
      })
    )
  }
}

export default function* settingSaga(): Generator {
  yield takeLatest(permissionActions.load.type, load)
}
