import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'

import { atividadeNaoProcedimentalMiddleware } from './atividade-nao-procedimental/slice'
import createStore from './createStore'
import persistReducer from './persistReducer'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

const sagaMiddleware = createSagaMiddleware()

const store = createStore(persistReducer(rootReducer), sagaMiddleware, [atividadeNaoProcedimentalMiddleware.middleware])

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch

export { store, persistor }
