import styled from 'styled-components'
import colors from 'styles/colors'
import fonts from 'styles/fonts/fonts'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

interface Props {
  open: boolean
}

interface listItemProps {
  open: boolean
  selected: boolean
}

interface CollapseProps {
  selected: boolean
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: 240,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      zIndex: 10,
      top: 'unset',
      width: 260,
      overflow: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      zIndex: 10,
      top: 'unset',
      width: 75,
      overflow: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  })
)

export const CloseIconContainer = styled.div<Props>`
  display: flex;
  justify-content: ${props => (props.open ? 'flex-start' : 'center')};
  align-items: center;
  text-align: center;
  margin-left: ${props => (props.open ? '5px' : 0)};
  .rotated {
    transform: rotate(180deg);
  }
  border-bottom: 1px solid ${colors.disabled};
`
export const CloseText = styled.span`
  font-family: ${fonts.fontFamilyRaleway};
  color: #828282;
  font-size: 15px;
  font-weight: 400;
  margin-top: 2px;
`
export const ListDrawer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  .MuiCollapse-entered,
  .MuiCollapse-root {
    background-color: #f3f4fc;
    border-left: 3px solid #3949ab;
  }
`
export const ItemWrapper = styled.div<CollapseProps>`
  display: flex;
  transition: background 0.3s ease;
  background-color: ${props => (props.selected ? '#F3F4FC' : '')};
  border-left: ${props => (props.selected ? '3px solid #3949AB' : 0)};
  transition: all 0.1s ease;
  &:hover {
    background-color: ${colors.disabled};
    cursor: pointer;
  }

  flex-direction: column;
`
export const ListItem = styled.div<listItemProps>`
  display: flex;
  justify-content: ${props => (props.open ? 'flex-start' : 'center')};
  margin-left: ${props => (props.open ? '15px' : 0)};
  width: 100%;
  height: 100%;
  gap: 9px;
  padding: 15px 0 15px;
  align-items: center;
  font-family: ${fonts.fontFamilyRaleway};
  font-weight: 700;
  font-size: 14px;
  color: ${props => (props.selected ? '#3949AB' : '#828282')};
`
export const OtherMenus = styled.div`
  position: absolute;
  display: flex;
  /* margin-bottom: 20px; */
  overflow: hidden;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 25px 0;
  transition: background 0.3s ease;
  &:hover {
    background-color: ${colors.disabled};
    cursor: pointer;
  }
  height: 5%;
  gap: 9px;
  border-top: 1px solid ${colors.disabled};
  bottom: 0;
  left: 0;
  font-family: ${fonts.fontFamilyRaleway};
  font-weight: 700;
  font-size: 14px;
  color: #828282;
`
export const CollapseItem = styled.div<CollapseProps>`
  display: flex;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 15px 0 15px;
  justify-content: flex-start;
  margin-left: 15px;
  &:hover {
    color: #3949ab;
  }
  font-family: ${fonts.fontFamilyRaleway};
  font-weight: 700;
  font-size: 14px;
  color: ${props => (props.selected ? '#3949AB' : '#828282')};
`
