import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const state = (state: AppState) => state.atividadeNProcedimental

const values = createSelector(state, anp => anp.values)
const valuesView = createSelector(state, anp => anp.valuesView)
const anexos = createSelector(state, anp => anp.values.anexos)
const currentStep = createSelector(state, anp => anp.currentStep)
const completedStep = createSelector(state, anp => anp.completedStep)
const selectedIdToDelete = createSelector(state, anp => anp.selectedIdToDelete)

export const atividadeNaoProcedimentalSelectors = {
  completedStep,
  currentStep,
  selectedIdToDelete,
  valuesView,
  values,
  anexos,
}
