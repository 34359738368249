import { isAxiosError } from './axios'

export function errorHandler(error: unknown) {
  let errorMessage = 'Erro! Por favor atualize a página para continuar.'

  if (isAxiosError(error)) {
    Object.entries(error.response?.data?.errors).forEach(([, value]) => {
      errorMessage = Array.isArray(value) ? value[0] : value
    })
    return errorMessage
  }

  if (error instanceof Error) {
    return error.message
  }

  if (typeof error === 'object' && error !== null) {
    Object.entries(error).forEach(([, value]) => {
      errorMessage = Array.isArray(value) ? value[0] : value
    })
    return errorMessage
  }

  return errorMessage
}
