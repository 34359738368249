import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const OutlinedButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))<{ small?: boolean }>`
  width: ${props => props.small ? 'auto' : '100%'};
  max-width: 340px;
  padding: ${props => props.small ? '0.5rem 1rem' : '1rem 1.5rem'};
  border-radius: 0.5rem;
  border: 1px solid rgb(224, 224, 224);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 20px 0px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: rgb(100, 109, 123);
  font-family: Poppins;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: ${props => props.small ? '0.9rem' : '20px'};
  transition: background-color 0.2s;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.04);
  }

  @media (max-width: ${breakpoints.small}px) {
    font-size: 0.8rem;
  }
`
