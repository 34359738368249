import React from 'react'

import colors from 'styles/colors'

import { IconProps } from './types'

const CloseMenuIcon = ({ color = colors.white, size = 16 }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.872 1.128C14.249 0.505023 13.239 0.505023 12.616 1.128L8 5.744L3.384 1.128C2.76102 0.505022 1.75098 0.505023 1.128 1.128C0.505023 1.75098 0.505023 2.76102 1.128 3.384L5.744 8L1.128 12.616C0.505022 13.239 0.505023 14.249 1.128 14.872C1.75098 15.495 2.76102 15.495 3.384 14.872L8 10.256L12.616 14.872C13.239 15.495 14.249 15.495 14.872 14.872C15.495 14.249 15.495 13.239 14.872 12.616L10.256 8L14.872 3.384C15.495 2.76102 15.495 1.75098 14.872 1.128Z"
      fill={color}
    />
  </svg>
)

export default CloseMenuIcon
