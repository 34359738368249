import vMasker from 'vanilla-masker'

export function toMoney(value: string | number) {
  return vMasker.toMoney(value.toString(), { separator: ',', delimiter: '.', unit: 'R$' })
}

export function toDecimal(value: string | number, precision = 2) {
  return vMasker.toMoney(value.toString(), {
    precision,
    separator: ',',
    delimiter: '.',
    zeroCents: Number.isInteger(Number(value)),
  })
}

export function toNumber(value: string | number) {
  return vMasker.toNumber(value)
}
