import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { drawerActions } from 'store/drawer/duck'
import DrawerSelector from 'store/drawer/selectors'

const useDrawer = (key: string) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(DrawerSelector.byKey(key))

  const openDrawer = useCallback(() => {
    dispatch(drawerActions.set({ key, isOpen: true }))
  }, [dispatch, key])

  const closeDrawer = useCallback(() => {
    dispatch(drawerActions.set({ key, isOpen: false }))
  }, [dispatch, key])

  const toggleDrawer = useCallback(() => {
    dispatch(drawerActions.set({ key, isOpen }))
  }, [dispatch, isOpen, key])

  return { openDrawer, closeDrawer, toggleDrawer, isOpen }
}

export default useDrawer
