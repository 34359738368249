import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl('finalistico')

export const endpoints = {
  getAll: `${BASE_URL}/Configuracao`,
  create: `${BASE_URL}/Configuracao`,
  update: `${BASE_URL}/Configuracao`,
  delete(id: number) {
    return `${BASE_URL}/Configuracao/${id}`
  },
  deleteValor(id: number) {
    return `${BASE_URL}/ConfiguracaoValor/${id}`
  },
  getById(id: number) {
    return `${BASE_URL}/Configuracao/${id}`
  },
  getByChaveId(id: number) {
    return `${BASE_URL}/Configuracao/ObterPorChave?chaves=${id}`
  },
}
