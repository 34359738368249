import { formatISO } from 'date-fns'
import { PessoaJuridica } from 'modules/pessoa-juridica/types'
import { generateRandomId } from 'utils/helpers'
import { toPattern } from 'vanilla-masker'

import { PessoaJuridicaApiViewModel } from '../api/models/pessoa-juridica.models'
import { mapPessoaFisicaToStore } from './pessoa-fisica.mappers'

export function mapPessoaJuridicaToApi(pj: PessoaJuridica) {
  return {
    nuCnpj: pj.nuCnpj?.replace(/\D/g, ''),
    nmRazaoSocial: pj.nmRazaoSocial,
    dtAbertura: pj.dtAbertura && formatISO(pj.dtAbertura),
    nmFantasia: pj.nmFantasia,
    nuInscricaoMunicipal: pj.nuInscricaoMunicipal,
    nuInscricaoEstadual: pj.nuInscricaoEstadual,
    idOEVinculada: pj.idOEVinculada,
    enderecos: pj.enderecos?.map(end => ({
      nuCep: end.nuCep,
      dsLogradouro: end.dsLogradouro,
      nuLogradouro: end.nuLogradouro,
      dsComplementoLogradouro: end.dsComplementoLogradouro,
      dsPontoReferencia: end.dsPontoReferencia,
      stPrincipal: end.stPrincipal,
      idUF: end.uf?.value ? end.uf.value : null,
      cdMunicipio: end.municipio?.value ? end.municipio.value : null,
      cdDistritoEndereco: end.distrito?.value ? end.distrito.value : null,
      cdBairro: end.bairro?.value ? end.bairro.value : null,
      idEnderecoPessoaJuridica: end.idEndereco,
      idPessoaJuridica: pj.idPessoaJuridica,
    })),
    emails: pj.emails?.map(email => ({
      dsEmail: email.dsEmail,
      stPrincipal: email.stPrincipal,
      idEmailPessoaJuridica: email.idEmail,
      idPessoaJuridica: pj.idPessoaJuridica,
    })),
    telefones: pj.telefones?.map(tel => ({
      stNacional: tel.stNacional,
      idTpTelefone: tel.idTpTelefone || '1',
      nuTelefone: tel.nuTelefone,
      stPrincipal: tel.stPrincipal,
      nrDDD: tel.nuTelefone?.substring(1, 3),
      cdPais: tel.stNacional ? '55' : tel.cdPais,
      idTelefonePessoaJuridica: tel.idTelefone,
      idPessoaJuridica: pj.idPessoaJuridica,
    })),
    idPessoaFisica: pj.representante?.idPessoaFisica,
  }
}

export function mapPessoaJuridicaToStore(pj: PessoaJuridicaApiViewModel) {
  const pessoaJuridica: PessoaJuridica = {
    idPessoaJuridica: pj.idPessoaJuridica ? pj.idPessoaJuridica : String(generateRandomId()),
    nuCnpj: pj.nuCnpj ? toPattern(pj.nuCnpj, '99.999.999/9999-99') : '',
    nmRazaoSocial: pj.nmRazaoSocial,
    nmFantasia: pj.nmFantasia,
    dtAbertura: pj.dtAbertura && new Date(pj.dtAbertura),
    nuInscricaoMunicipal: pj.nuInscricaoMunicipal,
    nuInscricaoEstadual: pj.nuInscricaoEstadual,
    ultimaAlteracao: pj.dtAlteracao && new Date(pj.dtAlteracao),
    idOEVinculada: pj.oeVinculada ? pj.oeVinculada.idOrgaoUnidade : undefined,
    orgExterna: !!(pj.oeVinculada && !pj.idPessoaJuridica),
    enderecos:
      pj.enderecos?.map(end => ({
        nuCep: end.nuCep,
        dsLogradouro: end.dsLogradouro,
        nuLogradouro: end.nuLogradouro,
        dsComplementoLogradouro: end.dsComplementoLogradouro,
        dsPontoReferencia: end.dsPontoReferencia,
        stPrincipal: end.stPrincipal,
        uf: {
          value: end.uf?.idUF || '',
          label: end.uf?.sgUF || '',
        },
        municipio: {
          value: end.municipio?.cdMunicipio || '',
          label: end.municipio?.nmMunicipio || '',
        },
        distrito: {
          value: end.distrito?.cdDistrito || '',
          label: end.distrito?.nmDistrito || '',
        },
        bairro: {
          value: end.bairro?.cdBairro || '',
          label: end.bairro?.nmBairro || '',
        },
        idEndereco: end.idEnderecoPessoaJuridica,
      })) || [],
    emails:
      pj.emails?.map(email => ({
        dsEmail: email.dsEmail,
        stPrincipal: email.stPrincipal,
        idEmail: email.idEmailPessoaJuridica,
      })) || [],
    telefones:
      pj.telefones?.map(tel => ({
        idTpTelefone: tel.tipoTelefone ? String(tel.tipoTelefone.idTpTelefone) : '',
        nuTelefone: tel.stNacional
          ? toPattern(
              `${tel.nrDDD}${tel.nuTelefone}`,
              +tel.tipoTelefone?.idTpTelefone === 1 ? '(99) 9999-9999' : '(99) 99999-9999'
            )
          : tel.nuTelefone,
        stNacional: tel.stNacional,
        stPrincipal: tel.stPrincipal,
        cdPais: tel.stNacional ? '55' : tel.cdPais,
        idTelefone: tel.idTelefonePessoaJuridica,
      })) || [],
    representante: pj.representante ? mapPessoaFisicaToStore(pj.representante) : undefined,
  }

  return pessoaJuridica
}

export const mapPessoaJuridica = mapPessoaJuridicaToStore
