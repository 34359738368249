import { getBaseUrl } from '../get-base-url'

const BASE_URI = getBaseUrl('finalistico')

export const endpoints = {
  getAll: `${BASE_URI}/EquipeDistribuicao`,
  getById(id: number) {
    return `${BASE_URI}/EquipeDistribuicao/${id}`
  },
  getByOrgaoUnidadeId(orgaoUnidadeId: number) {
    return `${BASE_URI}/EquipeDistribuicao/ListarPorOrgaoUnidade/${orgaoUnidadeId}`
  },
  create: `${BASE_URI}/EquipeDistribuicao`,
  update(id: number) {
    return `${BASE_URI}/EquipeDistribuicao/${id}`
  },
  delete(id: number) {
    return `${BASE_URI}/EquipeDistribuicao/${id}`
  },
}
