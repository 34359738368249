import React from 'react'
import { useSelector } from 'react-redux'
import { unstable_HistoryRouter as Router } from 'react-router-dom'

import AlertContainerComponent from 'containers/alert/AlertContainer'
import AuthenticatedRoutesProvider from 'routes/authenticate.routes'
import GuestRoutesProvider from 'routes/guest.routes'
import history from 'store/history'
import UserSelector from 'store/user/selector'

const ScreenRouter: React.FC = () => {
  const isAuthenticated = useSelector(UserSelector.isAuthenticate)

  const RouterContext = isAuthenticated ? AuthenticatedRoutesProvider : GuestRoutesProvider
  return (
    <Router history={history}>
      <RouterContext />
      <AlertContainerComponent />
    </Router>
  )
}

export default ScreenRouter
