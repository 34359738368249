import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: Record<string, boolean> = {}

type ModalPayload = PayloadAction<{
  key: string
  isOpen?: boolean
}>

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    add: (state, { payload }: ModalPayload) => ({ ...state, [payload.key]: Boolean(payload.isOpen) }),
    remove: (state, { payload }: ModalPayload) =>
      Object.entries(state).reduce((newState: Record<string, boolean>, [key, value]) => {
        if (payload.key !== key) {
          newState[key] = value
        }
        return newState
      }, {}),
    open: (state, { payload }: ModalPayload) => ({ ...state, [payload.key]: true }),
    close: (state, { payload }: ModalPayload) => ({ ...state, [payload.key]: false }),
    toggle: (state, { payload }: ModalPayload) => ({ ...state, [payload.key]: !state[payload.key] }),
    reset: () => initialState,
  },
})

export const modalsActions = modalsSlice.actions

export default modalsSlice.reducer
