import { ButtonHTMLAttributes, ReactNode } from 'react'

import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

import { CircularProgress } from '@material-ui/core'

const colors = {
  primary: '#0F73B2',
  danger: '#EB5757',
  warning: '#F2C94C',
  success: '#27AE60',
  default: '#646D7B',
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode
  loading?: boolean
  color?: keyof typeof colors
}

const Container = styled.button.attrs(props => ({
  type: props.type || 'button',
}))<ButtonProps>`
  min-width: 100px;
  padding: 0 1.5rem;
  height: 44px;
  color: #fff;
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: uppercase;
  background-color: ${props => colors[props.color || 'primary']};
  border: none;
  border-radius: 10px;
  transition: filter 250ms;

  &:hover {
    cursor: pointer;
    filter: brightness(0.95);
  }

  &:disabled {
    background-color: #bdbdbd;
    cursor: not-allowed;
  }

  @media (max-width: ${breakpoints.small}px) {
    width: 100%;
  }
`

export const ContainedButton = ({ children, loading, ...rest }: ButtonProps) => (
  <Container {...rest}>{loading ? <CircularProgress color="inherit" size={20} /> : children}</Container>
)
