import React from 'react'

import useModal from 'hooks/useModal'
import CloseIcon from 'icons/close'

import { Tooltip } from '@material-ui/core'

import { Backdrop, IconWrapper, ModalContent, ModalWrapper } from './styles'

type ModalProps = {
  id: string
  onCloseModal?: () => void | undefined
}

const Modal: React.FC<ModalProps> = ({ id, onCloseModal, children }) => {
  const { isOpen, closeModal } = useModal(id)

  return isOpen ? (
    <>
      <Backdrop onClick={onCloseModal || closeModal} />

      <ModalWrapper id={id}>
        <ModalContent>
          {children}
          <Tooltip title="Fechar">
            <IconWrapper onClick={onCloseModal || closeModal}>
              <CloseIcon />
            </IconWrapper>
          </Tooltip>
        </ModalContent>
      </ModalWrapper>
    </>
  ) : (
    <></>
  )
}

export default Modal
