import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const ModalBody = styled.main`
  display: flex;
  flex-direction: column;
  padding: 0px 48px;

  @media (max-width: ${breakpoints.small}px) {
    padding: 0px 24px;
    overflow-y: auto;
  }
`
