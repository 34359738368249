import { Instrumento, ProcessoProcedimento } from 'modules/processo-procedimento/types'

import { endpoints, endpointsMovimento } from './api/endpoints/processo-procedimento.endpoints'
import { httpDelete, httpGet, httpPost, httpPut } from './api/http-methods'
import {
  CreateMovimentoSchema,
  InstrumentoViewModel,
  ProcessoProcedimentoApiViewModel,
} from './api/models/processo-procedimento.models'
import { requestHandler } from './api/request-handler'
import { mapInstrumentoToApi, mapProcessoProcedimentoToApi } from './mappers/processo-procedimento.mappers'

type CreateInstrumentoParams = {
  processoProcedimentoId: string
  instrumento: Instrumento
}

export const checkExistenciaNuIDEA = requestHandler<boolean, string>(nuIdea =>
  httpGet(endpoints.verificarExistenciaNuIdea(nuIdea))
)

export const checkNumeroProcessoJudicialValido = requestHandler<boolean, string>(nuProcesso =>
  httpGet(endpoints.validarNumeroProcessoJudicial(nuProcesso))
)

export const checkProcessoJudicialAssociado = requestHandler<boolean, string>(nuProcesso =>
  httpGet(endpoints.processoJudicialAssociado(nuProcesso))
)

export const getProcessoProcedimentoById = requestHandler<ProcessoProcedimentoApiViewModel, string>(
  processoProcedimentoId => httpGet(endpoints.obterProcessoProcedimentoPorId(processoProcedimentoId))
)

export const createProcessoProcedimento = requestHandler<ProcessoProcedimentoApiViewModel, ProcessoProcedimento>(
  payload => httpPost(endpoints.cadastrar, mapProcessoProcedimentoToApi(payload))
)

export const updateProcessoProcedimento = requestHandler<ProcessoProcedimentoApiViewModel, ProcessoProcedimento>(
  payload => httpPut(endpoints.atualizar(String(payload.idProcProcedimento)), payload)
)

export const getInstrumentosByProcessoProcedimentoId = requestHandler<InstrumentoViewModel[], string>(
  processoProcedimentoId => httpGet(endpoints.obterInstrumentosPorProcessoProcedimento(processoProcedimentoId))
)

export const createInstrumento = requestHandler<InstrumentoViewModel, CreateInstrumentoParams>(params =>
  httpPost(endpoints.cadastrarInstrumento, mapInstrumentoToApi(params.processoProcedimentoId, params.instrumento))
)

export const updateInstrumento = requestHandler<void, CreateInstrumentoParams>(params =>
  httpPut(
    endpoints.atualizarInstrumento(String(params.instrumento.idProcProcedimentoInstrumento)),
    mapInstrumentoToApi(params.processoProcedimentoId, params.instrumento)
  )
)

export const deleteInstrumento = requestHandler<void, string>(instrumentoId =>
  httpDelete(endpoints.excluirInstrumento(instrumentoId))
)

export const createMovimento = requestHandler<void, CreateMovimentoSchema>(payload =>
  httpPost(endpointsMovimento.createMovimento, payload)
)
