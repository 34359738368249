import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl('global')

export const endpoints = {
  getEstado: `${BASE_URL}/Estado`,
  getPais: `${BASE_URL}/Pais`,
  getAssunto: `${BASE_URL}/Assunto`,
  getTema: `${BASE_URL}/Tema`,
  getClasse: `${BASE_URL}/Classe`,
  getResponsavel: `${BASE_URL}/Responsavel`,
  getEstadoCivil: `${BASE_URL}/TipoEstadoCivil/true`,
  getGenero: `${BASE_URL}/TipoGenero`,
  getGrauInstrucao: `${BASE_URL}/TipoGrauInstrucao`,
  getAtividadeNaoProcedimental: `${BASE_URL}/AtividadeNaoProcedimental`,
  getOrgaoUnidade: `${BASE_URL}/OrgaoUnidade/ObterOrgaosUnidadesResponsavel/`,
  getDocumento: `${BASE_URL}/TipoDocumento/true`,
  getDocumentoAll: `${BASE_URL}/TipoDocumento`,
  getTelefone: `${BASE_URL}/TipoTelefone`,
  getTpNivelAcesso: `${BASE_URL}/TpNivelAcesso`,
  getCidadeByUF(sgUf: string) {
    return `${BASE_URL}/Cidade?sgUf=${sgUf}`
  },
  getCidadeById(idCidade: string) {
    return `${BASE_URL}/Cidade/${idCidade}`
  },
  getUFCidadePorIdOrgaoUnidade(idOrgaoUnidade: number) {
    return `${BASE_URL}/Cidade/ObterCidadePorIdOrgaoUnidade/${idOrgaoUnidade}`
  },
  getBairroByCidade(idCidade: string | number) {
    return `${BASE_URL}/Bairro/${idCidade}`
  },
  getBairroById(idBairro: string | number) {
    return `${BASE_URL}/Bairro/ObterBairroPorId/${idBairro}`
  },
  getPovoadoDistrito(sgUf: string, idCidade: string | number) {
    return `${BASE_URL}/Cidade/${sgUf}/${idCidade}`
  },
  getOrgaoUnidadeAtuacaoByIds(query: string) {
    return `${BASE_URL}/OrgaoUnidade?${query}`
  },
  getAssuntoByClasseId(classeId: string | number, eleitoral = false) {
    return `${BASE_URL}/Assunto/ListarAssuntoPorClasse/${classeId}/${eleitoral}`
  },
  getEnderecoByCep(cep: string | number) {
    return `${BASE_URL}/Endereco/${cep}`
  },
}
