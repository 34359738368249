import { formatISO } from 'date-fns'
import { Instrumento, ProcessoProcedimento } from 'modules/processo-procedimento/types'
import { toMoney, toDecimal } from 'utils/format'

import {
  AcervoViewModel,
  InstrumentoViewModel,
  ProcessoProcedimentoApiViewModel,
} from '../api/models/processo-procedimento.models'

function parseValue(value?: string) {
  if (!value) {
    return null
  }
  return parseFloat(value.toString().replace('.', '').replace(',', '.').replace('R$', '').trim())
}

export function mapInstrumentoToApi(idProcesso: string, instrumento: Instrumento) {
  return {
    idProcProcedimentoInstrumento: instrumento.idProcProcedimentoInstrumento,
    idProcProcedimento: idProcesso,
    dsTpEspecie: instrumento.especie,
    idTpInstrumento: typeof instrumento.tipo === 'string' ? instrumento.tipo : instrumento.tipo.idTpInstrumento,
    idTpUnidadeMedida:
      typeof instrumento.unidadeMedida === 'string' ? null : instrumento.unidadeMedida.idTpUnidadeMedida,
    nuQuantidade: parseValue(instrumento.quantidade),
    dsPlacaVeiculo: instrumento.placaVeiculo ? instrumento.placaVeiculo.toUpperCase() : null,
    nuValor: parseValue(instrumento.valor),
    idTpModoApreensao:
      typeof instrumento.modoApreensao === 'string' ? null : instrumento.modoApreensao.idTpModoApreensao,
  }
}

export function mapInstrumentoToStore(data: InstrumentoViewModel) {
  const instrumento: Instrumento = {
    idProcProcedimentoInstrumento: data.idProcProcedimentoInstrumento,
    idProcProcedimento: data.idProcProcedimento,
    especie: data.dsTpEspecie ? data.dsTpEspecie.trim() : '',
    placaVeiculo: data.dsPlacaVeiculo || '',
    quantidade: data.nuQuantidade ? toDecimal(data.nuQuantidade) : '',
    valor: data.nuValor ? toMoney(data.nuValor) : '',
    tipo: data.tpInstrumento ? data.tpInstrumento : '',
    modoApreensao: data.tpModoApreensao ? data.tpModoApreensao : '',
    unidadeMedida: data.tpUnidadeMedida ? data.tpUnidadeMedida : '',
  }

  return instrumento
}

export const mapInstrumento = mapInstrumentoToStore

export function mapProcessoProcedimentoToApi(data: ProcessoProcedimento) {
  const processoProcedimento = {
    idProcProcedimento: data.idProcProcedimento,
    cdClasse: Number(data.classe[0].id),
    idTpNivelAcesso: Number(data.nivelAcesso),
    idOrgaoUnidade: data.orgaoUnidade?.value ? Number(data.orgaoUnidade.value) : undefined,
    idTema: data.tema && data.tema.length ? Number(data.tema[0].id) : undefined,
    nuProcesso: data.numeroProcesso ? data.numeroProcesso : null,
    dsInformacoesComplementares: data.informacoesComplementares,
    dsObjeto: data.objeto,
    dtProcProcedimento: data.dataHora && formatISO(data.dataHora),
    idComarca: data.idComarca ? data.idComarca : 0,
    expedientes: data.expedientesAssociados.map(item => ({
      idTpExpediente: Number(item.tipo),
      nuExpediente: item.tipo === '3' ? item.numero : item.numero.replace(/[^0-9]+/g, ''),
    })),
    assuntos: data.assuntos.map((item, index) => ({
      idAssunto: Number(item.id),
      stPrincipal: Object.hasOwn(item, 'principal') ? item.principal : index === 0,
      idProcProcedimento: item.idProcProcedimento,
      idProcProcedimentoAssunto: item.idProcProcedimentoAssunto,
    })),
    envolvidos: data.envolvidos.map(item => ({
      idPessoaFisica: item.tpPessoa === 'PF' && item.idPessoa ? Number(item.idPessoa) : undefined,
      idPessoaJuridica: item.tpPessoa === 'PJ' && item.idPessoa ? Number(item.idPessoa) : undefined,
      idTpEnvolvimento: Number(item.tpEnvolvimento.value),
      nmPessoa: item.nmPessoa,
      stDesconhecido: item.stDesconhecido,
      stSociedade: item.stSociedade,
      stTpPessoa: item.tpPessoa === 'PF' ? 'F' : 'J',
      idProcProcedimento: item.idProcProcedimento,
      idProcProcedimentoEnvolvimento: item.idProcProcedimentoEnvolvimento,
    })),
    equipeDist: data.idEquipeDist
      ? {
          idProcProcedimento: data.idProcProcedimento,
          idEquipeDist: data.idEquipeDist,
          stDistribuir: data.stDistribuir,
          stEncaminhamentoAutomatico: data.stEncaminhamentoAutomatico,
        }
      : undefined,
  }

  return processoProcedimento
}

export function mapEnderecoToStore(data: ProcessoProcedimentoApiViewModel) {
  if (!data.nuCep) {
    return undefined
  }

  return {
    nuCep: data.nuCep,
    dsComplementoLogradouro: data.complemento,
    dsLogradouro: data.dsLogradouro,
    dsPontoReferencia: data.dsPontoReferencia,
    uf: data.uf ? { value: data.uf.idUf, label: data.uf.sgUf } : { value: '', label: '' },
    bairro: data.bairro ? { value: data.bairro.cdBairro, label: data.bairro.nmBairro } : { value: '', label: '' },
    municipio: data.municipio
      ? { value: data.municipio.cdMunicipio, label: data.municipio.nmMunicipio }
      : { value: '', label: '' },
    distrito: { value: '', label: '' },
    stPrincipal: true,
  }
}

export function mapProcessoProcedimentoToStore(data: ProcessoProcedimentoApiViewModel) {
  const processoProcedimento: ProcessoProcedimento = {
    idProcProcedimento: data.idProcProcedimento,
    dataHora: new Date(data.dtProcProcedimento),
    nivelAcesso: String(data.idTpNivelAcesso),
    numeroProcesso: data.nuProcesso,
    idComarca: data.idComarca,
    informacoesComplementares: data.dsInformacoesComplementares,
    objeto: data.dsObjeto,
    orgaoUnidade: null,
    nuIdea: data.nuIdea,
    assuntos: data.assuntos?.map(item => ({
      idProcProcedimento: item.idProcProcedimento,
      idProcProcedimentoAssunto: item.idProcProcedimentoAssunto,
      id: String(item.idAssunto),
      path: '',
      principal: item.stPrincipal,
    })),
    classe: [{ id: String(data.cdClasse), path: '' }],
    tema: [],
    expedientesAssociados: [],
    envolvidos: data.envolvidos?.map(item => ({
      idProcProcedimento: item.idProcProcedimento,
      idProcProcedimentoEnvolvimento: item.idProcProcedimentoEnvolvimento,
      tpPessoa: item.stTpPessoa === 'J' ? 'PJ' : 'PF',
      nmPessoa: '',
      tpEnvolvimento: { value: item.idTpEnvolvimento, label: '' },
      stDesconhecido: item.stDesconhecido,
      stSociedade: item.stSociedade,
    })),
    instrumentos: data.instrumentos ? data.instrumentos?.map(mapInstrumentoToStore) : [],
  }

  return processoProcedimento
}

export function tempMapAcervo(data: AcervoViewModel) {
  return {
    ...data,
    dtProcProcedimento: new Date(data.dtProcProcedimento),
    etiqueta: {
      private: false,
    },
    envolvidos: {
      sigiloso: false,
    },
    dtUltimaEntrada: '-',
    nuDiasSemMovimento: '-',
  }
}

export type MappedAcervo = ReturnType<typeof tempMapAcervo>

export const mapProcessoProcedimento = mapProcessoProcedimentoToStore
