import { createTheme } from '@material-ui/core/styles'

import colors from './colors'
import fonts from './fonts/fonts'

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    warning: {
      main: colors.yellow,
      contrastText: colors.white,
    },
  },
  typography: { fontFamily: fonts.fontFamily },
  overrides: {
    MuiTextField: {
      root: {
        fontSize: 16,
      },
    },
    MuiSwitch: {
      switchBase: {
        '&$checked': {
          color: `${colors.lightBlue} !important`,
        },
        '&$checked + $track': {
          backgroundColor: `${colors.lighterBlue} !important`,
        },
        '&:not($checked)': {
          color: `${colors.lightGrey} !important`,
        },
        '&:not($checked) + $track': {
          backgroundColor: `${colors.lighterGrey} !important`,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        fontFamily: fonts.fontFamily,
        color: '#9d9d9d',
        fontSize: 12,
      },
    },
  },
})

export default theme
