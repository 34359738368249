import React, { ReactElement } from 'react'

import { IconProps } from './types'

const LogoffIcon = ({ color = '#5A6473' }: IconProps): ReactElement => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0625 7.00244H4.875" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7.0625 9.18994L4.875 7.00244L7.0625 4.81494"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5709 9.68332C11.3317 12.2291 8.50913 13.5937 5.74426 12.9838C2.97939 12.3738 0.992914 9.9483 0.93981 7.11745C0.886705 4.2866 2.78081 1.78828 5.52086 1.07507C8.26091 0.361865 11.1327 1.61966 12.4665 4.11715"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LogoffIcon
