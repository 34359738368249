import { createSlice, createListenerMiddleware, PayloadAction } from '@reduxjs/toolkit'
import { AtividadeNaoProcedimental } from 'modules/atividade-nao-procedimental/types'
import { modalsActions } from 'store/modals/duck'

type AtividadeNaoProcedimentalStore = {
  currentStep: number
  completedStep: Array<number>
  isSubmiting: boolean
  selectedIdToDelete: string | null
  values: AtividadeNaoProcedimental
  valuesView?: AtividadeNaoProcedimental
}

const initialState: AtividadeNaoProcedimentalStore = {
  currentStep: 0,
  completedStep: [],
  isSubmiting: false,
  selectedIdToDelete: null,
  values: {
    procedimento: [],
    assunto: [],
    dtInicio: null,
    dtFim: null,
    tema: [],
    orgaoUnidade: '',
    uf: '',
    municipio: '',
    bairro: '',
    nuReferencia: '',
    responsaveis: [],
    anexos: [],
    nuIdea: '',
  },
}

export const atividadeNaoProcedimentalSlice = createSlice({
  initialState,
  name: 'atividadeNProcedimental',
  reducers: {
    reset: () => initialState,
    create: state => state,
    update: state => state,
    delete: (state, { payload: _payload }: PayloadAction<{ onSuccess: () => void }>) => state,
    openModalConfirmDelete(state, { payload }: PayloadAction<{ anpId: string }>) {
      state.selectedIdToDelete = payload.anpId
    },
    cancelModalConfirmDelete(state) {
      state.selectedIdToDelete = null
    },
    setIsSubmiting(state, action) {
      state.isSubmiting = action.payload
    },
    setValues(state, action) {
      state.values = {
        ...state.values,
        ...action.payload,
      }
    },
    setValuesView(state, action) {
      state.valuesView = action.payload
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload
    },
    setCompletedStep(state, action) {
      state.completedStep = action.payload
    },
    addAnexo(state, action) {
      if (!state.values.anexos) {
        state.values.anexos = []
      }
      if (Array.isArray(action.payload)) {
        state.values.anexos.push(...action.payload)
      } else {
        state.values.anexos.push(action.payload)
      }
    },
    removeAnexo(state, action) {
      if (!state.values.anexos) {
        state.values.anexos = []
      }
      state.values.anexos.splice(action.payload, 1)
    },
  },
})

const listener = createListenerMiddleware()

listener.startListening({
  type: atividadeNaoProcedimentalSlice.actions.openModalConfirmDelete.type,
  effect(_, { dispatch }) {
    dispatch(modalsActions.open({ key: 'modal-confirm-delete-anp' }))
  },
})

listener.startListening({
  type: atividadeNaoProcedimentalSlice.actions.cancelModalConfirmDelete.type,
  effect(_, { dispatch }) {
    dispatch(modalsActions.close({ key: 'modal-confirm-delete-anp' }))
  },
})

export const atividadeNaoProcedimentalMiddleware = listener
export const atividadeNaoProcedimentalActions = atividadeNaoProcedimentalSlice.actions
export default atividadeNaoProcedimentalSlice.reducer
