import styled from 'styled-components'
import colors from 'styles/colors'
import fonts from 'styles/fonts/fonts'

export const ShortcutMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background-color: ${colors.primary};
  z-index: 10;

  font-family: ${fonts.fontFamilyRaleway};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
`

export const ShortcutMenuItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 0 34px;
`

export const ShortcutMenuHeader = styled(ShortcutMenuItem)`
  margin-top: 20px;

  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;

  justify-content: space-between;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &:before,
  &:after {
    width: 100%;
    border-bottom: 1px solid ${colors.lightBlue};
    mix-blend-mode: soft-light;
  }
`

export const ShorcutMenuProfile = styled.div`
  font-family: ${fonts.fontFamily};
  position: relative;
  padding: 34px 24px;
  display: flex;
  gap: 8px;
  align-items: center;

  .username {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.white};
    margin-bottom: 2px;
  }

  .user-type {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #b5b6b7;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${colors.lightBlue};
    mix-blend-mode: soft-light;
  }
`

export const ShortcutMenuFooter = styled.div`
  font-family: ${fonts.fontFamily};
  background: #0d659c;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 14px 24px;
`
