import { ActionButton } from './ActionButton'
import { ContainedButton } from './ContainedButton'
import { IconButton } from './IconButton'
import { OutlinedButton } from './OutlinedButton'
import { SearchButton } from './SearchButton'
import { TextButton } from './TextButton'

export const Button = {
  Icon: IconButton,
  Outlined: OutlinedButton,
  Contained: ContainedButton,
  Text: TextButton,
  Action: ActionButton,
  Search: SearchButton,
}
