export default {
  MSG0001: 'Cadastro concluído com sucesso.',
  MSG0002: 'Nenhuma ocorrência para o(s) filtro(s) informado(s).',
  MSG0003: 'Filtro(s) de busca não informado(s).',
  MSG0004:
    'Para recuperar seu acesso, por favor, entre em contato com a Central de Serviços de TI do MPBA através do telefone (71) 3103-0640 ou e-mail csti@mpba.mp.br. ',
  MSG0005: 'Preenchimento obrigatório.',
  MSG0006: 'Ao confirmar a sua saída, todas as alterações não salvas serão perdidas.',
  MSG0007: 'Atenção! Formato de arquivo inválido. Por favor, revise para continuar.',
  MSG0008: 'Atenção! Tamanho de arquivo inválido. Por favor, revise para continuar.',
  MSG0009: 'Dados editados com sucesso!',
  MSG0010: 'Ao confirmar o cancelamento, todas as alterações não salvas serão perdidas. ',
  MSG0011: 'Que deseja excluir este registro?',
  MSG0012: 'Formato inválido!',
  MSG0013: 'Dados atualizado com sucesso!',
  MSG0014: 'Dados excluídos com sucesso!',
  MSG0015:
    'Utilize este campo para identificar o processo/procedimento, inserindo palavras-chaves. Estas informações não compõem o corpo do processo/procedimento.',
  MSG0016: 'Processo/procedimento não existente.',
  MSG0017: 'Não é possível realizar esta associação.',
  MSG0018: 'Dados inválidos.',
  MSG0019: 'Ocorreu um erro inesperado ao gerar o documento. Favor tentar novamente posteriormente.',
  MSG0020: 'Confirma a exclusão? Voltar. Sim, Excluir.',
  MSG0021: 'Operação realizada com sucesso!',
  MSG0022: 'Esta funcionalidade possui um ou mais parâmetros inativos. Consulte o administrador do sistema.',
  MSG0023: 'Por favor, verifique o preenchimento do(s) campo(s) sinalizado(s).',
  MSG0028:
    'Utilize este campo para descrição do ato e/ou fato objeto da representação ou da Portaria. Estas informações não compõem o corpo do processo/procedimento.',
  MSGE0004: 'A data de nascimento não deve ser igual ou maior que a data atual.',
  MSGE0007: 'Informe a UF de emissão.',
  MSGE0008: 'Documento inválido!',
  MSGE0015: 'CNPJ inválido!',
  MSGE0033: 'CPF inválido!',
  MSGE0034: 'Já existe pessoa física cadastrada com o CPF informado.',
  MSGE0047: 'Apenas parâmetros inativos podem ser excluídos.',
  MSGE0081: 'Informe um ou mais filtros para busca pela data de nascimento.',
  MSGE0086: 'Chave já existente.',
  MSGE0091: 'Valor já cadastrado.',
  MSGE110: 'Informe aqui o número do processo judicial, se houver',
  MSGE111: 'Atenção! Este Nº de Processo Judicial já está associado a outro Processo/Procedimento.',
  MSGE112: 'Atenção! O número IDEA inserido já está associado a este Processo/Procedimento.',
  MSGE113: 'Envolvido já vinculado.',
  MSGE114: 'É necessário vincular envolvido(s) ao processo/procedimento.',
  MSGE115: 'Processo/Procedimento cadastrado com sucesso. Nº IDEA',
  MSGE121: 'Atenção! Já existe pessoa jurídica cadastrada com o CNPJ informado.',
  MSGE122: 'Digite um valor entre -90,00000 a 90,00000.',
  MSGE0019: 'Data superior à data atual.',
  MSGE0020: 'Data-hora final inferior à data-hora inicial.',
  MSGE0023: 'Processo/procedimento inexistente.',
  MSGE0037: 'Deseja realmente excluir o endereço principal?',
  MSGE0038: 'Deseja realmente excluir o e-mail principal?',
  MSGE0039: 'Deseja realmente excluir o telefone principal?',
  MSGE0042: 'A data de abertura não deve ser maior que a data atual.',
  MSGE0058: 'O Processo/Procedimento selecionado não está em andamento no mesmo OU do usuário autenticado.',
  MSGE0069: 'Atenção! É necessário ter um endereço principal.',
  MSGE0082: 'E-mail já cadastrado.',
  MSGE0083: 'Telefone já cadastrado.',
  MSGE0084: 'Endereço já cadastrado.',
  MSGE0144:
    'Atenção! A exclusão de uma Atividade Não Procedimental causará impacto na produtividade de todos os responsáveis envolvidos e esta ação deve ser avisada aos mesmos.',
  MSGE0145:
    'Atenção! A edição de uma Atividade Não Procedimental poderá causar impacto na produtividade de todos os responsáveis envolvidos e esta ação deve ser avisada aos mesmos.',
  MSGE0146: 'Atenção! Pelo menos um campo deve ser preenchido.',
  MSGE0147:
    'Não é possível realizar a exclusão da Atividade não Procedimental, pois o órgão/unidade de atuação é diferente do órgão/unidade do usuário logado.',
  MSGE0148:
    'Não é possível realizar a edição da Atividade não Procedimental, pois o órgão/unidade de atuação é diferente do órgão/unidade do usuário logado.',
  MSGE_000: 'Atenção! O número de Processo Judicial inserido já está associado a este Processo/Procedimento.',
}
