import { Setting } from 'store/settings/duck'

import { endpoints } from './api/endpoints/configuracao.endpoints'
import { httpDelete, httpGet, httpPost, httpPut } from './api/http-methods'
import { requestHandler } from './api/request-handler'

type Configuracao = Setting

export const getConfiguracoes = requestHandler<Configuracao[]>(() => httpGet(endpoints.getAll))

export const getConfiguracaoById = requestHandler<Configuracao, number>(configuracaoId =>
  httpGet(endpoints.getById(configuracaoId))
)

export const getConfiguracaoByChave = requestHandler<Configuracao[], number>(configuracaoId =>
  httpGet(endpoints.getById(configuracaoId))
)

export const createConfiguracao = requestHandler<Configuracao, Configuracao>(payload =>
  httpPost(endpoints.create, payload)
)

export const updateConfiguracao = requestHandler<Configuracao, Configuracao>(payload =>
  httpPut(endpoints.update, payload)
)

export const deleteConfiguracao = requestHandler<void, number>(configuracaoId =>
  httpDelete(endpoints.delete(configuracaoId))
)

export const deleteConfiguracaoValor = requestHandler<void, number>(valorId =>
  httpDelete(endpoints.deleteValor(valorId))
)
