import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import MenuDrawer from 'containers/MenuDrawer/MenuDrawer'
import Topbar from 'containers/topbar/Topbar'
import PermissionsSelector from 'store/permissions/selectors'

import { getMenuOptions } from './menu'
import { Content, Container, Main } from './styles'

const Authenticated: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false)

  const PERMISSION_CAD_NAO_PROCEDIMENTAL =
    useSelector(PermissionsSelector.getPermissionsByUrl('/atividade-nao-procedimental/cadastrar')).length > 0
  const PERMISSION_CAD_PESSOA_FISICA =
    useSelector(PermissionsSelector.getPermissionsByUrl('/pessoa-fisica/cadastrar')).length > 0
  const PERMISSION_CAD_PESSOA_JURIDICA =
    useSelector(PermissionsSelector.getPermissionsByUrl('/pessoa-juridica/cadastrar')).length > 0
  const PERMISSION_VISUALIZAR_CONFIGURACAO_SISTEMA =
    useSelector(PermissionsSelector.getPermissionsByUrl('/configuracao-sistema')).length > 0
  const PERMISSION_CONSULTAR_EQUIPE_DISTRIBUICAO = 
    useSelector(PermissionsSelector.getPermissionsByUrl('/equipe-distribuicao/listar')).length > 0
  const PERMISSION_CADASTRAR_EQUIPE_DISTRIBUICAO = 
    useSelector(PermissionsSelector.getPermissionsByUrl('/equipe-distribuicao/cadastrar')).length > 0

  const mappedPermissions = [
    { route: '/atividade-nao-procedimental/cadastrar', hasPermission: PERMISSION_CAD_NAO_PROCEDIMENTAL },
    { route: '/processo-procedimento/cadastrar', hasPermission: true },
    { route: '/consulta', hasPermission: true },
    { route: '/pessoa-fisica/cadastrar', hasPermission: PERMISSION_CAD_PESSOA_FISICA },
    { route: '/pessoa-juridica/cadastrar', hasPermission: PERMISSION_CAD_PESSOA_JURIDICA },
    { route: '/configuracao-sistema', hasPermission: PERMISSION_VISUALIZAR_CONFIGURACAO_SISTEMA },
    { route: '/equipe-distribuicao/listar', hasPermission: PERMISSION_CONSULTAR_EQUIPE_DISTRIBUICAO },
    { route: '/equipe-distribuicao/cadastrar', hasPermission: PERMISSION_CADASTRAR_EQUIPE_DISTRIBUICAO },    
  ]

  const dataMenu = getMenuOptions(mappedPermissions)

  return (
    <Container>
      <Topbar id="topbar" onClick={() => setOpen(prev => !prev)} />
      <Main>
        <div className="main__wrapper">
          <MenuDrawer id="MenuDrawer" data={dataMenu} openDrawer={open} onClick={() => setOpen(prev => !prev)} />
          <Content>
            <div className="content__wrapper">{children}</div>
          </Content>
        </div>
      </Main>
    </Container>
  )
}

export default Authenticated
