import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'
import colors from 'styles/colors'
import fonts from 'styles/fonts/fonts'

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  mix-blend-mode: normal;
  backdrop-filter: blur(16px);
  z-index: 15;
`

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 15;
`

export const ModalContent = styled.div`
  position: relative;
  background: ${colors.white};
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1.5rem;
  max-height: 95vh;
  max-width: 70vw;
  padding: 20px;
  pointer-events: all;
  
  @media (max-width: ${breakpoints.small}px) {
    max-width: 100vw;
    width: 100%;
    max-height: 95vh;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: .75rem;
    padding: .5rem;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`

export const ModalTitle = styled.h1<{ color: keyof typeof colors }>`
  font-family: ${fonts.fontFamilyRaleway};
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 123.5%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: ${props => colors[props.color]};
`

export const ModalText = styled.p`
  font-family: ${fonts.fontFamilyRaleway};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 143%;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
  letter-spacing: 0.15px;
  color: #5f5f5f;
  width: 75%;
  padding: 15px 0;
`
