import React, { Suspense, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import map from 'lodash/map'

export type RouteType = {
  path: string
  Component: React.LazyExoticComponent<React.FC>
}

type RouterSwitchProps = {
  routes: Record<string, RouteType>
}

const RouterSwitch: React.FC<RouterSwitchProps> = ({ routes }) => {
  const enabledRoutes = useMemo(
    () => map(routes, ({ path, Component }, name) => <Route key={name} path={path} element={<Component />} />),
    [routes]
  )

  // TODO: adicionar componente de loader como fallback
  return (
    <Suspense fallback={<h1>Carregando</h1>}>
      <Routes>{enabledRoutes}</Routes>
    </Suspense>
  )
}

export default RouterSwitch
