import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ErrorStore = Record<string, string[]>

const initialState: ErrorStore = {}

type PayloadAddError = PayloadAction<{
  key: string
  errors: string[]
}>

const errorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    add: (state: ErrorStore, { payload }: PayloadAddError) => ({ ...state, [payload.key]: payload.errors }),
    remove: (
      state: ErrorStore,
      {
        payload,
      }: PayloadAction<{
        key: string
      }>
    ) =>
      Object.entries(state).reduce((newState: ErrorStore, [key, value]) => {
        if (payload.key !== key) {
          newState[key] = value
        }
        return newState
      }, {}),
    reset: () => initialState,
  },
})

export const errorActions = errorSlice.actions

export default errorSlice.reducer
