import axios, { AxiosError } from 'axios'

import { requestInterceptor, responseInterceptor } from './interceptors'

const BASE_URL = process.env.REACT_APP_API_URL
const USE_LOCAL_APIS = process.env.REACT_APP_USE_LOCAL_APIS

const instance = 
  USE_LOCAL_APIS 
  ? axios.create({  })
  : axios.create({ baseURL: BASE_URL })

instance.interceptors.request.use(requestInterceptor)
instance.interceptors.response.use(responseInterceptor)

export function isAxiosError(unknownError: unknown): unknownError is AxiosError {
  const error = unknownError as AxiosError

  if (typeof error !== 'object' || error === null) {
    return false
  }

  if (!('response' in error) || typeof error.response !== 'object') {
    return false
  }

  if (error.response === null || !('data' in error.response)) {
    return false
  }

  return true
}

export const axiosInstance = instance
