export const NUM_PROCESSO_ORIGINARIO_ID = '1'
export const NUM_PROCESSO_RELACIONADO_ID = '2'
export const NUM_IDEA_ID = '3'
export const NUM_OUTRO_ID = '4'

export const mapTipoExpediente = {
  [NUM_PROCESSO_ORIGINARIO_ID]: {
    mask: '9999999-99.9999.9.99.9999',
    label: 'N° do Processo',
  },
  [NUM_PROCESSO_RELACIONADO_ID]: {
    mask: '9999999-99.9999.9.99.9999',
    label: 'N° do Processo',
  },
  [NUM_IDEA_ID]: {
    mask: '999.9.999999/9999',
    label: 'N° IDEA',
  },
  [NUM_OUTRO_ID]: {
    mask: '9999999999999999999999999',
    label: 'Número',
  },
}
