import { ReactElement } from 'react'
import { Provider } from 'react-redux'

import { Store } from '@reduxjs/toolkit'
import { store } from 'store/index'

export default function withProvider(
  WrapperComponent: (_props: Record<string, unknown>) => ReactElement,
  enhancedStore: Store = store
): (_props: Record<string, unknown>) => ReactElement {
  return function (props) {
    return (
      <Provider store={enhancedStore}>
        <WrapperComponent {...props} />
      </Provider>
    )
  }
}
