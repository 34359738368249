import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import PerfilAvatar from 'components/avatar/Avatar'
import IconButton from 'components/iconButton/IconButton'
import useDrawer from 'hooks/useDrawer'
import { useHandleNavigate } from 'hooks/useHandleNavigate'
import ChangeIcon from 'icons/change'
import ChartIcon from 'icons/chart'
import CloseMenuIcon from 'icons/close-menu'
import GridIcon from 'icons/grid'
import HomeIcon from 'icons/home'
import OrgaoUnidadeSelector from 'store/orgaoUnidade/selector'
import UserSelector from 'store/user/selector'
import colors from 'styles/colors'
import { DefaultProps } from 'styles/types'

import { Slide } from '@material-ui/core'


import {
  ShorcutMenuProfile,
  ShortcutMenuFooter,
  ShortcutMenuHeader,
  ShortcutMenuItem,
  ShortcutMenuWrapper,
} from './styles'

const ShortCutMenu = ({ id }: DefaultProps) => {
  const userName = useSelector(UserSelector.name)
  const matricula = useSelector(UserSelector.id)
  const { isOpen, closeDrawer } = useDrawer(id)
  const { openDrawer: openUserDrawer } = useDrawer('user-drawer')
  const navigate = useHandleNavigate()
  const orgaosUnidadeSelecionado = useSelector(OrgaoUnidadeSelector.orgaosUnidadeSelecionado)

  useEffect(
    () => () => closeDrawer(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleNavigation = useCallback(
    (navigate: () => void) => {
      navigate()
      closeDrawer()
    },
    [closeDrawer]
  )

  const handleUserProfile = useCallback(() => {
    closeDrawer()
    openUserDrawer()
  }, [closeDrawer, openUserDrawer])

  return (
    <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
      <ShortcutMenuWrapper id={id}>
        <nav>
          <ShortcutMenuHeader onClick={() => handleNavigation(() => navigate('/'))}>
            <div>
              <IconButton id="grid-icon" tooltipTitle="Atalhos">
                <GridIcon />
              </IconButton>
              Atalhos
            </div>
            <IconButton id="close-icon" tooltipTitle="Fechar atalhos" onClick={closeDrawer}>
              <CloseMenuIcon />
            </IconButton>
          </ShortcutMenuHeader>
          <ShortcutMenuItem onClick={() => handleNavigation(() => navigate('/'))}>
            <IconButton id="home-icon" tooltipTitle="Início">
              <HomeIcon />
            </IconButton>
            Início
          </ShortcutMenuItem>
          <ShortcutMenuItem
            onClick={() =>
              handleNavigation(() =>
                window.open('https://bi.sistemas.mpba.mp.br/reports/powerbi/AplicacoesBI?rs:embed=true', '_blank')
              )
            }
          >
            <IconButton id="chart-icon" tooltipTitle="BI - Business inteligence">
              <ChartIcon />
            </IconButton>
            BI - Business inteligence
          </ShortcutMenuItem>
          <ShortcutMenuItem onClick={() => handleNavigation(() => navigate('/orgao-unidade/trocar'))}>
            <IconButton id="change-icon" tooltipTitle="Trocar de Órgão">
              <ChangeIcon color={colors.white} />
            </IconButton>
            Trocar de Órgão
          </ShortcutMenuItem>
        </nav>
        <div>
          <ShorcutMenuProfile onClick={handleUserProfile}>
            <PerfilAvatar
              id="perfil-avatar"
              userName={userName}
              variant="rounded"
              style={{ width: '48px', height: '48px' }}
            />
            <div>
              <p className="username">{userName}</p>
              <p className="user-type">{matricula}</p>
            </div>
          </ShorcutMenuProfile>
          <ShortcutMenuFooter>
            <p> {orgaosUnidadeSelecionado ? orgaosUnidadeSelecionado?.nome : ''}</p>
          </ShortcutMenuFooter>
        </div>
      </ShortcutMenuWrapper>
    </Slide>
  )
}

export default ShortCutMenu
