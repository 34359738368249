import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const state = (state: AppState) => state.pessoaJuridica

const values = createSelector(state, pessoaJuridica => pessoaJuridica.form.values)
const currentStep = createSelector(state, pessoaJuridica => pessoaJuridica.form.currentStep)
const completedStep = createSelector(state, pessoaJuridica => pessoaJuridica.form.completedStep)
const origin = createSelector(state, pessoaJuridica => pessoaJuridica.form.origin)
const readOnlyCNPJ = createSelector(state, pessoaJuridica => pessoaJuridica.form.readOnlyCNPJ)
const view = createSelector(state, pessoaJuridica => pessoaJuridica.view)

export const pessoaJuridicaSelectors = {
  completedStep,
  currentStep,
  readOnlyCNPJ,
  values,
  origin,
  valuesView: view,
}
