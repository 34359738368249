import styled from 'styled-components'

export const AvatarContainer = styled.div`
  .MuiAvatar-img {
    border-radius: 14px !important;
  }

  .MuiAvatar-rounded {
    border-radius: 24px !important;
  }
`
