import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const state = (state: AppState) => state.pessoaFisica

const values = createSelector(state, pessoaFisica => pessoaFisica.values)
const currentStep = createSelector(state, pessoaFisica => pessoaFisica.currentStep)
const completedStep = createSelector(state, pessoaFisica => pessoaFisica.completedStep)
const origin = createSelector(state, pessoaFisica => pessoaFisica.origin)
const searchByCPF = createSelector(state, pessoaFisica => pessoaFisica.searchByCPF)
const searchByRG = createSelector(state, pessoaFisica => pessoaFisica.searchByRG)
const readOnlyCPF = createSelector(state, pessoaFisica => pessoaFisica.readOnlyCPF)

export const pessoaFisicaSelectors = {
  completedStep,
  currentStep,
  searchByCPF,
  searchByRG,
  readOnlyCPF,
  values,
  origin
}
