import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: string[] = []

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    add(state, { payload }: PayloadAction<string>) {
      if (state.includes(payload)) return state
      return [...state, payload]
    },
    remove: (state, { payload }: PayloadAction<string>) => state.filter(loadingKey => loadingKey !== payload),
    reset: () => initialState,
  },
})

export const loaderActions = loaderSlice.actions

export default loaderSlice.reducer
