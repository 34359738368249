import { ButtonHTMLAttributes } from 'react'

import styled from 'styled-components'

const colors = {
  primary: '#0F73B2',
  danger: '#EB5757',
  warning: '#F2C94C',
  success: '#27AE60',
  default: '#646D7B',
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: keyof typeof colors
}

export const TextButton = styled.button.attrs(props => ({
  type: props.type || 'button'
}))<ButtonProps>`
  padding: 0 1.5rem;
  height: 44px;
  font-family: 'Poppins';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: uppercase;
  color: ${props => colors[props.color || 'primary']};
  border: 0;
  border-radius: 10px;
  background-color: transparent;
  transition: background-color .2s;

  &:hover {
    cursor: pointer;
    background-color: rgb(241 241 241 / 50%);
  }
`