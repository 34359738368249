import { Fragment, ReactNode, useEffect } from 'react'
import { createPortal } from 'react-dom'

type ModalRootProps = {
  children: ReactNode
  onClose?: VoidFunction
}

export const ModalRoot = ({ children, onClose }: ModalRootProps) => {
  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (typeof onClose === 'function') {
          onClose()
        }
      }
    }
    document.addEventListener('keydown', keydownListener)

    return () => document.removeEventListener('keydown', keydownListener)
  }, [onClose])

  return createPortal(<Fragment>{children}</Fragment>, document.body)
}
