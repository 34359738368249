import React, { ReactElement } from 'react'

import Snackbar from '@material-ui/core/Snackbar'
import { Alert, Color } from '@material-ui/lab'

import { AlertStyles } from './styles'

type AlertProps = {
  message: string
  severity: Color
  open?: boolean
  action?: ReactElement
  onClose: () => void
}

const AlertComponent: React.FC<AlertProps> = ({ message, severity, open, action, onClose }) => (
  <AlertStyles>
    <Snackbar
      open={open}
      autoHideDuration={3000}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      onClose={onClose}
    >
      <div>
        <Alert variant="filled" severity={severity} elevation={6} action={action}>
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </Alert>
      </div>
    </Snackbar>
  </AlertStyles>
)

export default AlertComponent
