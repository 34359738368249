import { formatISO } from 'date-fns'
import { AtividadeNaoProcedimental } from 'modules/atividade-nao-procedimental/types'
import { getOptionValue } from 'utils/helpers'

import { AnpApiViewModel } from '../api/models/atividade-nao-procedimental.models'

export function mapANPToApi(anp: AtividadeNaoProcedimental) {
  return {
    cdProcedimento: anp.procedimento[0].id,
    cdAssunto: anp.assunto[0].id,
    dtInicio: anp.dtInicio ? formatISO(anp.dtInicio) : undefined,
    dtFim: anp.dtFim ? formatISO(anp.dtFim) : undefined,
    idTema: anp.tema && anp.tema.length ? anp.tema[0].id : undefined,
    idUF: getOptionValue(anp.uf),
    cdMunicipio: getOptionValue(anp.municipio),
    cdBairro: getOptionValue(anp.bairro),
    nuReferencia: anp.nuReferencia,
    responsaveis: anp.responsaveis?.map((res, index) => ({
      idUsuario: res.idUsuario,
      idOrgaoUnidade: getOptionValue(anp.orgaoUnidade),
      stPrincipal: index === 0,
    })),
    anexos: anp.anexos?.map(an => ({
      idAnexoANProcedimental: an.idAnexoANProcedimental,
      idAtividadeNaoProcedimental: anp.idAtividadeNaoProcedimental,
      nmAnexo: an.nmAnexo || an.nmArquivo,
      dsCaminhoAnexo: an.dsCaminhoAnexo || an.dsCaminhoArquivo,
      stUpload: an.stUpload,
    })),
    idComarca: anp.idComarca,
  }
}

export function mapANPToStore(anp: AnpApiViewModel) {
  const atividadeNaoProcedimental: AtividadeNaoProcedimental = {
    idAtividadeNaoProcedimental: anp.idAtividadeNaoProcedimental,
    procedimento: [{ id: String(anp.procedimento.cdProcedimento), path: anp.procedimento.nmProcedimentoTree }],
    assunto: [{ id: String(anp.assunto.cdAssunto), path: anp.assunto.nmAssuntoTree }],
    tema: anp.tema?.idTema ? [{ id: String(anp.tema.idTema), path: anp.tema.dsTemaTree }] : [],
    uf: anp.uf ? { label: anp.uf.sgUF, value: anp.uf.idUF } : '',
    municipio: anp.municipio ? { label: anp.municipio.nmMunicipio, value: anp.municipio.cdMunicipio } : '',
    bairro: anp.bairro ? { label: anp.bairro.nmBairro, value: anp.bairro.cdBairro } : '',
    nuReferencia: anp.nuReferencia,
    responsaveis: anp.responsaveis?.map(res => ({
      nmUsuario: res.responsavel?.nmResponsavel,
      idUsuario: res.responsavel?.idResponsavel,
    })),
    orgaoUnidade: {
      value: anp.responsaveis?.[0]?.orgaoUnidade?.idOrgaoUnidade,
      label: anp.responsaveis?.[0]?.orgaoUnidade?.dsOrgaoUnidadeCompletaMunicipio,
    },
    anexos: anp.anexos,
    dtInicio: new Date(anp.dtInicio),
    dtFim: anp.dtFim ? new Date(anp.dtFim) : null,
    nuAno: anp.nuAno,
    idComarca: anp.idComarca,
    nuIdea: anp.nuIdea,
  }

  return atividadeNaoProcedimental
}
