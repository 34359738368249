import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import clsx from 'clsx';
import withForm from 'hoc/with-form'
import { useHandleNavigate } from 'hooks/useHandleNavigate'
import useWindowDimensions from 'hooks/useWindowDimensions'
import DoubleCaretLeftIcon from 'icons/doubleCaretLeft'
import ServerIcon from 'icons/serverIcon'
import { IconType } from 'icons/types';
import PermissionsSelector from 'store/permissions/selectors'
import breakpoints from 'styles/breakpoints'

import { Tooltip } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse'
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';


import { 
  CloseIconContainer, 
  CloseText, 
  ListDrawer,
  ItemWrapper,
  ListItem,
  OtherMenus,
  useStyles,
  CollapseItem
} from './styles'

interface collapseOptions {
  title: string
  route: string
  query?: string
}

interface Option {
  id: number
  title: string
  icon: IconType
  route?: string
  query?: string
  collapseOptions: collapseOptions[]
}

interface MenuProps {
  id: string
  openDrawer: boolean
  onClick: VoidFunction
  data: Option[]
}

const MenuDrawer = ({ id, data, openDrawer, onClick }: MenuProps) => {
  
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const { width } = useWindowDimensions()
  const navigate = useHandleNavigate()
  const classes = useStyles();

  const [selectedId, setSelectedId] = useState(0)
  const semPermissao = "Você não possui permissão"

  const PERMISSION_CAD_NAO_PROCEDIMENTAL =  useSelector(PermissionsSelector.getPermissionsByUrl('/atividade-nao-procedimental/cadastrar')).length > 0
  const PERMISSION_CAD_PESSOA_FISICA =  useSelector(PermissionsSelector.getPermissionsByUrl('/pessoa-fisica/cadastrar')).length > 0
  const PERMISSION_CAD_PESSOA_JURIDICA =  useSelector(PermissionsSelector.getPermissionsByUrl('/pessoa-juridica/cadastrar')).length > 0

  const handleMenu = (id: number) => {    
    if (!open && !(id === selectedId)) {
      setOpen(true);
    }    
    setSelectedId(id)
  }

  const handleDrawer = () => {    
    setOpen(!open)
  }

  const handleTitleTooltip = (title: string, route: string) => {
    let titleAux = title
    if(route.length > 0){
      if(route === "/pessoa-fisica/cadastrar") {
        if(PERMISSION_CAD_PESSOA_FISICA) {
          titleAux = title
        } else {
          titleAux = semPermissao
        }
      } else if(route === "/pessoa-juridica/cadastrar") {
        if(PERMISSION_CAD_PESSOA_JURIDICA) {
          titleAux = title
        } else {
          titleAux = semPermissao
        }
      } else if(route === "/atividade-nao-procedimental/cadastrar") {
        if(PERMISSION_CAD_NAO_PROCEDIMENTAL) {
          titleAux = title
        } else {
          titleAux = semPermissao
        }
      }
    }
    return titleAux
  }

  return (
    <>
      {width > breakpoints.medium ? 
        <Drawer variant="permanent" className={clsx(classes.drawer, {
          [classes.drawerOpen] : open, [classes.drawerClose]: !open
        })} classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }} id={id}
        >
          <CloseIconContainer open={open}>
            {open ?
              <Tooltip title="Comprimir Menu">
                <div>
                  <IconButton onClick={handleDrawer}>
                    <DoubleCaretLeftIcon />
                  </IconButton>
                  <CloseText>Comprimir menu</CloseText>
                </div> 
              </Tooltip>
              :
              <Tooltip title="Expandir Menu">
                <div className="rotated">
                  <IconButton onClick={() => setOpen(!open)}>
                    <DoubleCaretLeftIcon />
                  </IconButton>
                </div>  
              </Tooltip>
            }
          </CloseIconContainer>
          <ListDrawer>
            {data?.map(({ id: optionId, title, collapseOptions, icon: Icon, route, query }) => (
              <>
                <ItemWrapper onClick={() => handleMenu(optionId)} key={optionId} selected={optionId === selectedId}>
                  <Tooltip title={ title }>
                    <ListItem open={open} selected={optionId === selectedId} onClick={route ? () =>{ 
                       onClick()
                       navigate(`${route}${query || ''}`)} : undefined}>
                      {optionId === selectedId ? <Icon color="#3949AB" /> : <Icon />}
                      {open && title} 
                    </ListItem>
                  </Tooltip>
                </ItemWrapper>
                <Collapse in={optionId === selectedId && open}>
                  {collapseOptions.map((option, index) => (
                  
                    <ItemWrapper key={index} selected={false}>
                      <Tooltip title={ handleTitleTooltip(option.title, option.route || "")}>
                        <CollapseItem onClick={ () => navigate(`${option.route}${option.query ? option.query : ''}`) } selected={ location.pathname === `${option.route}` }>
                          {option.title}
                        </CollapseItem>
                      </Tooltip>
                    </ItemWrapper>
                  ))}
                </Collapse>
              </>
            ))}
            <Tooltip title={'Outros Menus'}>
              <OtherMenus>
                <ServerIcon/>
                {open && 'Outros Menus'}
              </OtherMenus>
            </Tooltip>
          </ListDrawer>
        </Drawer> :
        <Drawer variant="temporary" open={openDrawer} onClose={onClick} anchor={ 'left' } className={clsx(classes.drawer, {
          [classes.drawerOpen] : open, [classes.drawerClose]: !open
        })} classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }} id={id}
        >
          <CloseIconContainer open={open}>
            {open ?
              <Tooltip title="Comprimir Menu">
                <div>
                  <IconButton onClick={handleDrawer}>
                    <DoubleCaretLeftIcon />
                  </IconButton>
                  <CloseText>Comprimir menu</CloseText>
                </div> 
              </Tooltip>
              :
              <Tooltip title="Expandir Menu">
                <div className="rotated">
                  <IconButton onClick={() => setOpen(!open)}>
                    <DoubleCaretLeftIcon />
                  </IconButton>
                </div>  
              </Tooltip>
            }
          </CloseIconContainer>
          <ListDrawer>
            {data?.map(({ id: optionId, title, collapseOptions, icon: Icon, route, query }) => (
              <>
                <ItemWrapper onClick={() => handleMenu(optionId)} key={optionId} selected={optionId === selectedId}>
                  <Tooltip title={ title }>
                    <ListItem open={open} selected={optionId === selectedId} onClick={route ? () => 
                      {
                        onClick() 
                        navigate(`${route}${query || ''}`)} : undefined}>
                      {optionId === selectedId ? <Icon color="#3949AB" /> : <Icon />}
                      {open && title}
                    </ListItem>
                  </Tooltip>
                </ItemWrapper>
                <Collapse in={optionId === selectedId && open}>
                  {collapseOptions.map((option, index) => (
                  
                    <ItemWrapper key={index} selected={false}>
                      <Tooltip title={ handleTitleTooltip(option.title, option.route || "")}>
                        <CollapseItem onClick={ () => navigate(`${option.route}${option.query ? option.query : ''}`) } selected={ location.pathname === `${option.route}` }>
                          {option.title}
                        </CollapseItem>
                      </Tooltip>
                    </ItemWrapper>
                  ))}
                </Collapse>
              </>
            ))}
            <Tooltip title={'Outros Menus'}>
              <OtherMenus>
                <ServerIcon/>
                {open && 'Outros Menus'}
              </OtherMenus>
            </Tooltip>
          </ListDrawer>
        </Drawer> 
      }   
    </>
  )
}

export default  withForm("Drawer", MenuDrawer)