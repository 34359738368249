import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import Alert from 'components/alert/Alert'
import CopyIcon from 'icons/copy'
import {alertActions} from 'store/alert/duck'
import Selector from 'store/alert/selector'

import { Button } from '@material-ui/core'

const AlertContainerComponent = () => {
  const isOpen = useSelector(Selector.isOpen)
  const { value,message,type } = useSelector(Selector.alert)
  const dispatch = useDispatch()
  const OnClose = () => {
    dispatch(alertActions.close())
  } 

  const onClick = useCallback(() => {
    navigator.clipboard.writeText(value)
  },[value]) 

  return (
    <Alert
      open={isOpen}
      action={
        value ? (
          <>
            {value}
            <Button onClick={onClick}>
              <CopyIcon />
            </Button>
          </>
        ) : 
          (
            <></>
          )
      }
      onClose={OnClose}
      severity={type}
      message={message}
    />
  )
}

export default AlertContainerComponent
