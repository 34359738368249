import { ReactNode } from 'react'

import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

export const Container = styled.footer<{ justifyContent: string }>`
  padding: 0px 48px 48px;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: 24px;

  @media (max-width: ${breakpoints.small}px) {
    padding: 0px 24px 24px;
    flex-direction: column-reverse;
    gap: 8px;
  }
`

type ModalFooterProps = {
  children: ReactNode
  align?: 'flex-start' | 'center' | 'flex-end'
}

export const ModalFooter = ({ children, align = 'flex-end' }: ModalFooterProps) => (
  <Container justifyContent={align}>{children}</Container>
)
