import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const isOpenState = (state: AppState) => state.alert.isOpen
const alertState = (state: AppState) => state.alert

const isOpen = createSelector(isOpenState, isOpen => isOpen)
const  alert = createSelector( alertState, alert => alert)

export default { alert, isOpen}