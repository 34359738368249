import { mapTipoExpediente, NUM_IDEA_ID } from 'constants/tiposExpediente'
import { createQueryString } from 'utils/helpers'

import { getBaseUrl } from '../get-base-url'

const BASE_URI = getBaseUrl('finalistico')

type Params = {
  nuIdea?: string
  page?: number
  pageSize?: number
}

export const endpoints = {
  create: `${BASE_URI}/AtividadeNaoProcedimental/`,
  update(id: string) {
    return `${BASE_URI}/AtividadeNaoProcedimental/${id}`
  },
  delete(id: string) {
    return `${BASE_URI}/AtividadeNaoProcedimental/${id}`
  },
  uploadAnexo: `${BASE_URI}/AnexoANProcedimental/upload`,
  deleteAnexo: `${BASE_URI}/AnexoANProcedimental/`,
  createDocumento: `${BASE_URI}/AnexoANProcedimental/`,
  getById(id: string) {
    return `${BASE_URI}/AtividadeNaoProcedimental/${id}`
  },
  getByParams(params?: Params) {
    const query = createQueryString({
      ...params,
      nuIdea: params?.nuIdea?.length === mapTipoExpediente[NUM_IDEA_ID].mask.length ? params.nuIdea : '',
    })
    return `${BASE_URI}/AtividadeNaoProcedimental${query}`
  },
  downloadAnexo(idAnexo: string) {
    return `${BASE_URI}/AnexoANProcedimental/${idAnexo}`
  },
  deleteAnexoTemporario(cdUsuario: number, nmAnexo: string) {
    return `${BASE_URI}/AnexoANProcedimental/${cdUsuario}/${nmAnexo}`
  },
}
