import { createSlice } from '@reduxjs/toolkit'
import { PessoaJuridica } from 'modules/pessoa-juridica/types'

type PessoaJuridicaStore = {
  form: {
    currentStep: number
    completedStep: Array<number>
    isSubmiting: boolean
    readOnlyCNPJ: boolean
    values: PessoaJuridica
    origin?: string
  }
  view?: PessoaJuridica
}

const initialState: PessoaJuridicaStore = {
  form: {
    currentStep: 0,
    completedStep: [],
    isSubmiting: false,
    readOnlyCNPJ: false,
    values: {
      nuCnpj: '',
      nmRazaoSocial: '',
      dtAbertura: undefined,
      nmFantasia: '',
      nuInscricaoMunicipal: '',
      nuInscricaoEstadual: '',
      enderecos: [],
      emails: [],
      telefones: [],
    },
  },
  view: undefined,
}

export const pessoaJuridicaSlice = createSlice({
  initialState,
  name: 'pessoaJuridica',
  reducers: {
    create: state => state,
    update: state => state,
    reset(state) {
      state.form = initialState.form
    },
    setIsSubmiting(state, action) {
      state.form.isSubmiting = action.payload
    },
    setOrigin(state, action) {
      state.form.origin = action.payload
    },
    setValues(state, action) {
      state.form.values = {
        ...state.form.values,
        ...action.payload,
      }
    },
    setView(state, action) {
      state.view = action.payload
    },
    resetView(state) {
      state.view = undefined
    },
    setCurrentStep(state, action) {
      state.form.currentStep = action.payload
    },
    setCompletedStep(state, action) {
      state.form.completedStep = action.payload
    },
    setReadOnlyCNPJ(state) {
      state.form.readOnlyCNPJ = true
    },
  },
})

export const pessoaJuridicaActions = pessoaJuridicaSlice.actions
export default pessoaJuridicaSlice.reducer
