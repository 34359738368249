import { useDispatch, useSelector } from 'react-redux'

import { modalsActions } from 'store/modals/duck'
import ModalSelector from 'store/modals/selector'

interface UseModalData {
  isOpen: boolean
  openModal: () => void
  closeModal: () => void
  toggleModal: () => void
  modals?: Record<string, boolean>
}

const useModal = (modalId: string): UseModalData => {
  const dispatch = useDispatch()
  const isOpen = useSelector(ModalSelector.isOpen(modalId))

  const openModal = () => {
    if (modalId) {
      dispatch(modalsActions.open({ key: modalId }))
    }
  }

  const closeModal = () => {
    dispatch(modalsActions.close({ key: modalId }))
  }

  const toggleModal = () => {
    dispatch(modalsActions.toggle({ key: modalId }))
  }

  return { isOpen, openModal, closeModal, toggleModal }
}

export default useModal
