import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type GrupoCentroCusto = {
  codigo: number
  nome: string
  descricao: string
  sigla: string
}

export type CentroCusto = {
  codigo: number
  nome: string
  nomeCompleto: string
  favorito?: boolean
  nuNivel: number
  cdPai: number
  stOrigemSics: boolean
  ativo: boolean
  dtInicio?: string
  dtFim?: string
  dsTpDesignacao?: string
  dsAtuacaoExternoSimples?: string
  descricaoTipoDesignacao?: string
  grupos?: GrupoCentroCusto[]
  selecionado: boolean
  stAtuacao?: boolean
  idComarca?: number
  stEleitoral?: boolean
  stDistribuicao?: boolean
}

export type OrgaoUnidadeState = {
  centroCusto: CentroCusto[]
  idSelecionado: number
}

export type Favorite = {
  idOrgaoUnidadeFavoritoLogin: number
  cdUsuario: number
  cdOrgaoUnidade: number
}

const initialState: OrgaoUnidadeState = { centroCusto: [], idSelecionado: 0 }

const orgaoUnidadeSlice = createSlice({
  name: 'orgaoUnidade',
  initialState,
  reducers: {
    set: (state: OrgaoUnidadeState, { payload }: PayloadAction<CentroCusto[]>) => ({ ...state, centroCusto: payload }),
    load: (state: OrgaoUnidadeState) => state,
    reset: () => initialState,
    favorite: (
      state: OrgaoUnidadeState,
      { payload: _payload }: PayloadAction<{ cdOrgaoUnidade: number; favoritar: boolean }>
    ) => state,

    // faz update do OrgaoUnidadeSelecionado no backend e depois 
    // atualiza o estado da aplicação (OrgaoUnidadeSelecionado e token de acesso do usuário)
    updateSelecionado: (state: OrgaoUnidadeState, { payload }: PayloadAction<number>) => ({...state, idSelecionado: payload}),

    // apenas autaliza o OrgaoUnidadeSelecionado no estado da aplicação. Utilizado por métodos que
    // antes buscam a informação atual no backend, como o load.
    setSelecionado: (state: OrgaoUnidadeState, { payload }: PayloadAction<number>) => ({...state, idSelecionado: payload}),
    },
  },
)

export const orgaoUnidadeActions = orgaoUnidadeSlice.actions

export default orgaoUnidadeSlice.reducer
