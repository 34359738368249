import { Option } from 'components/form/inputs'
import { formatISO } from 'date-fns'
import { PessoaFisica } from 'modules/pessoa-fisica/types'
import { toPattern } from 'vanilla-masker'

import { PessoaFisicaApiViewModel } from '../api/models/pessoa-fisica.models'

function getOptionValue(option: Option | string) {
  if (option && typeof option !== 'string') {
    return option.value || null
  }
  return null
}

export function mapPessoaFisicaToApi(pf: PessoaFisica) {
  return {
    idPessoaFisica: pf.idPessoaFisica,
    nmPessoaFisica: pf.nmPessoaFisica,
    dtNascimento: pf.dtNascimento && formatISO(pf.dtNascimento),
    nmSocial: pf.nmSocial,
    nmAlcunha: pf.nmAlcunha,
    nmMae: pf.nmMae,
    nmPai: pf.nmPai,
    dsOrgaoEmissorRG: pf.dsOrgaoEmissorRG,
    nuCpf: pf.nuCpf,
    nuRG: pf.nuRg,
    idTpSexo: pf.idTpSexo,
    idTpNacionalidade: pf.idTpNacionalidade,
    idTpGenero: getOptionValue(pf.genero),
    idTpEstCivil: getOptionValue(pf.estadoCivil),
    idTpGrauInstrucao: getOptionValue(pf.grauInstrucao),
    cdMunicipioNaturalidade: getOptionValue(pf.municipioNaturalidade),
    idUFExpedicaoRG: getOptionValue(pf.ufExpedicaoRG),
    dsPaisOrigem: pf.paisOrigem && typeof pf.paisOrigem !== 'string' ? pf.paisOrigem.value : null,
    documentos: pf.documentos.map(doc => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const documento: any = {
        idTpDocumento: doc.idTpDocumento,
        nuDocumento: doc.nuDocumento,
      }
      if (pf.idPessoaFisica) {
        documento.idPessoaFisica = pf.idPessoaFisica
      }
      if (doc.uf?.value) {
        documento.idUF = doc.uf.value
      }
      return documento
    }),
    enderecos: pf.enderecos.map(end => ({
      ...end,
      idUF: end.uf?.value,
      cdMunicipio: end.municipio?.value,
      cdBairro: end.bairro?.value ? end.bairro.value : null,
      cdDistritoEndereco: end.distrito?.value ? end.distrito.value : null,
      idPessoaFisica: pf.idPessoaFisica,
    })),
    emails: pf.emails?.map(email => ({
      ...email,
      idPessoaFisica: pf.idPessoaFisica,
    })),
    telefones: pf.telefones.map(tel => ({
      ...tel,
      idTpTelefone: tel.idTpTelefone || '1',
      nrDDD: tel.nuTelefone.substring(1, 3),
      cdPais: tel.stNacional ? '55' : tel.cdPais,
      idPessoaFisica: pf.idPessoaFisica,
    })),
  }
}

export function mapPessoaFisicaToStore(pf: PessoaFisicaApiViewModel) {
  const pessoaFisica: PessoaFisica = {
    nmPessoaFisica: pf.nmPessoaFisica,
    nmSocial: pf.nmSocial,
    nmMae: pf.nmMae,
    nmPai: pf.nmPai,
    nmAlcunha: pf.nmAlcunha,
    nuRg: pf.nuRg,
    dsOrgaoEmissorRG: pf.dsOrgaoEmissorRG,
    dtNascimento: pf.dtNascimento && new Date(pf.dtNascimento),
    nuCpf: pf.nuCpf ? toPattern(pf.nuCpf, '999.999.999-99') : '',
    idPessoaFisica: pf.idPessoaFisica ? String(pf.idPessoaFisica) : '',
    idTpSexo: pf.sexo?.idTpSexo ? String(pf.sexo.idTpSexo) : '',
    genero: {
      value: pf.genero?.idTpGenero ? String(pf.genero.idTpGenero) : '',
      label: pf.genero?.nmTpGenero || '',
    },
    estadoCivil: {
      value: pf.estadoCivil?.idEstCivil ? String(pf.estadoCivil.idEstCivil) : '',
      label: pf.estadoCivil?.dsEstCivil || '',
    },
    grauInstrucao: {
      value: pf.grauInstrucao?.idTpGrauInstrucao || '',
      label: pf.grauInstrucao?.nmTpGrauInstrucao || '',
    },
    idTpNacionalidade: pf.nacionalidade ? String(pf.nacionalidade.idTpNacionalidade) : '',
    paisOrigem: pf.dsPaisOrigem,
    ufNaturalidade: {
      value: pf.municipioNaturalidade?.uf?.idUF || '',
      label: pf.municipioNaturalidade?.uf?.sgUF || '',
    },
    municipioNaturalidade: {
      value: pf.municipioNaturalidade?.cdMunicipio ? String(pf.municipioNaturalidade.cdMunicipio) : '',
      label: pf.municipioNaturalidade?.nmMunicipio || '',
    },
    ufExpedicaoRG: {
      value: pf.ufExpedicaoRG?.idUF || '',
      label: pf.ufExpedicaoRG?.sgUF || '',
    },
    ultimaAlteracao: pf.dtAlteracao && new Date(pf.dtAlteracao),
    documentos: pf.documentos?.map(({ nuDocumento, tipoDocumento, uf }) => ({
      nuDocumento,
      idTpDocumento: tipoDocumento ? String(tipoDocumento.idTpDocumento) : '',
      uf: {
        value: uf?.idUF,
        label: uf?.sgUF,
      },
    })),
    enderecos: pf.enderecos?.map(end => ({
      nuCep: end.nuCep,
      dsLogradouro: end.dsLogradouro || '',
      nuLogradouro: end.nuLogradouro || '',
      stPrincipal: end.stPrincipal,
      dsComplementoLogradouro: end.dsComplementoLogradouro || '',
      dsPontoReferencia: end.dsPontoReferencia || '',
      uf: {
        value: end.uf?.idUF || '',
        label: end.uf?.sgUF || '',
      },
      municipio: {
        value: end.municipio?.cdMunicipio || '',
        label: end.municipio?.nmMunicipio || '',
      },
      bairro: {
        value: end.bairro?.cdBairro || '',
        label: end.bairro?.nmBairro || '',
      },
      distrito: {
        value: end.distrito?.cdDistrito || '',
        label: end.distrito?.nmDistrito || '',
      },
    })),
    emails: pf.emails,
    telefones: pf.telefones?.map(tel => ({
      ...tel,
      nuTelefone: tel.stNacional
        ? toPattern(
            `${tel.nrDDD}${tel.nuTelefone}`,
            tel.tipoTelefone?.nmTpTelefone === 'Fixo' ? '(99) 9999-9999' : '(99) 99999-9999'
          )
        : tel.nuTelefone,
      idTpTelefone: tel.tipoTelefone?.idTpTelefone ? String(tel.tipoTelefone.idTpTelefone) : '',
      cdPais: tel.cdPais ? String(tel.cdPais) : '',
    })),
  }

  return pessoaFisica
}

export const mapPessoaFisica = mapPessoaFisicaToStore
