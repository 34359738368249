import React, { ReactElement } from 'react'

import colors from 'styles/colors'

import { IconProps } from './types'

const CloseIcon = ({ size = 18, color = colors.grey }: IconProps): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L17 17M1 17L17 1" stroke={color} />
  </svg>
)

export default CloseIcon
