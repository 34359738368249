import { Button } from 'components/base/buttons'
import IconClose from 'icons/close'
import styled from 'styled-components'
import breakpoints from 'styles/breakpoints'

const Container = styled.header`
  position: relative;
  padding: 48px 48px 0px;

  > button {
    position: absolute;
    right: 12px;
    top: 12px;
  }

  @media (max-width: ${breakpoints.small}px) {
    padding: 44px 24px 0px;

    > button {
      right: 6px;
      top: 6px;
    }
  }
`

const ModalTitle = styled.h2`
  color: #828282;
  font-family: 'Poppins';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: center;

  @media (max-width: ${breakpoints.small}px) {
    font-size: 1.2rem;
  }
`

type ModalHeaderProps = {
  onClose?: VoidFunction
  children?: string
}

export const ModalHeader = (props: ModalHeaderProps) => (
  <Container>
    {props.onClose && (
      <Button.Icon title="Fechar" onClick={props.onClose}>
        <IconClose />
      </Button.Icon>
    )}
    {props.children && <ModalTitle id="modal-heading">{props.children}</ModalTitle>}
  </Container>
)
