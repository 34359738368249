import { HTMLAttributes } from 'react'

import SearchIcon from 'icons/searchIcon'
import styled from 'styled-components'

import Tooltip from '@material-ui/core/Tooltip'

const Button = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  border-radius: 14px;
  border: 1px solid rgba(15, 115, 178, 0.5);
  background: #fff;
  box-shadow: 2px 2px 8px 0px rgba(65, 17, 105, 0.16);
  height: 48px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`

type SearchButtonProps = {
  disabled?: boolean
} & HTMLAttributes<HTMLButtonElement>

export const SearchButton = ({ title = 'Pesquisar', ...rest }: SearchButtonProps) => (
  <Tooltip title={title} arrow>
    <Button {...rest}>
      <SearchIcon color="#0F73B2" />
    </Button>
  </Tooltip>
)
