import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/base/buttons'
import Modal from 'components/modal'
import message from 'constants/message'
import useModal from 'hooks/useModal'
import { navigationActions } from 'store/actions'
import { navigationSelectors } from 'store/selectors'

import { Typography } from '@material-ui/core'

const defaultModalId = 'modal-confirm-data-loss'

type ComponentProps = {
  modalId?: string
  text?: string
  onConfirm?: VoidFunction
  onCancel?: VoidFunction
}

export const ModalConfirmDataLoss = ({
  text = message.MSG0006,
  modalId = defaultModalId,
  onConfirm,
  onCancel,
}: ComponentProps) => {
  const modal = useModal(modalId)
  const dispatch = useDispatch()

  const confirmationMsg = useSelector(navigationSelectors.confirmationMsg)
  const handleConfirm = typeof onConfirm === 'function' ? onConfirm : () => dispatch(navigationActions.setConfirm())
  const handleCancel = typeof onCancel === 'function' ? onCancel : () => dispatch(navigationActions.setCancel())

  if (!modal.isOpen) {
    return null
  }

  return (
    <Modal modalId={modalId} size="sm" onClose={handleCancel}>
      <Modal.Header onClose={handleCancel}>Atenção!</Modal.Header>
      <Modal.Body>
        <Typography align="center">{confirmationMsg || text}</Typography>
      </Modal.Body>
      <Modal.Footer>
        <Button.Text onClick={handleCancel} color="warning">
          Voltar
        </Button.Text>
        <Button.Contained onClick={handleConfirm} color="warning">
          Confirmar
        </Button.Contained>
      </Modal.Footer>
    </Modal>
  )
}
