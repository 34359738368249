import React from 'react'

import { IconProps } from './types'

const HomeIcon = ({ color = '#F2F2F2' }: IconProps) => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.08734 0.5L8.41274 0.120372C8.22549 -0.0401239 7.94919 -0.0401239 7.76195 0.120372L8.08734 0.5ZM1.08734 6.5L0.761946 6.12037L0.587341 6.27003V6.5H1.08734ZM6.08734 14.5V15C6.36348 15 6.58734 14.7761 6.58734 14.5H6.08734ZM10.0873 14.5H9.58734C9.58734 14.7761 9.8112 15 10.0873 15V14.5ZM15.0873 6.5H15.5873V6.27003L15.4127 6.12037L15.0873 6.5ZM2.08734 15H6.08734V14H2.08734V15ZM15.4127 6.12037L8.41274 0.120372L7.76195 0.879628L14.7619 6.87963L15.4127 6.12037ZM7.76195 0.120372L0.761946 6.12037L1.41274 6.87963L8.41274 0.879628L7.76195 0.120372ZM6.58734 14.5V11.5H5.58734V14.5H6.58734ZM9.58734 11.5V14.5H10.5873V11.5H9.58734ZM10.0873 15H14.0873V14H10.0873V15ZM15.5873 13.5V6.5H14.5873V13.5H15.5873ZM0.587341 6.5V13.5H1.58734V6.5H0.587341ZM8.08734 10C8.91577 10 9.58734 10.6716 9.58734 11.5H10.5873C10.5873 10.1193 9.46805 9 8.08734 9V10ZM8.08734 9C6.70663 9 5.58734 10.1193 5.58734 11.5H6.58734C6.58734 10.6716 7.25891 10 8.08734 10V9ZM14.0873 15C14.9158 15 15.5873 14.3284 15.5873 13.5H14.5873C14.5873 13.7761 14.3635 14 14.0873 14V15ZM2.08734 14C1.8112 14 1.58734 13.7761 1.58734 13.5H0.587341C0.587341 14.3284 1.25891 15 2.08734 15V14Z"
      fill={color}
    />
  </svg>
)

export default HomeIcon
