import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ValidationMode } from 'react-hook-form/dist/types/form'

type FormProps = {
  id: string
  onSubmit: (_data: Record<string, unknown>) => void
  onError?: (_errors: Record<string, unknown>) => void
  validationMode?: keyof ValidationMode | undefined
  defaultValues?: { [x: string]: object | undefined }
  containerWidth?: string
}

const Form: React.FC<FormProps> = ({ onSubmit, onError, validationMode = 'onBlur', children, defaultValues, containerWidth }) => {
  const formMethods = useForm({
    mode: validationMode,
    defaultValues,
  })
  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onError)} style={{ width: containerWidth }}>
        {children}
      </form>
    </FormProvider>
  )
}

export default Form
