import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const state = (appState: AppState) => appState.navigation

const navigationConfirm = createSelector(state, navigation => navigation.confirm)
const navigationCancel = createSelector(state, navigation => navigation.cancel)
const navigationKey = createSelector(state, navigation => navigation.navigationKey)
const confirmationMsg = createSelector(state, navigation => navigation.confirmationMsg)
const searchUrl = createSelector(state, navigation => navigation.searchUrl)

export const navigationSelectors = {
  navigationConfirm,
  navigationCancel,
  confirmationMsg,
  navigationKey,
  searchUrl,
}
