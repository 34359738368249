import styled from 'styled-components'
import colors from 'styles/colors'
import fonts from 'styles/fonts/fonts'

import DrawerMUI from '@material-ui/core/Drawer'

export const Drawer = styled(DrawerMUI)`
  .MuiDrawer-paper {
    width: 326px;
    border-top-left-radius: 75px;
  }
`

export const DrawerTitle = styled.h1`
  font-family: ${fonts.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #80a3b4;
  margin: 32px 42px;
`

export const Version = styled.h1`
  font-family: ${fonts.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #80a3b4;
  margin: 0 12px;
`

export const UserInfoWrapper = styled.div`
  display: flex;
  gap: 16px;
  font-family: ${fonts.fontFamily};
  margin: 0 42px;
  margin-bottom: 8px;
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const UserNameTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #7a7a7b;
`
export const UserInfoText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #b5b6b7;
`

export const CurrentOrgaoUnidadeWrapper = styled.div`
  background: #f2f2f2;
`

export const CurrentOrgaoUnidadeItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  background: #f2f2f2;
  border: 1px solid #e0e0e0;
  border-left: 0;
  border-right: 0;
  margin-left: 41px;
  margin-right: 25px;
  padding: 14px 0;
`

export const CurrentOrgaoUnidadeItemText = styled.div`
  font-family: ${fonts.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #7a7a7b;
`

export const LogoffButton = styled.button`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border: none;
  background-color: ${colors.white};
  padding: 14px 0;
  font-family: ${fonts.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
  color: #5a6473;
  cursor: pointer;
  border-top: 1px solid ${colors.disabled};
  transition: background 0.3s ease;

  &:hover {
    background-color: ${colors.disabled};
  }
`
