import { SagaReturnType, call, put, select, takeLatest } from 'redux-saga/effects'

import { PayloadAction } from '@reduxjs/toolkit'
import message from 'constants/message'
import { DadosDoFato, Instrumento, ProcessoProcedimento } from 'modules/processo-procedimento/types'
import {
  createDadosDoFato,
  getDadosDoFatoByProcessoProcedimentoId,
  updateDadosDoFato,
} from 'services/dados-do-fato.services'
import { mapDadosDoFato } from 'services/mappers/dados-do-fato.mappers'
import { mapInstrumento, mapProcessoProcedimento } from 'services/mappers/processo-procedimento.mappers'
import {
  createInstrumento,
  createProcessoProcedimento,
  getInstrumentosByProcessoProcedimentoId,
  getProcessoProcedimentoById,
  updateInstrumento,
  updateProcessoProcedimento,
} from 'services/processo-procedimento.services'
import { alert } from 'store/alert/duck'
import history from 'store/history'

import { orgaoUnidadeSelectors } from '../orgaoUnidade/selector'
import { processoProcedimentoSelectors as selector } from './selector'
import { processoProcedimentoActions as action } from './slice'

type SaveDadosDoFatoResult = SagaReturnType<typeof createDadosDoFato>
type GetDadosDoFatoResult = SagaReturnType<typeof getDadosDoFatoByProcessoProcedimentoId>
type GetProcessoProcedimentoResult = SagaReturnType<typeof getProcessoProcedimentoById>
type CreateProcessoProcedimentoResult = SagaReturnType<typeof createProcessoProcedimento>
type UpdateProcessoProcedimentoResult = SagaReturnType<typeof updateProcessoProcedimento>
type GetInstrumentoResult = SagaReturnType<typeof getInstrumentosByProcessoProcedimentoId>
type SaveInstrumentoResult = SagaReturnType<typeof createInstrumento>

function* loadProcessoProcedimentoSaga({ payload }: PayloadAction<{ processoProcedimentoId: string }>) {
  const result: GetProcessoProcedimentoResult = yield call(getProcessoProcedimentoById, payload.processoProcedimentoId)

  if (!result.success) {
    yield put(alert.error(result.errorMessage))
  } else {
    yield put(action.setValues(mapProcessoProcedimento(result.data)))
  }
}

function* createProcessoProcedimentoSaga() {
  let payload: ProcessoProcedimento = yield select(selector.values)

  if (!payload.envolvidos || !payload.envolvidos.length) {
    yield put(alert.error(message.MSGE114))
    return
  }

  if (!payload.orgaoUnidade) {
    const idOrgaoUnidadeSelecionado: number = yield select(orgaoUnidadeSelectors.idSelecionado)

    payload = {
      ...payload,
      orgaoUnidade: { label: '', value: idOrgaoUnidadeSelecionado },
    }
  }

  const result: CreateProcessoProcedimentoResult = yield call(createProcessoProcedimento, payload)

  if (!result.success) {
    yield put(alert.error(result.errorMessage))
  } else {
    yield put(alert.success({ message: message.MSGE115, value: result.data?.nuIdea }))
    yield put(action.reset())

    history.push(`/processo-procedimento/detalhar?identificador=${result.data.idProcProcedimento}`)
  }
}

function* updateProcessoProcedimentoSaga() {
  const payload: ProcessoProcedimento = yield select(selector.values)

  const result: UpdateProcessoProcedimentoResult = yield call(updateProcessoProcedimento, payload)

  if (!result.success) {
    yield put(alert.error(result.errorMessage))
  } else {
    yield put(alert.success(message.MSG0013))
    history.push('/')
  }
}

function* saveInstrumentoSaga({ payload }: PayloadAction<Instrumento>) {
  const processoProcedimento: ProcessoProcedimento = yield select(selector.values)
  const processoProcedimentoId = String(processoProcedimento.idProcProcedimento)

  const service = payload.idProcProcedimentoInstrumento ? updateInstrumento : createInstrumento
  const result: SaveInstrumentoResult = yield call(service, { processoProcedimentoId, instrumento: payload })

  if (!result.success) {
    yield put(alert.error(result.errorMessage))
  } else {
    yield put(action.loadInstrumentos({ processoProcedimentoId }))
    yield put(alert.success(payload.idProcProcedimentoInstrumento ? message.MSG0013 : message.MSG0001))
  }
}

function* loadInstrumentoSaga({ payload }: PayloadAction<{ processoProcedimentoId: string }>) {
  const result: GetInstrumentoResult = yield call(
    getInstrumentosByProcessoProcedimentoId,
    payload.processoProcedimentoId
  )

  if (!result.success) {
    yield put(alert.error(result.errorMessage))
  } else {
    yield put(action.setInstrumentos(result.data.map(mapInstrumento)))
  }
}

function* saveDadosDoFatoSaga({ payload }: PayloadAction<DadosDoFato>) {
  const processoProcedimento: ProcessoProcedimento = yield select(selector.values)
  const processoProcedimentoId = String(processoProcedimento.idProcProcedimento)

  const service = payload.idDadosDoFato ? updateDadosDoFato : createDadosDoFato
  const response: SaveDadosDoFatoResult = yield call(service, { processoProcedimentoId, payload })

  if (!response.success) {
    yield put(alert.error(response.errorMessage))
    return
  }

  const getDadosDoFatoResult: GetDadosDoFatoResult = yield call(
    getDadosDoFatoByProcessoProcedimentoId,
    processoProcedimentoId
  )

  if (getDadosDoFatoResult.success) {
    yield put(action.setDadosDoFato(mapDadosDoFato(getDadosDoFatoResult.data[0])))
  }

  yield put(alert.success(payload.idDadosDoFato ? message.MSG0013 : message.MSG0001))
}

export default function* saga() {
  yield takeLatest(action.loadProcessoProcedimento.type, loadProcessoProcedimentoSaga)
  yield takeLatest(action.create.type, createProcessoProcedimentoSaga)
  yield takeLatest(action.update.type, updateProcessoProcedimentoSaga)
  yield takeLatest(action.saveDadosDoFato.type, saveDadosDoFatoSaga)
  yield takeLatest(action.saveInstrumento.type, saveInstrumentoSaga)
  yield takeLatest(action.loadInstrumentos.type, loadInstrumentoSaga)
}
