import { SagaReturnType, call, put, select, takeLatest } from 'redux-saga/effects'

import message from 'constants/message'
import { PessoaJuridica } from 'modules/pessoa-juridica/types'
import { createPessoaJuridica, updatePessoaJuridica } from 'services/pessoa-juridica.services'
import { alert } from 'store/alert/duck'
import history from 'store/history'

import { pessoaJuridicaSelectors } from './selector'
import { pessoaJuridicaActions } from './slice'

type CreateOrUpdatePessoaFisicaResult = SagaReturnType<typeof createPessoaJuridica>

function* handleCreatePessoaJuridica() {
  try {
    yield put(pessoaJuridicaActions.setIsSubmiting(true))
    const origin: string = yield select(pessoaJuridicaSelectors.origin)

    const payload: PessoaJuridica = yield select(pessoaJuridicaSelectors.values)
    const result: CreateOrUpdatePessoaFisicaResult = yield call(createPessoaJuridica, payload)

    if (!result.success) {
      throw new Error(result.errorMessage)
    }

    yield put(alert.success(message.MSG0001))
    yield put(pessoaJuridicaActions.reset())

    if (origin) {
      history.push(origin, { selectedId: result.data?.idPessoaJuridica })
    } else {
      history.push('/')
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(alert.error(error.message))
    }
  } finally {
    yield put(pessoaJuridicaActions.setIsSubmiting(false))
  }
}

function* handleUpdatePessoaJuridica() {
  try {
    yield put(pessoaJuridicaActions.setIsSubmiting(true))
    const origin: string = yield select(pessoaJuridicaSelectors.origin)

    const payload: PessoaJuridica = yield select(pessoaJuridicaSelectors.values)
    const service = payload.orgExterna ? createPessoaJuridica : updatePessoaJuridica
    const result: CreateOrUpdatePessoaFisicaResult = yield call(service, payload)

    if (!result.success) {
      throw new Error(result.errorMessage)
    }

    yield put(alert.success('Cadastro atualizado com sucesso'))
    yield put(pessoaJuridicaActions.reset())

    if (origin) {
      history.push(origin, { selectedId: payload.idPessoaJuridica })
    } else {
      history.push('/')
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(alert.error(error.message))
    }
  } finally {
    yield put(pessoaJuridicaActions.setIsSubmiting(false))
  }
}

export default function* saga() {
  yield takeLatest(pessoaJuridicaActions.create.type, handleCreatePessoaJuridica)
  yield takeLatest(pessoaJuridicaActions.update.type, handleUpdatePessoaJuridica)
}
