import { endpoints } from './api/endpoints/equipe-distribuicao.endpoints'
import { httpDelete, httpGet, httpPost, httpPut } from './api/http-methods'
import { EquipeDistribuicaoViewModel } from './api/models/equipe-distribuicao.model'
import { requestHandler } from './api/request-handler'

export type CreateEquipeDistribuicao = {
  dsEquipeDist: string
  idOrgaoUnidade: number
  stAtivo: boolean
  idTpEquipeDistribuicao: number
  stEquipeAutomatica?: boolean
}

export type UpdateEquipeDistribuicao = CreateEquipeDistribuicao & {
  idEquipeDist: number
}

export const getEquipesDistribuicao = requestHandler<EquipeDistribuicaoViewModel[]>(() => httpGet(endpoints.getAll))

export const getEquipeDistribuicaoById = requestHandler<EquipeDistribuicaoViewModel, number>(id =>
  httpGet(endpoints.getById(id))
)

export const getEquipeDistribuicaoByOrgaoUnidadeId = requestHandler<EquipeDistribuicaoViewModel[], number>(
  orgaoUnidadeId => httpGet(endpoints.getByOrgaoUnidadeId(orgaoUnidadeId))
)

export const createEquipeDistribuicao = requestHandler<EquipeDistribuicaoViewModel, CreateEquipeDistribuicao>(payload =>
  httpPost(endpoints.create, payload)
)

export const updateEquipeDistribuicao = requestHandler<EquipeDistribuicaoViewModel, UpdateEquipeDistribuicao>(
  payload => {
    const { idEquipeDist: _, ...updateParameter } = payload
    return httpPut(endpoints.update(payload.idEquipeDist), updateParameter)
  }
)

export const deleteEquipeDistribuicao = requestHandler<void, number>(id => httpDelete(endpoints.delete(id)))
