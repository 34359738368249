import { SagaReturnType, call, put, select, takeLatest } from 'redux-saga/effects'

import { PayloadAction } from '@reduxjs/toolkit'
import { getOrgaoUnidadeAtuacaoByIds } from 'services/global.services'
import { favoritarOrgaoUnidade, getOrgaoUnidadeByLogin, getOrgaoUnidadeSelecionado, selecionarOrgaoUnidade } from 'services/login.services'
import { errorActions } from 'store/errors/duck'
import { userActions } from 'store/user/duck'
import UserSelector from 'store/user/selector'

import { orgaoUnidadeActions } from './duck'

type GetOrgaoUnidadeAtuacaoResult = SagaReturnType<typeof getOrgaoUnidadeAtuacaoByIds>
type GetOrgaoUnidadeByLoginResult = SagaReturnType<typeof getOrgaoUnidadeByLogin>
type SelecionarResult = SagaReturnType<typeof selecionarOrgaoUnidade>
type GetSelecionadoResult = SagaReturnType<typeof getOrgaoUnidadeSelecionado>

function* favorite({ payload }: PayloadAction<{ cdOrgaoUnidade: number; favoritar: boolean }>) {
  try {
    const cdUsuario: number = yield select(UserSelector.id)
    yield call(favoritarOrgaoUnidade, {
      cdUsuario,
      cdOrgaoUnidade: payload.cdOrgaoUnidade,
      favoritar: payload.favoritar,
    })
  } catch (error) {
    yield put(
      errorActions.add({
        key: orgaoUnidadeActions.favorite.type,
        errors: ['Não foi possível favoritar o Órgão/Unidade.'],
      })
    )
  }
}

function* updateSelecionado({ payload }: PayloadAction<number>) {
  try{
    const response : SelecionarResult = yield call(selecionarOrgaoUnidade, {
      idOrgaoUnidadeSelecionado: payload
    })
    if(!response.success){
      throw new Error(response.errorMessage)
    }
    yield put(userActions.setToken(response.data.dsToken))
  }
  catch(error){
    yield put(
      errorActions.add({
        key: orgaoUnidadeActions.updateSelecionado.type,
        errors: ['Não foi possível selecionar o Órgão/Unidade.'],
    }))
  }
}

function* load() {
  try {
    const codigo: number = yield select(UserSelector.id)
    const login: string = yield select(UserSelector.username)

    const response: GetOrgaoUnidadeByLoginResult = yield call(getOrgaoUnidadeByLogin, { codigo, login })
    if (!response.success) {
      throw new Error(response.errorMessage)
    }
    const responseOuSelecionado: GetSelecionadoResult = yield call(getOrgaoUnidadeSelecionado)

    if (!responseOuSelecionado.success) {
      throw new Error(responseOuSelecionado.errorMessage)
    }
    const codigosCentroCusto = response.data.map(centroCusto => centroCusto.codigo)
    const ouSelecionado = responseOuSelecionado.data.idOrgaoUnidadeSelecionado

    const centroCustoResult: GetOrgaoUnidadeAtuacaoResult = yield call(getOrgaoUnidadeAtuacaoByIds, codigosCentroCusto)

    if (!centroCustoResult.success) {
      throw new Error(centroCustoResult.errorMessage)
    }

    const centroCustoList = centroCustoResult.data

    yield put(
      orgaoUnidadeActions.set(
        response.data.map(cc => {
          const centroCursoInfo = centroCustoList.find(item => item.idOrgaoUnidade === cc.codigo)

          return {
            ...cc,
            stAtuacao: centroCursoInfo?.stAtuacao,
            stEleitoral: centroCursoInfo?.stEleitoral,
            stDistribuicao: centroCursoInfo?.stDistribuicao,
            idComarca: centroCursoInfo?.idComarca,
          }
        })
      )
    )
    yield put(orgaoUnidadeActions.setSelecionado(ouSelecionado))
    
  } catch (error) {
    yield put(
      errorActions.add({
        key: orgaoUnidadeActions.favorite.type,
        errors: ['Não foi possível favoritar o Órgão/Unidade.'],
      })
    )
  }
}

export default function* orgaosUnidadeSaga(): Generator {
  yield takeLatest(orgaoUnidadeActions.favorite.type, favorite)
  yield takeLatest(orgaoUnidadeActions.load.type, load)
  yield takeLatest(orgaoUnidadeActions.updateSelecionado.type, updateSelecionado)
}
