import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type valoresType = {
  idConfiguracaoValor?: number
  idConfiguracao?: number
  dsValorConfiguracaoValor: string
}

export type Setting = {
  dsConfiguracao?: string
  idConfiguracao?: number
  sgChave: string
  stAtivo: boolean
  valores: valoresType[]
}

const initialState: Setting[] = []

const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    set: (state: Setting[], { payload }: PayloadAction<Setting[]>) => payload,
    load: (state: Setting[]) => state,
    save: (state: Setting[], { payload: _payload }: PayloadAction<Setting>) => state,
    update: (state: Setting[], { payload: _payload }: PayloadAction<Setting>) => state,
    remove: (state: Setting[], { payload: _payload }: PayloadAction<number>) => state,
    reset: () => initialState,
  },
})

export const settingActions = settingSlice.actions

export default settingSlice.reducer
