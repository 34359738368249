import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl('login')

export default {
  login: `${BASE_URL}/login/autenticar`,
  getPermissionsByGroup: `${BASE_URL}/Permissao/ObterPermissoesPorGrupos`,
  favoritarOrgaoUnidade: `${BASE_URL}/OrgaoUnidade/Favoritar`,
  selecionarOrgaoUnidade: `${BASE_URL}/OrgaoUnidade/SelecionarOU`,
  getOrgaoUnidadeSelecionado: `${BASE_URL}/OrgaoUnidade/ObterSelecionado`,
  getOrgaoUnidade: `${BASE_URL}/OrgaoUnidade/ObterCentrosCustoEDesignacoesPorLogin`,
  getDesignacoesPorFiltro: `${BASE_URL}/OrgaoUnidade/ObterDesignacoesPorFiltro`,
}
