import { endpoints } from './api/endpoints/global.endpoints'
import { httpGet } from './api/http-methods'
import { CEPApiViewModel, CidadeApiViewModel } from './api/models/global.models'
import { requestHandler } from './api/request-handler'

type OrgaoUnidade = {
  idOrgaoUnidade: number
  dsOrgaoUnidade: string
  idComarca: number
  stAtuacao: boolean
  stEleitoral: boolean
  stDistribuicao: boolean
}

export const getOrgaoUnidadeAtuacaoByIds = requestHandler<OrgaoUnidade[], number[]>(ids => {
  const query = ids.reduce((ous, ou, index) => `${ous}ous=${ou}${index < ids.length - 1 ? '&' : ''}`, '')
  return httpGet(endpoints.getOrgaoUnidadeAtuacaoByIds(query))
})

export const getEnderecoByCep = requestHandler<CEPApiViewModel, string>(cep => httpGet(endpoints.getEnderecoByCep(cep)))

export const getUFCidadePorIdOrgaoUnidade = requestHandler<CidadeApiViewModel, number>(idOrgaoUnidade =>
  httpGet(endpoints.getUFCidadePorIdOrgaoUnidade(idOrgaoUnidade))
)


