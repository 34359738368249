import { all } from 'redux-saga/effects'

import atividadeNaoProcedimentalSaga from './atividade-nao-procedimental/saga'
import equipeDistribuicaoSaga from './equipe-distribuicao/saga'
import formsStateSaga from './navigation/saga'
import orgaoUnidadeSaga from './orgaoUnidade/saga'
import permissionSaga from './permissions/saga'
import pessoaFisicaSaga from './pessoa-fisica/saga'
import pessoaJuridicaSaga from './pessoa-juridica/saga'
import processoProcedimentoSaga from './processo-procedimento/saga'
import settingsSaga from './settings/saga'
import userSaga from './user/saga'

export default function* rootSaga(): Generator {
  yield all([
    userSaga(),
    settingsSaga(),
    orgaoUnidadeSaga(),
    permissionSaga(),
    processoProcedimentoSaga(),
    pessoaFisicaSaga(),
    pessoaJuridicaSaga(),
    atividadeNaoProcedimentalSaga(),
    formsStateSaga(),
    equipeDistribuicaoSaga()
  ])
}
