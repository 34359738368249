import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Option } from 'components/form/inputs'
import { EquipeDistribuicaoViewModel } from 'services/api/models/equipe-distribuicao.model'

export type EquipeDistribuicaoForm = {
  dsEquipeDist: string,
  idOrgaoUnidade?: string | Option,
  stAtivo?: string,
  idTpEquipeDistribuicao?: string,
  stEquipeAutomatica?: string,
}

export type EquipeDistribuicaoStore = {
  isLoading: boolean,
  list: EquipeDistribuicaoViewModel[],
  current?: EquipeDistribuicaoForm
}

const initialState: EquipeDistribuicaoStore = {
  isLoading: false,
  list: [],
  current: {
    dsEquipeDist: '',
    idOrgaoUnidade: '',
    idTpEquipeDistribuicao: '',
    stAtivo: '',
    stEquipeAutomatica: ''
  }
}

const equipeDistribuicaoSlice = createSlice({
  name: 'equipe-distribuicao',
  initialState,
  reducers: {
    set: (state: EquipeDistribuicaoStore, { payload }: PayloadAction<EquipeDistribuicaoViewModel[]>) => ({ ...state, list: payload }),
    setLoading: (state: EquipeDistribuicaoStore, { payload }: PayloadAction<boolean>) => ({ ...state, isLoading: payload }),
    setFormValues: (state: EquipeDistribuicaoStore, { payload }: PayloadAction<EquipeDistribuicaoForm>) => ({ ...state, current: payload }),
    load: (state: EquipeDistribuicaoStore) => state,
    create: (state: EquipeDistribuicaoStore) => state,
    update: (state: EquipeDistribuicaoStore, { payload: _payload }: PayloadAction<number>) => state,
    remove: (state: EquipeDistribuicaoStore, { payload: _payload }: PayloadAction<number>) => state,
    reset: () => initialState,
  },
})

export const equipeDistribuicaoActions = equipeDistribuicaoSlice.actions

export default equipeDistribuicaoSlice.reducer
