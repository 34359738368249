import { combineReducers } from '@reduxjs/toolkit'
import alert from 'store/alert/duck'
import atividadeNProcedimental from 'store/atividade-nao-procedimental/slice'
import drawer from 'store/drawer/duck'
import equipeDistribuicao from 'store/equipe-distribuicao/slice'
import errors from 'store/errors/duck'
import loader from 'store/loader/duck'
import modals from 'store/modals/duck'
import navigation from 'store/navigation/slice'
import orgaoUnidade from 'store/orgaoUnidade/duck'
import permissions from 'store/permissions/duck'
import pessoaFisica from 'store/pessoa-fisica/slice'
import pessoaJuridica from 'store/pessoa-juridica/slice'
import processoProcedimento from 'store/processo-procedimento/slice'
import settings from 'store/settings/duck'
import user from 'store/user/duck'

const rootReducer = combineReducers({
  drawer,
  errors,
  loader,
  modals,
  user,
  permissions,
  orgaoUnidade,
  alert,
  settings,
  processoProcedimento,
  pessoaFisica,
  pessoaJuridica,
  atividadeNProcedimental,
  navigation,
  equipeDistribuicao
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
