import { useSelector } from 'react-redux'

import PerfilAvatar from 'components/avatar/Avatar'
import IconButton from 'components/iconButton/IconButton'
import useDrawer from 'hooks/useDrawer'
import { useHandleNavigate } from 'hooks/useHandleNavigate'
import useModal from 'hooks/useModal'
import ChangeIcon from 'icons/change'
import LogoffIcon from 'icons/logoff'
import LogoffConfirmationModal from 'modules/home/modals/LogoffConfirmationModal'
import moment from 'moment'
import OrgaoUnidadeSelector from 'store/orgaoUnidade/selector'
import UserSelector from 'store/user/selector'
import colors from 'styles/colors'
import { DefaultProps } from 'styles/types'

import { Tooltip } from '@material-ui/core'

import {
  CurrentOrgaoUnidadeItem,
  CurrentOrgaoUnidadeItemText,
  CurrentOrgaoUnidadeWrapper,
  Drawer,
  DrawerTitle,
  LogoffButton,
  UserInfo,
  UserInfoText,
  UserInfoWrapper,
  UserNameTitle,
  Version
} from './styles'

const UserDrawer = ({ id }: DefaultProps) => {
  const { closeDrawer, isOpen } = useDrawer(id)
  const { openModal: openModalLogoff } = useModal('modal-logoff')
  const userName = useSelector(UserSelector.name)
  const matricula = useSelector(UserSelector.matricula)
  const orgaosUnidadeSelecionado = useSelector(OrgaoUnidadeSelector.orgaosUnidadeSelecionado)
  const orgaosUnidade = useSelector(OrgaoUnidadeSelector.orgaosUnidade)
  const navigate = useHandleNavigate()

  const handleLogoff = () => {
    closeDrawer()
    openModalLogoff()
  }

  const getOrgaoUnidadeInfoText = () => {
    const data = orgaosUnidadeSelecionado || orgaosUnidade[0] || {}

    const info = {
      nome: data.nome || '',
      designacao: data.descricaoTipoDesignacao ? `- ${data.descricaoTipoDesignacao}` : '',
      dtInicio: data.dtInicio ? moment(data.dtInicio).format('- DD/MM/YYYY') : '',
      dtFim: data.dtFim ? moment(data.dtFim).format('- DD/MM/YYYY') : ''
    }

    return `${info.nome} ${info.designacao} ${info.dtInicio} ${info.dtFim}`
  }

  return (
    <>
      <Drawer style={{ width: '326px' }} id={id} anchor="right" open={isOpen} onClose={closeDrawer}>
        <DrawerTitle>Perfil do Usuário</DrawerTitle>
        <UserInfoWrapper>
          <PerfilAvatar
            id="perfil-avatar"
            userName={userName}
            variant="rounded"
            style={{ width: '72px', height: '72px' }}
          />
          <UserInfo>
            <div>
              <UserNameTitle>{userName}</UserNameTitle>
              <UserInfoText>{matricula}</UserInfoText>
            </div>
            <UserInfoText></UserInfoText>
          </UserInfo>
        </UserInfoWrapper>
        <CurrentOrgaoUnidadeWrapper>
          <CurrentOrgaoUnidadeItem>
            <IconButton
              id="perfil-trocar-orgao-unidade"
              tooltipTitle="Trocar órgão/unidade"
              style={{ background: colors.white, borderRadius: '8px' }}
              onClick={() => {
                closeDrawer()
                navigate('/orgao-unidade/trocar')
              }}
            >
              <ChangeIcon />
            </IconButton>
            <CurrentOrgaoUnidadeItemText>
              {getOrgaoUnidadeInfoText()}
            </CurrentOrgaoUnidadeItemText>
          </CurrentOrgaoUnidadeItem>
        </CurrentOrgaoUnidadeWrapper>
        {/* <Version>v{packageJson.version} dev</Version> */}
        <Version></Version>
        <Tooltip title="Sair" arrow>
          <LogoffButton onClick={handleLogoff}>
            <LogoffIcon />
            Sair
          </LogoffButton>
        </Tooltip>
      </Drawer>
      <LogoffConfirmationModal id={'modal-logoff'} />
    </>
  )
}

export default UserDrawer
