import React, { ReactElement, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import { ModalTitle } from 'components/modal/styles'
import useModal from 'hooks/useModal'
import { navigationActions } from 'store/actions'
import { errorActions } from 'store/errors/duck'
import { orgaoUnidadeActions } from 'store/orgaoUnidade/duck'
import { permissionActions } from 'store/permissions/duck'
import { userActions } from 'store/user/duck'
import colors from 'styles/colors'

import { Link, Tooltip } from '@material-ui/core'

import { LogoffContainer, LogoffText } from './styles'

type LogoffConfirmationModalProps = { id: string }

const LogoffConfirmationModal = ({ id }: LogoffConfirmationModalProps): ReactElement => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { closeModal } = useModal(id)

  const handleLogoff = useCallback(() => {
    dispatch(orgaoUnidadeActions.updateSelecionado(0))
    dispatch(navigationActions.resetFormState('ALL'))
    dispatch(permissionActions.reset())
    dispatch(userActions.logoff())
    dispatch(errorActions.reset())
    closeModal()
    navigate('/')
  }, [closeModal, dispatch, navigate])

  return (
    <Modal id={id}>
      <LogoffContainer>
        <ModalTitle color="yellow">Atenção!</ModalTitle>
        <LogoffText>
          <b>Ao confirmar a sua saída, todas as alterações não salvas serão perdidas.</b>
        </LogoffText>
        <Button
          id={''}
          variant="contained"
          bgColor="yellow"
          onClick={handleLogoff}
          style={{ marginBottom: '10px', textTransform: 'none' }}
          tooltipTitle="Confirmar saída"
        >
          Confirmar saída
        </Button>
        <Tooltip title="Cancelar" arrow>
          <Link onClick={closeModal} style={{ color: colors.yellow, cursor: 'pointer' }}>
            Cancelar e voltar para a tela anterior.
          </Link>
        </Tooltip>
      </LogoffContainer>
    </Modal>
  )
}

export default LogoffConfirmationModal
