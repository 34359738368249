import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const equipeDistribuicaoState = (state: AppState) => state.equipeDistribuicao

const all = createSelector(equipeDistribuicaoState, equipesDistribuicao => equipesDistribuicao.list)

const currentForm = createSelector(equipeDistribuicaoState, equipesDistribuicao => equipesDistribuicao.current)

const isLoading = createSelector(equipeDistribuicaoState, equipesDistribuicao => equipesDistribuicao.isLoading)

export const equipeDistribuicaoSelectors = { all, currentForm, isLoading }
