import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CentroCusto } from 'store/orgaoUnidade/duck'

export interface UserState {
  codigo: number
  login: string
  dsToken: string
  nome: string
  matricula: string
  centrosCusto: CentroCusto[]
  rememberLogin: boolean
  orgaoUnidadeOrigem?: number
  idOrgaoUnidadeFavorito?: number
  listaGruposOUFavorito?: number[]
  loading: boolean
  stMembro: boolean
}

export const initialState: UserState = {
  codigo: 0,
  login: '',
  rememberLogin: false,
  dsToken: '',
  nome: '',
  matricula: '',
  centrosCusto: [],
  loading: false,
  stMembro: false
}

export type LoginPayload = PayloadAction<{
  login: string
  senha: string
  rememberLogin: boolean
  onSuccess?: () => void
  onError?: () => void
}>

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set: (state: UserState, { payload }: PayloadAction<UserState>) => ({ ...state, ...payload }),
    login: (state: UserState, { payload: _payload }: LoginPayload) => state,
    logoff(state: UserState) {
      if (!state.rememberLogin) {
        return initialState
      }
      return { ...initialState, login: state.login, rememberLogin: state.rememberLogin }
    },
    reset: () => initialState,
    setLoading: (state: UserState, { payload }: PayloadAction<boolean>) => ({ ...state, loading: payload }),
    setToken: (state: UserState, { payload }: PayloadAction<string>) => ({ ...state, dsToken: payload })
  },
})

export const userActions = userSlice.actions

export default userSlice.reducer
