import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const orgaoUnidadeState = (state: AppState) => state.orgaoUnidade.centroCusto

const orgaosUnidadeFiltrado = (state: AppState) => {
  if (state.orgaoUnidade.idSelecionado) {
    return state.orgaoUnidade.centroCusto.find(x => x.codigo === state.orgaoUnidade.idSelecionado)
  } else if (state.orgaoUnidade.centroCusto.find(x => x.favorito)) {
    return state.orgaoUnidade.centroCusto.find(x => x.favorito)
  } else {
    return state.orgaoUnidade.centroCusto[0]
  }
}

const orgaosUnidade = createSelector(orgaoUnidadeState, orgaosUnidade => orgaosUnidade)
const idSelecionado = createSelector(orgaosUnidadeFiltrado, orgaoUnidade => orgaoUnidade?.codigo)
const orgaosUnidadeSelecionado = createSelector(orgaosUnidadeFiltrado, orgaosUnidade => orgaosUnidade)
const orgaosUnidadesAtuacao = createSelector(orgaoUnidadeState, orgaosUnidades =>
  orgaosUnidades.filter(ou => ou.stAtuacao)
)

export const orgaoUnidadeSelectors = { orgaosUnidade, idSelecionado, orgaosUnidadeSelecionado, orgaosUnidadesAtuacao }
export default orgaoUnidadeSelectors
