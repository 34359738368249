import styled from 'styled-components'
import fonts from 'styles/fonts/fonts'

export const LogoffContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  margin: 0 auto;
  padding: 4.5rem 0 2rem;
`

export const LogoffText = styled.p`
  font-family: ${fonts.fontFamilyRaleway};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 143%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: #5f5f5f;
  margin: 48px 0;
`
