import React from 'react'

import Authenticated from 'layout/Authenticated/Autehticated'
import { useRoutes } from 'routes/authenticate.routes'

import RouterSwitch from './RouterSwitch/RouterSwitch'

const AuthenticateScreenRouter = () => {
  const { routes } = useRoutes()
  return (
    <Authenticated>
      <RouterSwitch routes={routes} />
    </Authenticated>
  )
}

export default AuthenticateScreenRouter
