import { ButtonHTMLAttributes, useMemo } from 'react'

import useWindowDimensions from 'hooks/useWindowDimensions'
import styled, { css } from 'styled-components'
import breakpoints from 'styles/breakpoints'

const colors = {
  success: '#27AE60',
  default: '#0F73B2',
}

type ActionButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: keyof typeof colors
  primary?: boolean
  mobileText?: string
}

const ActionButtonRoot = styled.button.attrs(props => ({
  type: props.type ?? 'button',
}))<ActionButtonProps>`
  width: 160px;
  text-align: center;
  padding: 6px 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 0.85rem;
  border: 1px solid transparent;
  box-shadow: 1px 1px 2px rgba(8, 12, 31, 0.08);
  border-radius: 14px;
  border-color: ${props => colors[props.color || 'default']};
  color: ${props => (props.primary ? '#fff' : colors[props.color || 'default'])};
  background: ${props => (props.primary ? colors[props.color || 'default'] : 'transparent')};
  transition: filter 0.2s, background-color 0.2s;

  &:hover {
    cursor: pointer;

    ${props =>
      props.primary
        ? css`
            filter: brightness(0.95);
          `
        : css`
            background-color: rgba(0, 0, 0, 0.04);
          `}
  }

  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: ${breakpoints.small}px) {
    width: 80px;
  }
`

export const ActionButton = ({ mobileText, children, ...props }: ActionButtonProps) => {
  const { width } = useWindowDimensions()
  const isMobile = useMemo(() => width <= breakpoints.small, [width])

  return <ActionButtonRoot {...props}>{isMobile && mobileText ? mobileText : children}</ActionButtonRoot>
}
