import { DadosDoFato } from 'modules/processo-procedimento/types'

import { endpoints } from './api/endpoints/dados-do-fato.endpoints'
import { httpDelete, httpGet, httpPost, httpPut } from './api/http-methods'
import { AnexoViewModel, DadosDoFatoViewModel } from './api/models/processo-procedimento.models'
import { requestHandler } from './api/request-handler'
import { mapDadosDoFatoToApi } from './mappers/dados-do-fato.mappers'

type CreateOrUpdateDadosDoFatoParams = {
  processoProcedimentoId: string
  payload: DadosDoFato
}

export const getDadosDoFatoByProcessoProcedimentoId = requestHandler<DadosDoFatoViewModel[], string>(
  processoProcedimentoId => httpGet(endpoints.getByProcessoProcedimentoId(processoProcedimentoId))
)

export const createDadosDoFato = requestHandler<DadosDoFatoViewModel, CreateOrUpdateDadosDoFatoParams>(params =>
  httpPost(endpoints.create, mapDadosDoFatoToApi(params.processoProcedimentoId, params.payload))
)

export const updateDadosDoFato = requestHandler<DadosDoFatoViewModel, CreateOrUpdateDadosDoFatoParams>(params =>
  httpPut(
    endpoints.update(String(params.payload.idDadosDoFato)),
    mapDadosDoFatoToApi(params.processoProcedimentoId, params.payload)
  )
)

export const deleteDadosDoFato = requestHandler<void, string>(dadosDoFatoId =>
  httpDelete(endpoints.delete(dadosDoFatoId))
)

export const getAnexoByDadosDoFatoId = requestHandler<AnexoViewModel[], string>(dadosDoFatoId =>
  httpGet(endpoints.getAnexoByDadosDoFatoId(dadosDoFatoId))
)

export const deleteAnexo = requestHandler<void, string>(anexoId => httpDelete(endpoints.deleteAnexo(anexoId)))

export const downloadAnexo = requestHandler<Blob, string>(anexoId =>
  httpGet(endpoints.downloadAnexo(anexoId), {}, { responseType: 'blob' })
)
