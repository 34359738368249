import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import omit from 'lodash/omit'

const initialState: Record<string, boolean> = {}

type AddDrawerPayload = PayloadAction<{
  key: string
  isOpen?: boolean
}>

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    set: (state, { payload }: AddDrawerPayload) => ({ ...state, [payload.key]: Boolean(payload.isOpen) }),
    remove: (state, { payload }: PayloadAction<string>) => omit(state, payload),
    reset: () => initialState,
  },
})

export const drawerActions = drawerSlice.actions

export default drawerSlice.reducer
