import React, { ReactElement } from 'react'

import colors from '../styles/colors'
import { IconProps } from './types'

const CopyIcon = ({ size = 18, color = colors.white }: IconProps): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 19 19" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7168 1.25H3.7168C2.8918 1.25 2.2168 1.925 2.2168 2.75V13.25H3.7168V2.75H12.7168V1.25ZM14.9668 4.25H6.7168C5.8918 4.25 5.2168 4.925 5.2168 5.75V16.25C5.2168 17.075 5.8918 17.75 6.7168 17.75H14.9668C15.7918 17.75 16.4668 17.075 16.4668 16.25V5.75C16.4668 4.925 15.7918 4.25 14.9668 4.25ZM14.9668 16.25H6.7168V5.75H14.9668V16.25Z"
      fill={color}
    />
  </svg>
)

export default CopyIcon
