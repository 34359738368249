import CirclePlusIcon from 'icons/circlePlusIcon'
import DataIcon from 'icons/dataIcon'
import GraphIcon from 'icons/graphIcon'
import ListIcon from 'icons/list'
import SearchIcon from 'icons/searchIcon'
import SettingsIcon from 'icons/settings'
import { IconType } from 'icons/types'

type MenuOptions = Array<{
  id: number
  title: string
  route?: string
  query?: string
  icon: IconType
  collapseOptions: Array<{
    title: string
    route: string
    query?: string
  }>
}>

export const menuOptions: MenuOptions = [
  {
    id: 1,
    title: 'Cadastramento',
    route: undefined,
    icon: CirclePlusIcon,
    collapseOptions: [
      {
        title: 'Atendimento',
        route: '/atendimento/cadastrar',
      },
      {
        title: 'Processo / Procedimento',
        route: '/processo-procedimento/cadastrar',
      },
      {
        title: 'Pessoa Física',
        route: '/pessoa-fisica/cadastrar',
        query: '?q=documento&t=cpf'
      },
      {
        title: 'Pessoa Jurídica',
        route: '/pessoa-juridica/cadastrar',
        query: '?q=documento&t=cnpj'
      },
      {
        title: 'Atividade não Procedimental',
        route: '/atividade-nao-procedimental/cadastrar',
      },
    ],
  },
  {
    id: 2,
    title: 'Consulta',
    route: '/consulta',
    query: '?t=principal',
    icon: SearchIcon,
    collapseOptions: [],
  },
  {
    id: 3,
    title: 'Relatórios gerenciais',
    route: undefined,
    icon: DataIcon,
    collapseOptions: [],
  },
  {
    id: 4,
    title: 'Relatórios de produtividade',
    route: undefined,
    icon: GraphIcon,
    collapseOptions: [],
  },
  {
    id: 5,
    title: 'Configurações do sistema',
    route: '/configuracao-sistema',
    icon: SettingsIcon,
    collapseOptions: [],
  },
  {
    id: 6,
    title: 'Tabelas Básicas',
    route: undefined,
    icon: ListIcon,
    collapseOptions: [
      {
        title: 'Equipe - Consultar',
        route: '/equipe-distribuicao/listar',
      },
      {
        title: 'Equipe - Cadastrar',
        route: '/equipe-distribuicao/cadastrar',
      },      
    ],    
  }
]

type MenuCollapseOptions = MenuOptions[0]['collapseOptions']

type GetMenuOptionsProps = Array<{
  route: string
  hasPermission: boolean
}>

export function getMenuOptions(list: GetMenuOptionsProps) {
  const result = menuOptions.reduce((acc: MenuOptions, current) => {
    if (current.route) {
      const hasPermission = list.some(permission => permission.route === current.route && permission.hasPermission)
      if (!hasPermission) return acc
      return [...acc, current]
    }

    if (current.title === 'Consulta') { // TODO: Verificar alternativa para remover esta validação
      return [...acc, current]
    }

    const collapseOptions: MenuCollapseOptions = []
    current.collapseOptions.forEach(option => {
      const hasPermission = list.some(permission => permission.route === option.route && permission.hasPermission)
      if (hasPermission) {
        collapseOptions.push(option)
      }
    })

    if (collapseOptions.length > 0) {
      return [...acc, { ...current, collapseOptions }]
    }

    return acc
  }, [])

  return result
}
