import React, { ReactElement, useState } from 'react'
import { ValidationMode } from 'react-hook-form/dist/types/form'

import Form from 'components/form/Form'

interface FormProviderProps {
  submit?: [
    (_data: Record<string, unknown>) => void,
    React.Dispatch<React.SetStateAction<(_data: Record<string, unknown>) => void>>
  ]
  error?: [
    (_errors: Record<string, unknown>) => void,
    React.Dispatch<React.SetStateAction<(_errors: Record<string, unknown>) => void>>
  ]
  defaultValues?: Record<string, unknown>
}

export const FormContextProvider = React.createContext<FormProviderProps>({})

export default function withForm<T>(
  formId: string,
  WrappedComponent: (_props: T) => ReactElement,
  containerWidth?: string,
  onSubmitH?: (_data: Record<string, unknown>) => void,
  onErrorH?: (_errors: Record<string, unknown>) => void,
  validationMode?: keyof ValidationMode,
  defaultValues?: { [x: string]: object | undefined }
): (_props: T) => ReactElement {
  return function (props) {
    const submit = useState<(_data: Record<string, unknown>) => void>(
      () => onSubmitH as (_data: Record<string, unknown>) => void
    )
    const error = useState<(_errors: Record<string, unknown>) => void>(() => onErrorH)
    return (
      <FormContextProvider.Provider value={{ submit, error, defaultValues }}>
        <Form 
          id={formId} 
          onSubmit={submit[0]} 
          onError={error[0]} 
          validationMode={validationMode} 
          defaultValues={defaultValues} 
          containerWidth={containerWidth}  
        >
          <WrappedComponent {...props} />
        </Form>
      </FormContextProvider.Provider>
    )
  }
}
