import { SagaReturnType, call, put, select, takeLatest } from 'redux-saga/effects'

import { PayloadAction } from '@reduxjs/toolkit'
import message from 'constants/message'
import { AtividadeNaoProcedimental } from 'modules/atividade-nao-procedimental/types'
import {
  createAtividadeNaoProcedimental,
  deleteAtividadeNaoProcedimental,
  updateAtividadeNaoProcedimental,
} from 'services/atividade-nao-procedimental.services'
import { alert } from 'store/alert/duck'
import history from 'store/history'
import { modalsActions } from 'store/modals/duck'

import { atividadeNaoProcedimentalSelectors as selector } from './selector'
import { atividadeNaoProcedimentalActions as action } from './slice'

type CreateAnpResult = SagaReturnType<typeof createAtividadeNaoProcedimental>
type UpdateAnpResult = SagaReturnType<typeof updateAtividadeNaoProcedimental>

function* createAtividadeNaoProcedimentalSaga() {
  try {
    yield put(action.setIsSubmiting(true))

    const payload: AtividadeNaoProcedimental = yield select(selector.values)
    const result: CreateAnpResult = yield call(createAtividadeNaoProcedimental, payload)

    if (!result.success) {
      throw new Error(result.errorMessage)
    }

    yield put(alert.success({ message: message.MSG0001, value: result.data.nuIdea }))
    yield put(action.reset())
    history.push('/')
  } catch (error) {
    if (error instanceof Error) {
      yield put(alert.error(error.message))
    }
  } finally {
    yield put(action.setIsSubmiting(false))
  }
}

function* updateAtividadeNaoProcedimentalSaga() {
  try {
    yield put(action.setIsSubmiting(true))

    const payload: AtividadeNaoProcedimental = yield select(selector.values)
    const result: UpdateAnpResult = yield call(updateAtividadeNaoProcedimental, payload)

    if (!result.success) {
      throw new Error(result.errorMessage)
    }

    yield put(alert.success(message.MSG0013))
    yield put(action.reset())
    history.push('/atividade-nao-procedimental/listar')
  } catch (error) {
    if (error instanceof Error) {
      yield put(alert.error(error.message))
    }
  } finally {
    yield put(action.setIsSubmiting(false))
  }
}

function* deleteAtividadeNaoProcedimentalSaga({ payload }: PayloadAction<{ onSuccess: () => void }>) {
  try {
    const selectedIdToDelete: string = yield select(selector.selectedIdToDelete)

    if (!selectedIdToDelete) {
      throw new Error('Erro ao excluir cadastro.')
    }

    yield call(deleteAtividadeNaoProcedimental, selectedIdToDelete)
    yield call(payload.onSuccess)

    yield put(modalsActions.close({ key: 'modal-confirm-delete-anp' }))
    yield put(alert.success(message.MSG0014))
    yield put(action.reset())
  } catch (error) {
    if (error instanceof Error) {
      yield put(alert.error(error.message))
    }
  }
}

export default function* atividadeNaoProcedimentalSaga() {
  yield takeLatest(action.create.type, createAtividadeNaoProcedimentalSaga)
  yield takeLatest(action.update.type, updateAtividadeNaoProcedimentalSaga)
  yield takeLatest(action.delete.type, deleteAtividadeNaoProcedimentalSaga)
}
