import { createSlice } from '@reduxjs/toolkit'
import { PessoaFisica } from 'modules/pessoa-fisica/types'

type PessoaFisicaStore = {
  currentStep: number
  completedStep: Array<number>
  isSubmiting: boolean
  searchByCPF: boolean
  readOnlyCPF: boolean
  searchByRG: boolean
  origin?: string
  values: PessoaFisica
}

const initialState: PessoaFisicaStore = {
  currentStep: 0,
  completedStep: [],
  isSubmiting: false,
  searchByCPF: false,
  searchByRG: false,
  readOnlyCPF: false,
  values: {
    idPessoaFisica: '',
    nmPessoaFisica: '',
    dtNascimento: undefined,
    nmSocial: '',
    nmAlcunha: '',
    nmMae: '',
    nmPai: '',
    paisOrigem: '',
    ufNaturalidade: '',
    municipioNaturalidade: '',
    nuCpf: '',
    nuRg: '',
    dsOrgaoEmissorRG: '',
    ufExpedicaoRG: '',
    idTpSexo: '',
    genero: '',
    estadoCivil: '',
    grauInstrucao: '',
    idTpNacionalidade: '1',
    documentos: [],
    enderecos: [],
    emails: [],
    telefones: [],
  },
}

export const pessoaFisicaSlice = createSlice({
  name: 'pessoaFisica',
  initialState,
  reducers: {
    create: state => state,
    update: state => state,
    reset: () => initialState,
    setIsSubmiting(state, action) {
      state.isSubmiting = action.payload
    },
    setOrigin(state, action) {
      state.origin = action.payload
    },
    setValues(state, action) {
      state.values = {
        ...state.values,
        ...action.payload,
      }
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload
    },
    setCompletedStep(state, action) {
      state.completedStep = action.payload
    },
    setSearchByCPF(state) {
      state.searchByCPF = true
    },
    setReadOnlyCPF(state, action) {
      state.readOnlyCPF = action.payload
    },
    setSearchByRG(state) {
      state.searchByRG = true
    },
  },
})

export const pessoaFisicaActions = pessoaFisicaSlice.actions
export default pessoaFisicaSlice.reducer
