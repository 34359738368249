import React from 'react'

import { IconProps } from './types'

const ChartIcon = ({ color = '#F2F2F2' }: IconProps) => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 8H7C7 8.13261 7.05268 8.25979 7.14645 8.35355L7.5 8ZM7.5 14.5C3.91015 14.5 1 11.5899 1 8H0C0 12.1421 3.35786 15.5 7.5 15.5V14.5ZM14 8C14 11.5899 11.0899 14.5 7.5 14.5V15.5C11.6421 15.5 15 12.1421 15 8H14ZM7.5 1.5C11.0899 1.5 14 4.41015 14 8H15C15 3.85786 11.6421 0.5 7.5 0.5V1.5ZM7.5 0.5C3.35786 0.5 0 3.85786 0 8H1C1 4.41015 3.91015 1.5 7.5 1.5V0.5ZM7 0.5V8H8V0.5H7ZM7.72361 8.44721L13.7236 5.44721L13.2764 4.55279L7.27639 7.55279L7.72361 8.44721ZM7.14645 8.35355L12.1464 13.3536L12.8536 12.6464L7.85355 7.64645L7.14645 8.35355Z"
      fill={color}
    />
  </svg>
)

export default ChartIcon
