import { CentroCusto } from 'store/orgaoUnidade/duck'
import { Permission } from 'store/permissions/duck'
import { UserState } from 'store/user/duck'

import routes from './api/endpoints/login.endpoints'
import { httpPost, httpGet } from './api/http-methods'
import { requestHandler } from './api/request-handler'

type LoginParams = {
  login: string
  senha: string
}

type FavoritarParams = {
  cdUsuario: number
  cdOrgaoUnidade: number
  favoritar: boolean
}

type SelecionarParams = {
  idOrgaoUnidadeSelecionado: number
}

type GetOrgaoUnidadeParams = {
  codigo: number
  login: string
}

type GetDesignacoesParams = {
  IdUsuario: number
  DataInicioPeriodo: string
  DataFimPeriodo: string | null
}

type Favoritar = {
  idOrgaoUnidadeFavoritoLogin: number
  cdUsuario: number
  cdOrgaoUnidade: number
}

type Selecionar = {
  dsToken: string
  idOrgaoUnidadeSelecionado: number
}

type GetSelecionado = {
  idOrgaoUnidadeSelecionado: number
}

export const login = requestHandler<UserState, LoginParams>(params => httpPost(routes.login, params))

export const getPermissionsByGroups = requestHandler<Permission[], number[]>(groupIds =>
  httpPost(routes.getPermissionsByGroup, { codigosGrupos: groupIds })
)

export const favoritarOrgaoUnidade = requestHandler<Favoritar, FavoritarParams>(params =>
  httpPost(routes.favoritarOrgaoUnidade, params)
)

export const selecionarOrgaoUnidade = requestHandler<Selecionar, SelecionarParams>(params =>
  httpPost(routes.selecionarOrgaoUnidade, params)
)

export const getOrgaoUnidadeSelecionado = requestHandler<GetSelecionado, void>(() =>
  httpGet(routes.getOrgaoUnidadeSelecionado)
)

export const getOrgaoUnidadeByLogin = requestHandler<CentroCusto[], GetOrgaoUnidadeParams>(params =>
  httpPost(routes.getOrgaoUnidade, params)
)

export const getDesignacoesPorFiltro = requestHandler<[], GetDesignacoesParams>(params =>
  httpPost(routes.getDesignacoesPorFiltro, params)
)