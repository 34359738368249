import { formatISO } from 'date-fns'
import { DadosDoFato } from 'modules/processo-procedimento/types'

import {
  AnexoViewModel,
  DadosDoFatoViewModel,
  ProcessoProcedimentoApiViewModel,
} from '../api/models/processo-procedimento.models'

function checkHasDadosDoFato(data: Record<string, unknown>) {
  const keys = [
    'dtFato',
    'dtRegistroOcorrencia',
    'nuRegistroOcorrencia',
    'tpLocal',
    'unidadePolicial',
    'nuCep',
    'anexos',
    'dsLatitude',
    'dsLongitude',
  ]
  return keys.some(key => {
    if (Array.isArray(data[key])) {
      const arr = data[key] as Array<unknown>
      return arr.length > 0
    }
    return !!data[key]
  })
}

export function mapAnexos(data: AnexoViewModel) {
  return {
    idProcProcedimentoFato: data.idProcProcedimentoFato,
    idAnexoFato: data.idAnexoFato,
    nmArquivo: data.nmArquivo,
    dsArquivo: data.dsArquivo,
    mensagem: '',
    stUpload: true,
    sucesso: true,
    tamanhoArquivo: 0,
    tipoArquivo: '',
  }
}

export function mapEnderecoToStore(data: ProcessoProcedimentoApiViewModel) {
  if (!data.nuCep) {
    return undefined
  }

  return {
    nuCep: data.nuCep,
    dsComplementoLogradouro: data.complemento,
    dsLogradouro: data.dsLogradouro,
    dsPontoReferencia: data.dsPontoReferencia,
    uf: data.uf ? { value: data.uf.idUf, label: data.uf.sgUf } : { value: '', label: '' },
    bairro: data.bairro ? { value: data.bairro.cdBairro, label: data.bairro.nmBairro } : { value: '', label: '' },
    municipio: data.municipio
      ? { value: data.municipio.cdMunicipio, label: data.municipio.nmMunicipio }
      : { value: '', label: '' },
    distrito: { value: '', label: '' },
    stPrincipal: true,
  }
}

function getValueOrNull(value?: string | number) {
  if (!value) {
    return null
  }
  return value
}

export function mapDadosDoFatoToApi(idProcesso: string, dadosDoFato: DadosDoFato) {    
    return {
      idProcProcedimento: idProcesso,
      dtFato: dadosDoFato.dataHora ? formatISO(dadosDoFato.dataHora) : null,
      idTpLocal: typeof dadosDoFato.tipoLocal === 'string' ? null : dadosDoFato.tipoLocal?.idTpLocal,
      nuCep: getValueOrNull(dadosDoFato.endereco?.nuCep),
      dsLogradouro: getValueOrNull(dadosDoFato.endereco?.dsLogradouro),
      nuNumero: getValueOrNull(dadosDoFato.endereco?.nuLogradouro),
      dsComplemento: getValueOrNull(dadosDoFato.endereco?.dsComplementoLogradouro),
      idUf: getValueOrNull(dadosDoFato.endereco?.uf?.value ?? ''),
      cdMunicipio: getValueOrNull(dadosDoFato.endereco?.municipio?.value?? ''),
      cdBairro: getValueOrNull(dadosDoFato.endereco?.bairro?.value?? ''),
      dsPontoReferencia: getValueOrNull(dadosDoFato.endereco?.dsPontoReferencia),
      dsLatitude: dadosDoFato.dsLatitude,
      dsLongitude: dadosDoFato.dsLongitude,
      cdOuPolicial: typeof dadosDoFato.unidadeDelegacia === 'string' ? null : dadosDoFato.unidadeDelegacia?.cdOuPolicial,
      nuRegistroOcorrencia: dadosDoFato.nuOcorrencia,
      dtRegistroOcorrencia: dadosDoFato.dataOcorrencia ? formatISO(dadosDoFato.dataOcorrencia) : null,
      anexos: dadosDoFato?.anexos?.map(an => ({
        idProcProcedimentoFato: dadosDoFato.idDadosDoFato,
        idAnexoFato: an.idAnexoFato,
        nmArquivo: an.nmArquivo,
        dsArquivo: an.dsArquivo,
      })),
    }  
}

export function mapDadosDoFato(data: DadosDoFatoViewModel): DadosDoFato | undefined {
  if (!checkHasDadosDoFato(data)) {
    return undefined
  }

  return {
    idDadosDoFato: data.idProcProcedimentoFato,
    dataHora: data.dtFato ? new Date(data.dtFato) : null,
    dataOcorrencia: data.dtRegistroOcorrencia ? new Date(data.dtRegistroOcorrencia) : null,
    nuOcorrencia: data.nuRegistroOcorrencia || '',
    tipoLocal: data.tpLocal ? { idTpLocal: data.tpLocal.idTpLocal, nmTpLocal: data.tpLocal.nmTpLocal } : '',
    unidadeDelegacia: data.unidadePolicial
      ? { cdOuPolicial: data.unidadePolicial.cdOuPolicial, dsOuPolicial: data.unidadePolicial.dsOuPolicial }
      : '',
    endereco: data.nuCep
      ? {
          nuCep: data.nuCep,
          dsComplementoLogradouro: data.dsComplemento,
          dsLogradouro: data.dsLogradouro,
          dsPontoReferencia: data.dsPontoReferencia,
          uf: data.uf ? { value: data.uf.idUF, label: data.uf.sgUF } : { value: '', label: '' },
          bairro: data.bairro ? { value: data.bairro.cdBairro, label: data.bairro.nmBairro } : { value: '', label: '' },
          municipio: data.municipio
            ? { value: data.municipio.cdMunicipio, label: data.municipio.nmMunicipio }
            : { value: '', label: '' },
          distrito: { value: '', label: '' },
          stPrincipal: true,
        }
      : undefined,
    dsLatitude: data.dsLatitude,
    dsLongitude: data.dsLongitude,
    anexos: data.anexos?.map(mapAnexos),
  }
}
