import { Reducer } from 'redux'
import { persistReducer, createTransform } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import storage from 'redux-persist/lib/storage'

import { AppState } from 'store/rootReducer'

import { initialState, UserState } from './user/duck'

const userTransformer = createTransform(
  (actualState: UserState) => ({
    ...actualState,
    rememberedUserName: actualState.rememberLogin ? actualState.login : '',
    rememberLogin: actualState.rememberLogin,
  }),
  storedState => ({
    ...initialState,
    ...storedState,
    username: storedState.rememberedUserName || '',
    rememberLogin: storedState.rememberLogin || false,
  }),
  { whitelist: ['user'] }
)

export default (reducers: Reducer<AppState>): Reducer =>
  persistReducer(
    {
      key: 'root-v2',
      storage,
      whitelist: ['user', 'setting', 'orgaoUnidade', 'permissions'],
      transforms: [
        userTransformer,
        encryptTransform({
          secretKey: 'my-super-secret-key',
        }),
      ],
    },
    reducers
  )
