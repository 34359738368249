import { AxiosRequestConfig } from 'axios'

import { axiosInstance } from './axios'

export function httpGet<TParams = unknown>(url: string, params?: TParams, config?: AxiosRequestConfig) {
  return axiosInstance.get(url, { params, ...config })
}

export function httpPost<TData = unknown>(url: string, data: TData, config?: AxiosRequestConfig) {
  return axiosInstance.post(url, data, config)
}

export function httpPut<TData = unknown>(url: string, data: TData, config?: AxiosRequestConfig) {
  return axiosInstance.put(url, data, config)
}

export function httpDelete(url: string, config?: AxiosRequestConfig) {
  return axiosInstance.delete(url, config)
}
