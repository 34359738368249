import { SagaReturnType, call, put, select, takeLatest } from 'redux-saga/effects'

import { PayloadAction } from '@reduxjs/toolkit'
import message from 'constants/message'
import { createEquipeDistribuicao, deleteEquipeDistribuicao, getEquipesDistribuicao, updateEquipeDistribuicao } from 'services/equipe-distribuicao.services'
import { mapEquipeDistribuicaoFormToCreate, mapEquipeDistribuicaoFormToUpdate } from 'services/mappers/equipe-distribuicao.mappers'
import { alertActions } from 'store/alert/duck'
import history from 'store/history'

import { equipeDistribuicaoSelectors } from './selector'
import { EquipeDistribuicaoForm, equipeDistribuicaoActions } from './slice'


type GetAllResult = SagaReturnType<typeof getEquipesDistribuicao>
type CreateEquipeDistribuicaoResult = SagaReturnType<typeof createEquipeDistribuicao>
type UpdateEquipeDistribuicaoResult = SagaReturnType<typeof updateEquipeDistribuicao>
type DeleteEquipeDistribuicaoResult = SagaReturnType<typeof deleteEquipeDistribuicao>

function* handleLoad() {
  try {
    yield put(equipeDistribuicaoActions.setLoading(true))
    const result: GetAllResult = yield call(getEquipesDistribuicao, undefined)

    if (!result.success) {
      throw new Error(result.errorMessage)
    }
    yield put(equipeDistribuicaoActions.set(result.data))
    yield put(equipeDistribuicaoActions.setLoading(false))
  } catch (error) {
    yield put(equipeDistribuicaoActions.setLoading(false))    
    yield put(
      alertActions.open({
        isOpen: true,
        message: 'Não foi possível carregar as equipes de distribuição.',
        type: 'warning',
        value: '',
      })
    )
  }
}

function* handleCreate() {
  try {
    const formValues: EquipeDistribuicaoForm = yield select(equipeDistribuicaoSelectors.currentForm)
    const result: CreateEquipeDistribuicaoResult = yield call(createEquipeDistribuicao, mapEquipeDistribuicaoFormToCreate(formValues))

    if (!result.success) {
      throw new Error(result.errorMessage)
    }

    yield put(alertActions.success(message.MSG0001))
    yield put(equipeDistribuicaoActions.reset())

    history.push('/equipe-distribuicao/listar')
  } catch (error) {
    if (error instanceof Error) {
      yield put(alertActions.error(error.message))
    }
  }
}

function* handleUpdate({ payload }: PayloadAction<number>) {
  try {
    const formValues: EquipeDistribuicaoForm = yield select(equipeDistribuicaoSelectors.currentForm)
    const result: UpdateEquipeDistribuicaoResult = yield call(updateEquipeDistribuicao, mapEquipeDistribuicaoFormToUpdate(payload, formValues))

    if (!result.success) {
      throw new Error(result.errorMessage)
    }

    yield put(alertActions.success(message.MSG0001))
    yield put(equipeDistribuicaoActions.reset())

    history.push('/equipe-distribuicao/listar')
  } catch (error) {
    if (error instanceof Error) {
      yield put(alertActions.error(error.message))
    }
  }
}

function* handleRemove({ payload }: PayloadAction<number>) {
  try {    
    const result: DeleteEquipeDistribuicaoResult = yield call(deleteEquipeDistribuicao, payload)
    const alertFn = result.success ? alertActions.success : alertActions.error
    const alertMsg = result.success ? message.MSG0014 : result.errorMessage
    yield put(equipeDistribuicaoActions.reset())
    yield put(alertFn(alertMsg))
    yield put(equipeDistribuicaoActions.load())
  } catch (error) {
    if (error instanceof Error) {
      yield put(alertActions.error(error.message))
    }
  }
}

export default function* equipeDistribuicaoSaga(): Generator {
  yield takeLatest(equipeDistribuicaoActions.load.type, handleLoad)
  yield takeLatest(equipeDistribuicaoActions.create.type, handleCreate)
  yield takeLatest(equipeDistribuicaoActions.update.type, handleUpdate)
  yield takeLatest(equipeDistribuicaoActions.remove.type, handleRemove)
}

