import React, { ReactElement, useCallback } from 'react'

import withTooltip from 'hoc/withTooltip'
import { DefaultProps } from 'styles/types'

import { Avatar } from '@material-ui/core'

import { AvatarContainer } from '../avatar/styles'

type AvatarProps = {
  url?: string
  userName: string
  variant?: 'circle' | 'circular' | 'rounded' | 'square'
  sizes?: string
} & DefaultProps

const PerfilAvatar = ({ id, url, userName, variant, sizes, style, className }: AvatarProps): ReactElement => {
  const transformUserName = useCallback(() => {
    const nameParts = userName.split(' ')
    return `${nameParts[0].charAt(0)}${nameParts[1].charAt(0)}`
  }, [userName])

  return (
    <AvatarContainer id={id} className={className}>
      <div>
        <Avatar src={`${url}`} variant={variant} alt="Imagem perfil" sizes={sizes} style={style}>
          {!url && transformUserName()}
        </Avatar>
      </div>
    </AvatarContainer>
  )
}

export default withTooltip(PerfilAvatar)
