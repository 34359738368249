import { put, takeLatest } from 'redux-saga/effects'

import { modalsActions } from 'store/modals/duck'

import { pessoaFisicaActions, pessoaJuridicaActions, atividadeNaoProcedimentalActions, processoProcedimentoActions } from '../actions'
import { ResetFormPayload, navigationActions } from './slice'

function* handleResetFormsState({ payload }: ResetFormPayload) {
  switch (payload) {
    case 'ALL':
      yield put(pessoaFisicaActions.reset())
      yield put(pessoaJuridicaActions.reset())
      yield put(atividadeNaoProcedimentalActions.reset())
      yield put(processoProcedimentoActions.reset())
      yield put(modalsActions.reset())
      break
    case 'PESSOA_FISICA':
      yield put(pessoaFisicaActions.reset())
      break
    case 'PESSOA_JURIDICA':
      yield put(pessoaJuridicaActions.reset())
      break
    case 'ANP':
      yield put(atividadeNaoProcedimentalActions.reset())
      break
    case 'PROCESSO_PROCEDIMENTO':
      yield put(processoProcedimentoActions.reset())
      break
    default:
      break
  }
}

export default function* saga() {
  yield takeLatest(navigationActions.resetFormState.type, handleResetFormsState)
}
