import { AxiosResponse } from 'axios'

import { errorHandler } from './error-handler'

type Response<TValue> =
  | {
      success: true
      data: TValue
    }
  | {
      success: false
      errorMessage: string
    }

export const requestHandler =
  <TValue = unknown, TParams = unknown>(request: (_params: TParams) => Promise<AxiosResponse>) =>
  async (params: TParams): Promise<Response<TValue>> => {
    try {
      const response = await request(params)

      if (typeof response.data?.errors === 'object' && response.data.errors !== null) {
        throw response.data.errors
      }

      return { success: true, data: response.data }
    } catch (error) {
      return { success: false, errorMessage: errorHandler(error) }
    }
  }
