import { ReactNode } from 'react'

import useModal from 'hooks/useModal'
import styled from 'styled-components'

import { ModalBody } from './ModalBody'
import { ModalFooter } from './ModalFooter'
import { ModalHeader } from './ModalHeader'
import { ModalRoot } from './ModalRoot'

const ModalContainer = styled.div`
  position: fixed;
  z-index: 999;
  inset: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalBackdrop = styled.div`
  position: fixed;
  inset: 0px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
`

const ModalContent = styled.div<{ size: string }>`
  width: 100%;
  max-width: ${props => props.size};
  max-height: 90vh;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin: 1rem;
  border-radius: 16px;
  background-color: #f7f8fa;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #fff;
  overflow-y: scroll;
`

const maxWidth = {
  sm: '600px',
  md: '960px',
  lg: '1280px',
}

type ModalProps = {
  children: ReactNode
  modalId: string
  onClose?: VoidFunction
  size?: 'sm' | 'md' | 'lg'
  fixed?: boolean
}

const Modal = (props: ModalProps) => {
  const { children, modalId, onClose, size = 'md', fixed } = props
  const { isOpen, closeModal } = useModal(modalId)

  const handleClose = !fixed ? onClose || closeModal : undefined

  if (!isOpen) return null

  return (
    <ModalRoot onClose={handleClose}>
      <ModalContainer role="dialog" aria-modal="true">
        <ModalBackdrop aria-hidden="true" onClick={handleClose} />
        <ModalContent aria-labelledby="modal-heading" size={maxWidth[size]}>
          {children}
        </ModalContent>
      </ModalContainer>
    </ModalRoot>
  )
}

// Components composition
// Usage: <Moda.ModalHeader /> | <Moda.ModalBody />  />
Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter

export default Modal
