import React from 'react'

import colors from 'styles/colors'

import { IconProps } from './types'

const SearchIcon = ({ color = colors.lightGrey, width, height}: IconProps): React.ReactElement => (
  <svg width={width || "22"} height={height || "22"} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path
      d="M16.5281 15.2324L20.4542 19.1576L19.1571 20.4547L15.2319 16.5286C13.7714 17.6994 11.9548 18.3362 10.083 18.3335C5.52901 18.3335 1.83301 14.6375 1.83301 10.0835C1.83301 5.5295 5.52901 1.8335 10.083 1.8335C14.637 1.8335 18.333 5.5295 18.333 10.0835C18.3357 11.9553 17.6989 13.7719 16.5281 15.2324ZM14.6893 14.5522C15.8526 13.3559 16.5023 11.7522 16.4997 10.0835C16.4997 6.53783 13.6278 3.66683 10.083 3.66683C6.53734 3.66683 3.66634 6.53783 3.66634 10.0835C3.66634 13.6282 6.53734 16.5002 10.083 16.5002C11.7517 16.5028 13.3554 15.8531 14.5518 14.6897L14.6893 14.5522Z"
      fill={color || "#09121F"}
    />
  </svg>
)

export default SearchIcon
