import mavenPro from './MavenPro/MavenPro-Regular.ttf'
import poppinsBold from './Poppins/Poppins-Bold.ttf'
import poppinsLight from './Poppins/Poppins-Light.ttf'
import poppinsMedium from './Poppins/Poppins-Medium.ttf'
import poppinsRegular from './Poppins/Poppins-Regular.ttf'
import poppinsSemiBold from './Poppins/Poppins-SemiBold.ttf'
import poppinsThin from './Poppins/Poppins-Thin.ttf'
import ralewayBold from './Raleway/Raleway-Bold.ttf'
import ralewayLight from './Raleway/Raleway-Light.ttf'
import ralewayMedium from './Raleway/Raleway-Medium.ttf'
import ralewayRegular from './Raleway/Raleway-Regular.ttf'
import ralewaySemiBold from './Raleway/Raleway-SemiBold.ttf'
import ralewayThin from './Raleway/Raleway-Thin.ttf'
import robotoRegular from './Roboto/Roboto-Regular.ttf'

const fontWeight = {
  thin: '100',
  light: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  black: '900',
}

const fontFaceRaleway = [
  {
    fontFamily: 'Raleway',
    src: `url("${ralewayLight}") format("truetype")`,
    fontWeight: fontWeight.light,
  },
  {
    fontFamily: 'Raleway',
    src: `url("${ralewayThin}") format("truetype")`,
    fontWeight: fontWeight.light,
  },
  {
    fontFamily: 'Raleway',
    src: `url("${ralewayMedium}") format("truetype")`,
    fontWeight: fontWeight.light,
  },
  {
    fontFamily: 'Raleway',
    src: `url("${ralewayLight}") format("truetype")`,
    fontWeight: fontWeight.light,
  },
  {
    fontFamily: 'Raleway',
    src: `url("${ralewayLight}") format("truetype")`,
    fontWeight: fontWeight.light,
  },
  {
    fontFamily: 'Raleway',
    src: `url("${ralewayLight}") format("truetype")`,
    fontWeight: fontWeight.light,
  },
  {
    fontFamily: 'Raleway',
    src: `url("${ralewayLight}") format("truetype")`,
    fontWeight: fontWeight.light,
  },
  {
    fontFamily: 'Raleway',
    src: `url("${ralewayLight}") format("truetype")`,
    fontWeight: fontWeight.light,
  },
  {
    fontFamily: 'Raleway',
    src: `url("${ralewayLight}") format("truetype")`,
    fontWeight: fontWeight.light,
  },
  {
    fontFamily: 'Raleway',
    src: `url("${ralewayRegular}") format("truetype")`,
    fontWeight: fontWeight.regular,
  },
  {
    fontFamily: 'Raleway',
    src: `url("${ralewaySemiBold}") format("truetype")`,
    fontWeight: fontWeight.semiBold,
  },
  {
    fontFamily: 'Raleway',
    src: `url("${ralewayBold}") format("truetype")`,
    fontWeight: fontWeight.bold,
  },
]

const fontFacePoppins = [
  {
    fontFamily: 'Poppins',
    src: `url("${poppinsThin}") format("truetype")`,
    fontWeight: fontWeight.thin,
  },
  {
    fontFamily: 'Poppins',
    src: `url("${poppinsLight}") format("truetype")`,
    fontWeight: fontWeight.light,
  },
  {
    fontFamily: 'Poppins',
    src: `url("${poppinsMedium}") format("truetype")`,
    fontWeight: fontWeight.medium,
  },
  {
    fontFamily: 'Poppins',
    src: `url("${poppinsRegular}") format("truetype")`,
    fontWeight: fontWeight.regular,
  },
  {
    fontFamily: 'Poppins',
    src: `url("${poppinsSemiBold}") format("truetype")`,
    fontWeight: fontWeight.semiBold,
  },
  {
    fontFamily: 'Poppins',
    src: `url("${poppinsBold}") format("truetype")`,
    fontWeight: fontWeight.bold,
  },
]

const fontFaceRoboto = [
  {
    fontFamily: 'Roboto',
    src: `url("${robotoRegular}") format("truetype")`,
    fontWeight: fontWeight.regular,
  },
  {
    fontFamily: 'Roboto',
    src: `url("${robotoRegular}") format("truetype")`,
    fontWeight: fontWeight.medium,
  },
]

const fontMavenPro = [
  {
    fontFamily: 'Maven',
    src: `url("${mavenPro}") format("truetype")`,
    fontWeight: fontWeight.regular,
  },
]

const fontFamilyRaleway = ["'Raleway'", '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Oxygen'].join(',')
const fontFamily = ["'Roboto'", "'Raleway'", '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Oxygen'].join(',')

export default {
  fontFaceRaleway,
  fontFacePoppins,
  fontFaceRoboto,
  fontFamily,
  fontFamilyRaleway,
  fontWeight,
  fontMavenPro,
}
