import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects'

import { AxiosError } from 'axios'
import { getOrgaoUnidadeAtuacaoByIds } from 'services/global.services'
import { login as loginService } from 'services/login.services'
import { errorActions } from 'store/errors/duck'
import history from 'store/history'
import { applyLoading } from 'store/loader/applyLoading'
import { orgaoUnidadeActions } from 'store/orgaoUnidade/duck'
import { permissionActions } from 'store/permissions/duck'

import { LoginPayload, userActions } from './duck'

type GetOrgaoUnidadeAtuacaoResult = SagaReturnType<typeof getOrgaoUnidadeAtuacaoByIds>
type LoginResult = SagaReturnType<typeof loginService>

function* userLogin({ payload: { login, senha, rememberLogin, onSuccess, onError } }: LoginPayload) {
  try {
    yield put(errorActions.reset())
    yield put(userActions.setLoading(true))
    const response: LoginResult = yield call(loginService, { login, senha })
    yield put(userActions.setLoading(false))

    if (!response.success) {
      throw new Error(response.errorMessage)
    }

    const centrosCusto = response.data.centrosCusto

    // TODO: validar redirecionamento e carregamento de informaçoes
    if (centrosCusto.length > 1) {
      const hasFavorite = centrosCusto.some(centroCusto => centroCusto.favorito)
      const groupIds = centrosCusto.find(centroCusto => centroCusto.favorito)?.grupos?.map(grupo => grupo.codigo)

      if (!hasFavorite) {
        history.push('/orgao-unidade/favoritar')
      } else {
        if (groupIds) {
          yield put(permissionActions.load(groupIds))
        }
        history.push('/')
      }
    } else {
      const groupIds = centrosCusto?.[0]?.grupos?.map(grupo => grupo.codigo)
      if (groupIds) {
        yield put(permissionActions.load(groupIds))
      } else {
        yield put(errorActions.add({ key: 'dsLogin', errors: ['Usuário não possui permissão!'] }))
        return
      }
    }

    yield put(userActions.set({ ...response.data, rememberLogin, loading: false }))

    const codigosOu = centrosCusto.map(centroCusto => centroCusto.codigo)
    const ousResult: GetOrgaoUnidadeAtuacaoResult = yield call(getOrgaoUnidadeAtuacaoByIds, codigosOu)

    if (!ousResult.success) {
      throw new Error(ousResult.errorMessage)
    }

    const ous = ousResult.data

    yield put(
      orgaoUnidadeActions.set(
        centrosCusto.map(cc => {
          const orgaoUnidadeInfo = ous.find(item => item.idOrgaoUnidade === cc.codigo)
          return {
            ...cc,
            stAtuacao: orgaoUnidadeInfo?.stAtuacao,
            stEleitoral: orgaoUnidadeInfo?.stEleitoral,
            stDistribuicao: orgaoUnidadeInfo?.stDistribuicao,
            idComarca: orgaoUnidadeInfo?.idComarca,
          }
        })
      )
    )

    if (onSuccess) yield call(onSuccess)
  } catch (error) {
    yield put(userActions.setLoading(false))

    const dataError = (error as AxiosError).response?.data

    if (dataError.errors) {
      const arrayErros = dataError.errors as Array<string>
      if (arrayErros.includes('Usuário não encontrado!')) {
        yield put(errorActions.add({ key: 'dsLogin', errors: ['Usuário não encontrado!'] }))
      }

      if (arrayErros.includes('Digite uma senha válida!'))
        yield put(errorActions.add({ key: 'dsSenha', errors: ['Digite uma senha válida!'] }))
    }
    if (onError) yield call(onError)
  }
}

export default function* userSaga(): Generator {
  yield takeLatest(userActions.login.type, applyLoading(userLogin))
}
