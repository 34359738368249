import { put } from 'redux-saga/effects'

import { PayloadAction } from '@reduxjs/toolkit'

import { loaderActions } from './duck'

export function applyLoading(sagaFunction: unknown) {
  return function* (action: PayloadAction<unknown>): Generator {
    const defaultActionStart = action.type
    const defaultActionEnd = action.type

    yield put(loaderActions.add(defaultActionStart))
    yield (sagaFunction as GeneratorFunction)(action)
    yield put(loaderActions.remove(defaultActionEnd))
  }
}
