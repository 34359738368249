import { EquipeDistribuicaoViewModel } from 'services/api/models/equipe-distribuicao.model'
import { CreateEquipeDistribuicao, UpdateEquipeDistribuicao } from 'services/equipe-distribuicao.services'
import { EquipeDistribuicaoForm } from 'store/equipe-distribuicao/slice'
import { getOptionValue } from 'utils/helpers'

export function mapEquipeDistribuicaoViewModelToForm(viewModel: EquipeDistribuicaoViewModel) {
	const retorno: EquipeDistribuicaoForm = {
		dsEquipeDist: viewModel.dsEquipeDist,
		idOrgaoUnidade: {
			value: viewModel.orgaoUnidade.idOrgaoUnidade.toString(),
			label: viewModel.orgaoUnidade.dsOrgaoUnidadeCompletaMunicipio
		},
		idTpEquipeDistribuicao: viewModel.tpEquipeDistribuicao.idTpEquipeDistribuicao.toString(),
		stAtivo: viewModel.stAtivo ? 'true' : 'false',
		stEquipeAutomatica: viewModel.stEquipeAutomatica ? 'true' : 'false'
	}

	return retorno
}

export function mapEquipeDistribuicaoFormToCreate(form: EquipeDistribuicaoForm) {
	const retorno: CreateEquipeDistribuicao = {
		dsEquipeDist: form.dsEquipeDist,
		idOrgaoUnidade: Number(getOptionValue(form.idOrgaoUnidade)),
		idTpEquipeDistribuicao: Number(form.idTpEquipeDistribuicao),
		stAtivo: form.stAtivo === 'true',
		stEquipeAutomatica: form.stEquipeAutomatica === 'true'
	}

	return retorno
}

export function mapEquipeDistribuicaoFormToUpdate(id: number, form: EquipeDistribuicaoForm) {
	const retorno: UpdateEquipeDistribuicao = {
    idEquipeDist: id,
		dsEquipeDist: form.dsEquipeDist,
		idOrgaoUnidade: Number(getOptionValue(form.idOrgaoUnidade)),
		idTpEquipeDistribuicao: Number(form.idTpEquipeDistribuicao),
		stAtivo: form.stAtivo === 'true',
		stEquipeAutomatica: form.stEquipeAutomatica === 'true'
	}

	return retorno
}
