import { Reducer, Store } from 'redux'
import { SagaMiddleware } from 'redux-saga'

import { configureStore, ListenerMiddleware } from '@reduxjs/toolkit'

export default (reducers: Reducer, sagaMiddleware: SagaMiddleware, reduxMiddleware: ListenerMiddleware[]): Store =>
  configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ thunk: false }).concat([sagaMiddleware, ...reduxMiddleware]),
    devTools: { trace: true, traceLimit: 30 },
  })
