const getBaseUrlVar = (msname: string) => {
    if (msname === 'finalistico') {
        return process.env.REACT_APP_FINALISTICO_URL_LOCAL
    } else if (msname === 'global') {
        return process.env.REACT_APP_GLOBAL_URL_LOCAL
    } else if (msname === 'login') {
        return process.env.REACT_APP_LOGIN_URL_LOCAL
    } else {
        throw new Error('Defina um nome de serviço correto.');
    }
}

const getBaseUrlLocal = (msname: string) => {    
    const LOCAL_API_URL = getBaseUrlVar(msname);

    return `${LOCAL_API_URL}/api`
}

export const getBaseUrl = (msname: string) => {
    const USE_LOCAL_APIS = process.env.REACT_APP_USE_LOCAL_APIS

    return USE_LOCAL_APIS ? getBaseUrlLocal(msname) : `${msname}/api`;
}