// import React from 'react'

// import Colors from 'styles/colors'

import { IconProps } from './types'

const DoubleCaretLeftIcon = ({ size = 14 }: IconProps) => (
  <svg width={`${size}`} height={`${size}`} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00003 1.5L1.00003 8L8.00003 14.5M13.5 1.5L6.50003 8L13.5 14.5" stroke="#828282" strokeLinecap="square"/>
  </svg>
)

export default DoubleCaretLeftIcon