import { ReactElement } from 'react'

import { DefaultProps } from 'styles/types'

import { Tooltip } from '@material-ui/core'

export default function withTooltip<T>(
  WrapperComponent: (_props: T & DefaultProps) => ReactElement
): (_props: T & DefaultProps) => ReactElement {
  return function (props) {
    return props.tooltipTitle ? (
      <Tooltip title={props.tooltipTitle} arrow>
        <div>
          <WrapperComponent {...props} />
        </div>
      </Tooltip>
    ) : (
      <WrapperComponent {...props} />
    )
  }
}
