import { IconProps } from './types'

const ServerIcon = ({ size = 18 }: IconProps) => (
  <svg width={`${size}`} height={`${size}`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.33333 3.3335C1.33333 2.22893 2.22876 1.3335 3.33333 1.3335H12.6667C13.7712 1.3335 14.6667 2.22893 14.6667 3.3335V4.66683C14.6667 5.7714 13.7712 6.66683 12.6667 6.66683H3.33333C2.22876 6.66683 1.33333 5.7714 1.33333 4.66683V3.3335Z" stroke="#828282" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.33333 11.3335C1.33333 10.2289 2.22876 9.3335 3.33333 9.3335H12.6667C13.7712 9.3335 14.6667 10.2289 14.6667 11.3335V12.6668C14.6667 13.7714 13.7712 14.6668 12.6667 14.6668H3.33333C2.22876 14.6668 1.33333 13.7714 1.33333 12.6668V11.3335Z" stroke="#828282" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="3.99999" cy="4.00016" r="0.666667" fill="#828282"/>
    <ellipse cx="3.99999" cy="12.0002" rx="0.666667" ry="0.666667" fill="#828282"/>
  </svg>
)

export default ServerIcon