/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DadosDoFato, Instrumento, ProcessoProcedimento } from 'modules/processo-procedimento/types'
import { UploadAnexosResult } from 'services/anexo.services'

type ProcessoProcedimentoState = {
  currentStep: number
  completedStep: Array<number>
  selectedEnvolvidoId: string
  values: ProcessoProcedimento
  origin?: string
}

const initialState: ProcessoProcedimentoState = {
  currentStep: 0,
  completedStep: [],
  selectedEnvolvidoId: '',
  values: {
    nivelAcesso: '0',
    numeroProcesso: '',
    assuntos: [],
    envolvidos: [],
    classe: [],
    classeExtraJudicial: true,
    informacoesComplementares: '',
    objeto: '',
    orgaoUnidade: null,
    tema: [],
    expedientesAssociados: [],
    instrumentos: [],
    modalEnvolvidosTpPessoa: 'PF',
    modalEnvolvidosTpEnvolvimento: '',
    modalEnvolvidosStDesconhecido: false,
    modalEnvolvidosStSociedade: false,
    idComarca: undefined,
    nProcessoFormatValid: true,
    nProcessoAssociado: false,
  },
}

export const processoProcedimentoSlice = createSlice({
  name: 'processoProcedimento',
  initialState,
  reducers: {
    create: state => state,
    update: state => state,
    reset: () => initialState,
    setOrigin(state, action) {
      state.origin = action.payload
    },
    setValues(state, action) {
      state.values = {
        ...state.values,
        ...action.payload,
      }
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload
    },
    setCompletedStep(state, action) {
      state.completedStep = action.payload
    },
    setSelectedEnvolvidoId(state, action) {
      state.selectedEnvolvidoId = action.payload
    },
    resetSelectedEnvolvidoId(state) {
      state.selectedEnvolvidoId = ''
    },
    resetFieldsModalEnvolvido(state) {
      state.values.modalEnvolvidosTpPessoa = 'PF'
      state.values.modalEnvolvidosTpEnvolvimento = ''
      state.values.modalEnvolvidosStDesconhecido = false
      state.values.modalEnvolvidosStSociedade = false
    },
    loadProcessoProcedimento: (state, payload: PayloadAction<{ processoProcedimentoId: string }>) => state,
    deleteAnexo(state, action: PayloadAction<{ dsArquivo: string; anexoId?: string }>) {
      if (state.values?.dadosDoFato?.anexos?.length) {
        state.values.dadosDoFato.anexos = state.values.dadosDoFato.anexos.filter(
          d => d.dsArquivo !== action.payload.dsArquivo
        )
      }
    },
    addAnexos(state, action: PayloadAction<{ anexos: UploadAnexosResult[] }>) {
      if (Array.isArray(state.values?.dadosDoFato?.anexos)) {
        state.values?.dadosDoFato?.anexos.push(...action.payload.anexos)
      } else {
        state.values.dadosDoFato = {
          ...state.values.dadosDoFato,
          anexos: action.payload.anexos,
        }
      }
    },
    loadDadosDoFato: (state, payload: PayloadAction<{ processoProcedimentoId: string }>) => state,
    saveDadosDoFato: (state, payload: PayloadAction<DadosDoFato>) => state,
    removeDadosDoFato(state) {
      state.values.dadosDoFato = undefined
    },
    setDadosDoFato(state, action) {
      state.values.dadosDoFato = {
        ...state.values.dadosDoFato,
        ...action.payload,
      }
    },
    loadInstrumentos: (state, payload: PayloadAction<{ processoProcedimentoId: string }>) => state,
    saveInstrumento: (state, payload: PayloadAction<Instrumento>) => state,
    setInstrumentos(state, action) {
      state.values.instrumentos = action.payload
    },
  },
})

export const processoProcedimentoActions = processoProcedimentoSlice.actions
export default processoProcedimentoSlice.reducer
