import styled from 'styled-components'

export const Content = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #f2f2f2;
`
export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const Main = styled.div`
  flex: 1 1 auto;
  position: relative;

  .main__wrapper {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: row;
  }

  .content__wrapper {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    height: 100%;
  }
`
