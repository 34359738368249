import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const permissionsState = (state: AppState) => state.permissions

const all = createSelector(permissionsState, permissions => permissions)

const getPermissionsByUrl = (url: string) =>
  createSelector(permissionsState, permissions => permissions.filter(permission => permission.urlTela.toUpperCase() === url.toUpperCase() && permission.ativo === true))

const getPermissionsByUrlAndAction = (url: string, action: string) =>
  createSelector(permissionsState, permissions =>
    permissions.filter(permission => permission.urlTela.toUpperCase() === url.toUpperCase() && permission.nomeAcao.toUpperCase() === action.toUpperCase() && permission.ativo === true)
  )

export const checkPermission = (url: string, action: string) =>
  createSelector(
    permissionsState,
    permissions =>
      permissions.filter(
        permission =>
          permission.urlTela.toUpperCase() === url.toUpperCase() &&
          permission.nomeAcao.toUpperCase() === action.toUpperCase() &&
          permission.ativo === true
      ).length > 0
  )

export const checkPagePermission = (url: string) =>
  createSelector(
    permissionsState,
    permissions =>
      permissions.filter(
        permission =>
          permission.urlTela.toUpperCase() === url.toUpperCase() &&
          permission.ativo === true
      ).length > 0
  )

export default { all, getPermissionsByUrl, getPermissionsByUrlAndAction }
