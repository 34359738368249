import colors from './colors'
import fonts from './fonts/fonts'

export default () => ({
  '@global': {
    // add global css here

    '@font-face': [...fonts.fontFaceRoboto, ...fonts.fontFaceRaleway, ...fonts.fontFacePoppins],

    'html, body, #root': {
      padding: 0,
      margin: 0,
      fontFamily: fonts.fontFamily,
      height: '100%',
    },

    a: {
      color: 'inherit',
      textDecoration: 'none',
    },

    '*': { boxSizing: 'border-box', margin: 0 },

    '.MuiFormLabel-asterisk': {
      color: colors.red,
      fontSize: '16px',
      fontWeight: 'bold',
    },

    '.MuiFormLabel-root': {
      fontFamily: 'Roboto !important',
    },

    '.MuiChip-root': {
      fontFamily: 'Roboto !important',
    },

    '*::-webkit-scrollbar': {
      height: '4px',
      width: '4px',
    },

    '*::-webkit-scrollbar-track': {
      background: 'transparent',
      margin: '16px 0',
    },

    '*::-webkit-scrollbar-thumb': {
      background: colors.lightGrey,
      margin: '16px 0',
      borderRadius: '16px',
    },

    '*::-webkit-scrollbar-thumb:hover': {
      background: colors.grey,
    },
  },
})
