import { PessoaJuridica } from 'modules/pessoa-juridica/types'
import { searchParamsByValidParams } from 'utils/helpers'

import { endpoints } from './api/endpoints/pessoa-juridica.endpoints'
import { httpDelete, httpGet, httpPost, httpPut } from './api/http-methods'
import { PessoaJuridicaApiViewModel } from './api/models/pessoa-juridica.models'
import { requestHandler } from './api/request-handler'
import { mapPessoaJuridicaToApi } from './mappers/pessoa-juridica.mappers'

export const checkCNPJCadastrado = requestHandler<boolean, string>(async cnpj => {
  const response = await httpGet(endpoints.obterPjPorCnpj(cnpj))
  return {
    ...response,
    data: Boolean(response.data?.length),
  }
})

export const getPessoaJuridicaById = requestHandler<PessoaJuridicaApiViewModel, string>(pessoaJuridicaId =>
  httpGet(endpoints.obterPessoaJuridicaPorId(pessoaJuridicaId))
)

export const getPessoaJuridicaByFilters = requestHandler<PessoaJuridicaApiViewModel[], object>(params => {
  const validParams = ['nmPessoaJuridica', 'nuCnpj', 'nuCpfRepresentante', 'nmRepresentante']
  return httpGet(endpoints.consultarPJ, searchParamsByValidParams(params, validParams))
})

export const createPessoaJuridica = requestHandler<PessoaJuridicaApiViewModel, PessoaJuridica>(payload =>
  httpPost(endpoints.cadastrar, mapPessoaJuridicaToApi(payload))
)

export const updatePessoaJuridica = requestHandler<PessoaJuridicaApiViewModel, PessoaJuridica>(payload =>
  httpPut(endpoints.atualizar(String(payload.idPessoaJuridica)), mapPessoaJuridicaToApi(payload))
)

export const deletePessoaJuridica = requestHandler<void, string>(pessoaJuridicaId =>
  httpDelete(endpoints.deletePessoaJuridica(pessoaJuridicaId))
)
