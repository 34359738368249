import { PessoaFisica } from 'modules/pessoa-fisica/types'
import { searchParamsByValidParams } from 'utils/helpers'

import { endpoints } from './api/endpoints/pessoa-fisica.endpoints'
import { httpDelete, httpGet, httpPost, httpPut } from './api/http-methods'
import { PessoaFisicaApiViewModel } from './api/models/pessoa-fisica.models'
import { requestHandler } from './api/request-handler'
import { mapPessoaFisicaToApi } from './mappers/pessoa-fisica.mappers'

type CheckDocumentoParams = {
  tpDocumento: string
  nuDocumento: string
}

export const checkDocumentoValido = requestHandler<boolean, CheckDocumentoParams>(params =>
  httpGet(endpoints.validarDocumento(params.tpDocumento, params.nuDocumento))
)

export const checkCPFCadastrado = requestHandler<boolean, string>(cpf => httpGet(endpoints.cpfCadastrado(cpf)))

export const getPessoaFisicaById = requestHandler<PessoaFisicaApiViewModel, string>(pessoaFisicaId =>
  httpGet(endpoints.obterPessoaFisicaPorId(pessoaFisicaId))
)

export const getPessoaFisicaByFilters = requestHandler<PessoaFisicaApiViewModel[], object>(params => {
  const validParams = ['nmPessoaFisica', 'nuCpf', 'nuRg', 'dsOrgaoEmissorRG', 'idUFExpedicaoRG', 'dtNascimento']
  return httpGet(endpoints.consultarPF, searchParamsByValidParams(params, validParams))
})

export const getPessoaFisicaByOutroDocumento = requestHandler<PessoaFisicaApiViewModel[], object>(params => {
  const validParams = ['idTpDocumento', 'nuDocumento']
  return httpGet(endpoints.consultarPF, searchParamsByValidParams(params, validParams))
})

export const createPessoaFisica = requestHandler<PessoaFisicaApiViewModel, PessoaFisica>(payload =>
  httpPost(endpoints.cadastrar, mapPessoaFisicaToApi(payload))
)

export const updatePessoaFisica = requestHandler<PessoaFisicaApiViewModel, PessoaFisica>(payload =>
  httpPut(endpoints.atualizar(String(payload.idPessoaFisica)), mapPessoaFisicaToApi(payload))
)

export const deletePessoaFisica = requestHandler<PessoaFisicaApiViewModel, string>(pessoaFisicaId =>
  httpDelete(endpoints.excluirPessoaFisica(pessoaFisicaId))
)
