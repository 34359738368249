import { createSelector } from 'reselect'
import { AppState } from 'store/rootReducer'

const dsLoginState = (state: AppState) => state.user.login
const tokenState = (state: AppState) => state.user.dsToken
const nameState = (state: AppState) => state.user.nome
const idUserState = (state: AppState) => state.user.codigo
const matriculaUserState = (state: AppState) => state.user.matricula
const rememberLoginUserState = (state: AppState) => state.user.rememberLogin
const loadingLoginUserState = (state: AppState) => state.user.loading
const stMembroUserState = (state: AppState) => state.user.stMembro
const userState = (state: AppState) => state.user

const username = createSelector(dsLoginState, login => login)

const token = createSelector(tokenState, dsToken => dsToken)

const name = createSelector(nameState, name => name)

const id = createSelector(idUserState, idUser => idUser)

const isAuthenticate = createSelector(tokenState, dsToken => Boolean(dsToken))

const matricula = createSelector(matriculaUserState, matriculaUser => matriculaUser)

const rememberLogin = createSelector(rememberLoginUserState, rememberLogin => rememberLogin)

const loadingLogin = createSelector(loadingLoginUserState, loading => loading)

const stMembro = createSelector(stMembroUserState, stMembro => stMembro)

const user = createSelector(userState, userData => userData)

export default { username, token, name, id, isAuthenticate, matricula, rememberLogin, loadingLogin, stMembro, user }
