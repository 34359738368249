import React, { memo } from 'react'

import LoginLayout from 'layout/Login/Login'
import { useRoutes } from 'routes/guest.routes'

import RouterSwitch from './RouterSwitch'

const GuestScreenRouter: React.FC = () => {
  const { routes } = useRoutes()

  return (
    <LoginLayout>
      <RouterSwitch routes={routes} />
    </LoginLayout>
  )
}

export default memo(GuestScreenRouter)
