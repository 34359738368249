import MuiIconButton, { IconButtonProps as MuiProps } from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

type IconButtonProps = {
  title: string
} & MuiProps

export const IconButton = ({ title, ...props }: IconButtonProps) => (
  <Tooltip title={title} arrow>
    <MuiIconButton onClick={props.onClick} aria-label={title} {...props}>
      {props.children}
    </MuiIconButton>
  </Tooltip>
)
